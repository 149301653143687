import path from './path.js';
import cornerstoneTools from "cornerstone-tools";
const external = cornerstoneTools.external;
/**
 * Draw a series of joined lines, starting at `start` and then going to each point in `points`.
 * @public
 * @method drawJoinedLines
 * @memberof Drawing
 *
 * @param {CanvasRenderingContext2D} context - Target context
 * @param {HTMLElement} element - The DOM Element to draw on
 * @param {Object} start - `{ x, y }` in either pixel or canvas coordinates.
 * @param {Object[]} points - `[{ x, y }]` An array of points in either pixel or canvas coordinates.
 * @param {Object} options - See {@link path}
 * @param {String} [coordSystem='pixel'] - Can be "pixel" (default) or "canvas". The coordinate
 *     system of the points passed in to the function. If "pixel" then cornerstone.pixelToCanvas
 *     is used to transform the points from pixel to canvas coordinates.
 * @returns {undefined}
 */
 function gradient(a, b) {
  return (b.y-a.y)/(b.x-a.x);
}

function bzCurve(ctx, points, f, t) {
  //f = 0, will be straight line
  //t suppose to be 1, but changing the value can control the smoothness too
  

  var m = 0;
  var dx1 = 0;
  var dy1 = 0;

  var preP = points[0];
  for (var i = 1; i < points.length; i++) {
    var curP = points[i];
    var nexP = points[i + 1];
    if (nexP) {
      m = gradient(preP, nexP);
      var dx2 = (nexP.x - curP.x) * -f;
      var dy2 = dx2 * m * t;
    } else {
      dx2 = 0;
      dy2 = 0;
    }
    ctx.bezierCurveTo(preP.x - dx1, preP.y - dy1, curP.x + dx2, curP.y + dy2, curP.x, curP.y);
    dx1 = dx2;
    dy1 = dy2;
    preP = curP;
  }
  //ctx.stroke();
}
export default function drawJoinedLines (
  context,
  element,
  start,
  points,
  options,
  coordSystem = 'pixel'
) {
  path(context, options, context => {
    if (coordSystem === 'pixel') {
      start = external.cornerstone.pixelToCanvas(element, start);
      points = points.map(p => external.cornerstone.pixelToCanvas(element, p));
    }


    context.moveTo(start.x, start.y);
    bzCurve(context, points, 0.2, 0.7);
    // let xs = points.map(p => p.x);
    // let ys = points.map(p => p.y);

    // const spline = new Spline(xs, ys);



    // context.moveTo(start.x, start.y);

    // for (let i = 0; i < points.length - 1; i++) {
    //   for (let t = points[i].x; t <= points[i + 1].x; t++) {
    //     const p = spline.at(t);
    //     console.log(points[i])
    //     console.log(t, p)
    //     context.lineTo(points[i].x, p);
    //   }
    // }


    //   console.log(context)
    //   var i;
    //   for (i = 1; i < points.length - 2; i ++)
    //  {
    //     var xc = (points[i].x + points[i + 1].x) / 2;
    //     var yc = (points[i].y + points[i + 1].y) / 2;
    //     context.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
    //  }
    //  context.quadraticCurveTo(points[i].x, points[i].y, points[i+1].x,points[i+1].y);


    //points.forEach(({ x, y }) => {
    //  context.lineTo(x, y);
    //});
  });
}