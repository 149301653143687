import React, { useEffect, useRef, useState, useCallback } from "react";

import Navbar from "../../Components/Navbar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Icon from "@mdi/react";
import { mdiCloudUploadOutline, mdiCloudUpload } from "@mdi/js";
import { API_URL } from "../../Endpoints";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { Container } from "@mui/material";
import Button from "@mui/material/Button";

import { useDropzone } from "react-dropzone";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function AdminImagesUpload() {
  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const fontColors = {
    queued: "#000000",
    uploading: "#09F",
    uploaded: "#0F0",
    error: "#F00",
  };

  const updateStatus = (index, status, message) => {
    let temp_imgs = [...images];
    temp_imgs[index].status = status;
    temp_imgs[index].message = message;
    setImages(temp_imgs);
  };
  const uploadSingleFile = async (index) => {
    const formData = new FormData();
    formData.append("file", images[index].file);

    updateStatus(index, "uploading", "Uploading...");

    await axios
      .post(`${API_URL}/api/v1/files/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(
        (response) => {
          if (response.data.status === "success") {
            updateStatus(index, "uploaded", "File Uploaded");
          } else {
            updateStatus(index, "error", response.data.message);
          }
        },
        (error) => {
          updateStatus(index, "error", "Upload Failed");
        }
      );
  };

  const uploadImages = async () => {
    setIsUploading(true);
    for (var i = 0; i < images.length; i++) {
      if (images[i].status != "uploaded" || images[i].status != "uploading")
        await uploadSingleFile(i);
    }
    setIsUploading(false);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      let user_temp = [...images];
      for (var i = 0; i < acceptedFiles.length; i++) {
        user_temp.push({ status: "queue", file: acceptedFiles[i] });
      }
      setImages(user_temp);
    },
    [images]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpg,image/jpeg,image/png",
    multiple: true,
  });

  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundColor: "#fafbfe",
          width: "100%",
          height: "94vh",
          verticalAlign: "middle",
        }}
      >
        <Container fluid="md">
          <div>
            <h2
              style={{
                fontSize: 18,
                lineHeight: "75px",
              }}
            >
              Upload Images
            </h2>
          </div>
          <div
            {...getRootProps()}
            style={{
              border: "2px dashed #dee2e6",
              background: "#fff",
              borderRadius: "6px",
              cursor: "pointer",
              minHeight: "150px",
              padding: "20px",
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Icon path={mdiCloudUpload} size={2} color="#999" />
                <h4 style={{ marginTop: "20px" }}>
                  Drag 'n' drop some files here, or click to select files
                </h4>
                <p>Image upload will start automatically.</p>
              </div>
            )}
          </div>
          {!isUploading ? (
            <Button
              variant="contained"
              onClick={() => uploadImages()}
              color="primary"
              style={{ marginTop: "15px" }}
            >
              Upload Images
            </Button>
          ) : null}

          {images.length > 0 ? (
            <div>
              {images.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginTop: "20px",
                      border: "1px solid #ccc",
                      borderRadius: 5,
                      padding: "10px 5px",
                    }}
                  >
                    <div style={{ float: "left" }}>
                      <h5
                        style={{
                          color: "#98a6ad",
                          fontSize: "0.9rem",
                          marginBottom: "5px",
                        }}
                      >
                        {item.file.name}
                      </h5>
                      <strong>
                        {Math.round((item.file.size / 1024 / 1024) * 100, 2) /
                          100}{" "}
                        Mb
                      </strong>
                    </div>
                    <div style={{ float: "right", display: "flex" }}>
                      <div
                        style={{
                          marginTop: "9px",
                          color: fontColors[item.status],
                        }}
                      >
                        {item.message}
                      </div>
                      <div>
                        {item.status == "queue" ? (
                          <HighlightOffIcon
                            style={{ margin: "10px 15px", cursor: "pointer" }}
                          />
                        ) : item.status == "uploading" ? (
                          <CircularProgress
                            size={25}
                            style={{ margin: "10px 15px" }}
                          />
                        ) : item.status == "uploaded" ? (
                          <CheckCircleIcon
                            style={{
                              margin: "10px 15px",
                              cursor: "pointer",
                              color: "green",
                            }}
                          />
                        ) : (
                          <HighlightOffIcon
                            style={{
                              margin: "10px 15px",
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </Container>
      </div>
    </>
  );
}

export default AdminImagesUpload;
