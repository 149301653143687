import React, { useState, useRef, useEffect } from 'react';
import cornerstone, { getLayers } from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';



const getModule = cornerstoneTools.getModule;
const { getters, setters } = getModule('segmentation');
export default function SimpleDCMViewer(props) {
  const dispatch = useDispatch()
  const dicom_data = useSelector(state => state.dcm)

  const opData = {
        "currentImageIdIndex": 35,
        "imageIds": [
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=0",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=1",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=2",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=3",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=4",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=5",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=6",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=7",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=8",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=9",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=10",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=11",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=12",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=13",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=14",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=15",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=16",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=17",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=18",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=19",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=20",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=21",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=22",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=23",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=24",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=25",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=26",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=27",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=28",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=29",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=30",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=31",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=32",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=33",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=34",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=35",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=36",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=37",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=38",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=39",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=40",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=41",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=42",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=43",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=44",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=45",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=46",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=47",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=48",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=49",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=50",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=51",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=52",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=53",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=54",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=55",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=56",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=57",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=58",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=59",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=60",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=61",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=62",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=63",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=64",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=65",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=66",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=67",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=68",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=69",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=70",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=71",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=72",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=73",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=74",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=75",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=76",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=77",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=78",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=79",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=80",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=81",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=82",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=83",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=84",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=85",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=86",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=87",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=88",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=89",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=90",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=91",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=92",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=93",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=94",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=95",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=96",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=97",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=98",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=99",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=100",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=101",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=102",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=103",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=104",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=105",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=106",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=107",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=108",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=109",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=110",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=111",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=112",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=113",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=114",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=115",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=116",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=117",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=118",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=119",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=120",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=121",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=122",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=123",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=124",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=125",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=126",
            "wadouri:http://localhost:5000/static/dcm/E501/d-OPT.DCM?frame=127"
        ],
        "uuid": "71fa7a16-c4bd-41b0-89ae-092e98829959",
        "pending": []
    }
  const [elmentEnabled, setElementEnabled] = useState(false);
  const dcmRef = useRef(null);
  const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool;
  const [rectPoint, setRectPoint] = useState([0, 0, 0, 0])
  const [brushRadius, setBrushRadius] = useState(10)



  useEffect(() => {
    let element = dcmRef.current;
    
    cornerstone.loadImage(opData.imageIds[0])
      .then((image) => {
        // Enable the DOM Element for use with Cornerstone


        cornerstone.enable(element);
        cornerstone.displayImage(element, image)

        // set the stack as tool state
        cornerstoneTools.addStackStateManager(element, ['stack', 'Crosshairs']);
        cornerstoneTools.addToolState(element, 'stack', opData);
        cornerstoneTools.addTool(StackScrollMouseWheelTool);
        cornerstoneTools.setToolActive('StackScrollMouseWheel', {});

      }).then(() => {


        cornerstone.updateImage(element);
      });

  }, [])


  return (
    <div style={{ height: '100vh', width: props.width, backgroundColor: "#000" }}
      ref={dcmRef}
    ></div>
  );
}

