export const lineColors = {
      "ILM" : "rgb(132, 238, 61)",
      "RNFL" : "rgb(133, 180, 174)", 
      "GCL" : "rgb(143, 80, 170)", 
      "IPL" : "rgb(92, 151, 174)", 
      "INL" : "rgb(220, 152, 29)",
      "OPL" : "rgb(162, 104, 104)",
      "ONL" : "rgb(89, 88, 40)",
      "ELM" : "rgb(144, 77, 125)",
      "PR" : "rgb(116, 157, 66)",
      "RPE" : "rgb(189, 220, 226)",
      "BM" : "rgb(255, 0, 0)",
      undefined : "rgb(255, 255, 255)"
    }