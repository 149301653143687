import React, { useState, useEffect } from 'react';
import { Card, Typography, Button } from '@mui/material';
import { API_URL } from '../Endpoints';
import axios from 'axios';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';



export default function AddStackDialog(props) {

    const [stacks, setStacks] = useState([]);

    const fetchStacks = async () => {
        const response = await axios.get(`${API_URL}/api/v1/files`);
        setStacks(response.data)
    }


    useEffect(() => {
        fetchStacks();
    }, [])



    return (

        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Add Stack</DialogTitle>
            <DialogContent>
                { stacks.map((stack, index) => {
                    return (
                <div style={{ marginBottom: 8 }} onClick={() => console.log("clicked")} key={`stack-${index}`} >
                    <Card style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <img alt='dcm-stack'
                                    src={stack.url}
                                    style={{ width: "60px", height: "50px", borderRadius: 5 }} />

                                <Typography gutterBottom variant="body" style={{ padding: "10px" }} component="div">
                                    {stack.id}
                                </Typography>
                            </div>

                            <Button onClick={() => props.addStack(stack)} size="small">Add Stack</Button>
                        </div>
                    </Card>
                </div>
                    )
                })}

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
}

