import cornerstoneTools from "cornerstone-tools";

const BaseAnnotationTool = cornerstoneTools.importInternal('base/BaseAnnotationTool');

const external = cornerstoneTools.external;


const draw = cornerstoneTools.importInternal('drawing/draw');
const getNewContext = cornerstoneTools.importInternal('drawing/getNewContext');
const drawTextBox = cornerstoneTools.importInternal('drawing/drawTextBox');

const getToolState = cornerstoneTools.getToolState;
const textStyle = cornerstoneTools.textStyle;



const getRGBPixels = cornerstoneTools.importInternal('util/getRGBPixels');
const calculateSUV = cornerstoneTools.importInternal('util/calculateSUV');
const throttle = cornerstoneTools.importInternal('util/throttle');
const probeCursor = cornerstoneTools.importInternal('cursors/index');

// Utilities
//import getRGBPixels from '../../util/getRGBPixels.js';
//import calculateSUV from '../../util/calculateSUV.js';
//import { probeCursor } from '../cursors/index.js';
//import throttle from '../../util/throttle';
//import { getModule } from '../../store/index';


/**
 * @public
 * @class TextStatic
 * @memberof Tools.Annotation
 * @classdesc Tool which provides a probe of the image data at the
 * desired position.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class TextStatic extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'TextStatic',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: probeCursor,
      configuration: {
        drawHandles: true,
        renderDashed: false,
      },
    };

    super(props, defaultProps);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      
      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
      },
    };
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    const hasEndHandle = data && data.handles && data.handles.end;
    const validParameters = hasEndHandle;


    if (!validParameters || data.visible === false) {
      return false;
    }

    const probeCoords = external.cornerstone.pixelToCanvas(
      element,
      data.handles.end
    );

    return external.cornerstoneMath.point.distance(probeCoords, coords) < 5;
  }

  updateCachedStats(image, element, data) {
    const x = Math.round(data.handles.end.x);
    const y = Math.round(data.handles.end.y);

    const stats = {};

    if (x >= 0 && y >= 0 && x < image.columns && y < image.rows) {
      stats.x = x;
      stats.y = y;

      if (image.color) {
        stats.storedPixels = getRGBPixels(element, x, y, 1, 1);
      } else {
        stats.storedPixels = external.cornerstone.getStoredPixels(
          element,
          x,
          y,
          1,
          1
        );
        stats.sp = stats.storedPixels[0];
        stats.mo = stats.sp * image.slope + image.intercept;
        stats.suv = calculateSUV(image, stats.sp);
      }
    }

    data.cachedStats = stats;
    data.invalidated = false;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const fontHeight = textStyle.getFontSize();

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, context => {
        const color = "green"//toolColors.getColorIfActive(data);

        
          // Coords for text
          const coords = {
            // Translate the x/y away from the cursor
            x: 0,
            y: 0,
          };
          const textCoords = external.cornerstone.pixelToCanvas(
            eventData.element,
            coords
          );

          drawTextBox(
            context,
            data.text,
            textCoords.x,
            textCoords.y + fontHeight + 5,
            color
          );
        
      });
    }
  }
}



