export const get_slope_from_line = (line) => {
  let x1 = line[0];
  let y1 = line[1];
  let x2 = line[2];
  let y2 = line[3];

  let m = (y2 - y1) / (x2 - x1);
  return m;
};
export const extractFileName = (url) => {
  // Split the URL by '/' and get the last segment
  const segments = url.split("/");
  const lastSegment = segments.pop();

  // Decode the URL-encoded string
  const fileName = decodeURIComponent(lastSegment);

  return fileName;
};
export const intercept_from_line = (line) => {
  let x1 = line[0];
  let y1 = line[1];

  let m = get_slope_from_line(line);
  let b = y1 - m * x1;
  return b;
};

export const get_normalized_x = (e, currentFrameData, image_width) => {
  let xmin = currentFrameData[0];
  let xmax = currentFrameData[2];

  let total_width = xmax - xmin;

  let normalizedX =
    (e.detail.currentPoints.image.x / image_width) * total_width + xmin;

  if (normalizedX < xmin || normalizedX > xmax) {
    return null;
  }

  return normalizedX;
};

export function is_inside_polygon(point, vs) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

  var x = point[0],
    y = point[1];

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i][0],
      yi = vs[i][1];
    var xj = vs[j][0],
      yj = vs[j][1];

    var intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}
