import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
export default function AlertDialog({ open, handleClose, message, error }) {
  return (
    <Dialog open={open}>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          borderBottom: "3px solid #EA9143",
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: "3px",
            marginRight: "5px",
          }}
        >
          {error ? (
            <WarningIcon style={{ color: "#E9730C" }} />
          ) : (
            <CheckCircleIcon style={{ color: "#82CE34" }} />
          )}
        </div>
        {error ? "Error" : "Success"}
      </DialogTitle>

      <DialogContent style={{ marginTop: "10px" }}>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
