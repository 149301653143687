import { API_URL } from "../../../Endpoints";
import axios from "axios";
// Get each frame url as stack from dicom file
export function getDicomFrames(numFrames, imageIdRoot) {
  var imageIds = [];

  if (!numFrames) {
    imageIds.push("");
  } else {
    for (var i = 0; i < numFrames; i++) {
      var imageId = imageIdRoot + "?frame=" + i;
      imageIds.push(imageId);
    }
  }

  var stack = {
    currentImageIdIndex: 0,
    imageIds: imageIds,
  };
  return stack;
}

const toBinString = (bytes) =>
  bytes.reduce((str, byte) => str + byte.toString(2).padStart(8, "0"), "");

export function getReferenceCoordinates(dicomMeta, frameNumber) {
  // Get reference coordinates for a frame from the dicom file
  let coordinate_data =
    dicomMeta.frames[frameNumber].dataSet.elements.x00220031.items[0].dataSet
      .elements.x00220032;
  let byte_data = dicomMeta.dataset.byteArray.slice(
    coordinate_data.dataOffset,
    coordinate_data.dataOffset + 16
  );

  return get_rearranged_points([
    int8ArrayToFloat(byte_data.slice(0, 4).reverse()),
    int8ArrayToFloat(byte_data.slice(4, 8).reverse()),
    int8ArrayToFloat(byte_data.slice(8, 12).reverse()),
    int8ArrayToFloat(byte_data.slice(12, 16).reverse()),
  ]);
}

function get_rearranged_points(points) {
  return [points[1], points[0], points[3], points[2]];
}

// function toBinString(uint8Array) {
//   return Array.from(uint8Array, (byte) =>
//     byte.toString(2).padStart(8, "0")
//   ).join("");
// }

export function convertToInt2(mantissa_str) {
  // variable to make a count
  // of negative power of 2.
  let power_count = -1;
  let mantissa_int = 0;
  for (let i = 0; i < mantissa_str.length; i++) {
    mantissa_int += parseInt(mantissa_str[i]) * Math.pow(2, power_count);
    power_count -= 1;
  }
  return mantissa_int + 1;
}

export function int8ArrayToFloat(int8Array) {
  let byte_array = toBinString(Uint8Array.from(int8Array));
  let sign = byte_array[0] === "1" ? -1 : 1;

  let exponent = parseInt(byte_array.slice(1, 9), 2) - 127;
  let significand = convertToInt2(byte_array.slice(9, 32));
  return sign * parseInt(Math.pow(2, exponent) * significand);
}

// let byte_array = toBinString(Uint8Array.from(int8Array));

//

// let exponent = parseInt(byte_array.slice(1, 9), 2) - 127;
// let significand = 1 + parseInt(byte_array.slice(9, 32), 2) / Math.pow(2, 23);
// return sign * significand * Math.pow(2, exponent);
export function calc_angle_deg(line, pixel_spacing) {
  let x1 = line[0] * pixel_spacing.columnPixelSpacing; //line1[2]
  let y1 = line[1] * pixel_spacing.rowPixelSpacing; //line1[3]

  let x2 = line[2] * pixel_spacing.columnPixelSpacing; //line1[2]
  let y2 = line[3] * pixel_spacing.rowPixelSpacing; //line2[1]

  let angle = Math.abs((Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI);

  // Angle will be negative if the line is going from right to left
  if (y1 > y2) {
    angle = -angle;
  }

  return angle;
}

export function getSliceRectPoints(dataset, pixel_spacing) {
  let firstFramePoints = getReferenceCoordinates(dataset, 0);
  let lastFramePoints = getReferenceCoordinates(
    dataset,
    dataset.frames.length - 1
  );

  if (firstFramePoints[1] > lastFramePoints[1]) {
    let temp = lastFramePoints;
    lastFramePoints = firstFramePoints;
    firstFramePoints = temp;
  }

  let x1 = firstFramePoints[0];
  let y1 = firstFramePoints[1];
  let x2 = lastFramePoints[2];
  let y2 = lastFramePoints[3];

  let angle = 0;
  // if (firstFramePoints[1] !== firstFramePoints[3]) {
  //     angle = calc_angle_deg(firstFramePoints, pixel_spacing)
  // }

  return [x1, y1, x2, y2, angle];
}

function asciByteArrayToString(byter_array) {
  let final_string = "";
  byter_array.forEach((element) => {
    final_string += String.fromCharCode(element);
  });
  return final_string;
}

const getPixelSpacing = (dataSet) => {
  // Calculate pixle spacing from the pixel spacing element

  if (dataSet.elements.x52009229 === undefined) {
    return {
      rowPixelSpacing: null,
      columnPixelSpacing: null,
    };
  }

  let px =
    dataSet.elements.x52009229.items[0].dataSet.elements.x00289110.items[0]
      .dataSet.elements.x00280030;
  let final_string = asciByteArrayToString(
    dataSet.byteArray.slice(px.dataOffset, px.dataOffset + px.length)
  );

  return {
    rowPixelSpacing: parseFloat(final_string.split("\\")[0]),
    columnPixelSpacing: parseFloat(final_string.split("\\")[1]),
  };
};

export const processElement = (element, byte_array) => {
  // Process any element for ascii string
  if (element === undefined) {
    return "";
  }
  let final_string = asciByteArrayToString(
    byte_array.slice(element.dataOffset, element.dataOffset + element.length)
  );
  return final_string;
};

const parseDatetimeString = (datetime_string) => {
  if (datetime_string === undefined) {
    return "";
  }
  // Parse datetime string to date object
  let year = parseInt(datetime_string.slice(0, 4));
  let month = datetime_string.slice(4, 6).padStart(2, "0");
  let day = datetime_string.slice(6, 8);
  let hour = datetime_string.slice(8, 10);
  let minute = datetime_string.slice(10, 12);
  let second = datetime_string.slice(12, 14);
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

const get_basic_metadata = (dataSet) => {
  let metadata = {
    patient_name: processElement(dataSet.elements.x00100010, dataSet.byteArray),
    patient_id: processElement(dataSet.elements.x00100020, dataSet.byteArray),
    patient_birth_date: processElement(
      dataSet.elements.x00100030,
      dataSet.byteArray
    ),

    institution_name: processElement(
      dataSet.elements.x00080080,
      dataSet.byteArray
    ),
    study_description: processElement(
      dataSet.elements.x00081030,
      dataSet.byteArray
    ),
    series_description: processElement(
      dataSet.elements.x0008103e,
      dataSet.byteArray
    ),

    width: dataSet.uint16("x00280011"),
    height: dataSet.uint16("x00280010"),
    study_instance_uid: dataSet.string("x0020000d"),
  };
  return metadata;
};

const get_all_frame_points = (data) => {
  let points = [];
  data.frames.forEach((frame, i) => {
    let frame_points = getReferenceCoordinates(data, i);

    points.push(frame_points);
  });

  return points;
};

export function processOPTDicomMetaData(dataSet, scheme, dicom_url) {
  // Get final dicom metadata object
  let pixel_spacing = getPixelSpacing(dataSet);

  if (
    pixel_spacing.rowPixelSpacing === null ||
    pixel_spacing.columnPixelSpacing === null
  ) {
    pixel_spacing = get_OP_pixel_spacing(dataSet);
  }

  let dcm_meta = {
    dataset: dataSet,

    acquisition_date: parseDatetimeString(dataSet.string("x0008002a")),
    frames: get_all_frame_points({
      dataset: dataSet,
      frames: dataSet.elements.x52009230.items,
    }),
    rect: getSliceRectPoints(
      {
        dataset: dataSet,
        frames: dataSet.elements.x52009230.items,
      },
      pixel_spacing
    ),
    pixel_spacing: pixel_spacing,
    meta: get_basic_metadata(dataSet),
    url: dicom_url,
  };

  let total_frames = dataSet.elements.x52009230.items.length;

  let stack = getDicomFrames(total_frames, `${scheme}:${dicom_url}`);

  // If frame sequence is top to bottom, reverse it.
  // if (dcm_meta.frames[0][1] < dcm_meta.frames[1][1]) {
  //     stack.imageIds.reverse()
  // dcm_meta.frames.reverse()
  // }

  // DCM Meta Data
  dcm_meta.rect_poly = [
    [dcm_meta.frames[0][0], dcm_meta.frames[0][1]],
    [dcm_meta.frames[0][2], dcm_meta.frames[0][3]],
    [
      dcm_meta.frames[total_frames - 1][2],
      dcm_meta.frames[total_frames - 1][3],
    ],
    [
      dcm_meta.frames[total_frames - 1][0],
      dcm_meta.frames[total_frames - 1][1],
    ],
  ];

  return {
    vertical_line: null,
    annotations: {},
    metadata: dcm_meta,
    stack: stack,
  };
}

const get_OP_pixel_spacing = (dataSet) => {
  let px = dataSet.elements.x00280030;
  if (px === undefined) {
    return {
      rowPixelSpacing: 1,
      columnPixelSpacing: 1,
    };
  }
  let final_string = asciByteArrayToString(
    dataSet.byteArray.slice(px.dataOffset, px.dataOffset + px.length)
  );

  return {
    rowPixelSpacing: parseFloat(final_string.split("\\")[0]),
    columnPixelSpacing: parseFloat(final_string.split("\\")[1]),
  };
};

export function processOPDicomMetaData(dataSet, scheme, dicom_url) {
  // Get final dicom metadata object

  return {
    metadata: {
      pixel_spacing: get_OP_pixel_spacing(dataSet),
      acquisition_date: parseDatetimeString(dataSet.string("x0008002a")),
      url: dicom_url,
      width: dataSet.uint16("x00280011"),
      height: dataSet.uint16("x00280010"),
    },
    stack: {
      currentImageIdIndex: 0,
      imageIds: [`${scheme}:${dicom_url}`],
    },
    vertical_line: null,
    annotations: {},
  };
}

export const getLabelByValue = (value, array) => {
  let strVal = value.toString();
  const item = array.find((element) => element.value == strVal);
  return item ? item.label : value;
};

const statusSymbol = (previousFormStatus) => {
  if (previousFormStatus === "completed") {
    return "[a]";
  } else if (previousFormStatus === "editing") {
    return "[e]";
  } else {
    return "[p]";
  }
};
export const hidePreviousValues = (previousData) => {
  let lastResponse = previousData?.previous_record?.data;
  Object.keys(lastResponse).forEach((key) => {
    const targetDiv = document.querySelector(`.formio-component-${key}`);
    if (targetDiv) {
      let parentParagraphName = `added-component-${key}`;
      let parentParagraphComponent =
        document.getElementById(parentParagraphName);
      if (parentParagraphComponent) {
        parentParagraphComponent.style.display = "none";
      }
    }
  });
};
// To solve the issue of mixing OD and OS values, we need to implement a modification on the OCT-Viewer side
// Do not show the value correspondent to a visit name that contains the opposite laterality in Upper case
// Example: if the exam is about the Right Eye (Laterality 'R'), do not show the value of the previous answer where a visit name contains the word 'OS'
// Example: if the exam is about the Left Eye (Laterality 'L'), do not show the value of the previous answer where a visit name contains the word 'OD'

const lateralityOpposites = {
  L: "OD",
  R: "OS",
};

export const fetchLateralityObject = async (apiURL) => {
  try {
    const response = await axios.post(`${API_URL}/api/v1/dicom/meta`, {
      url: apiURL,
    });

    const data = response.data;
    console.log("resp.data ", data);

    // Find the object with the name 'Laterality'
    const lateralityObject = data.find((obj) => obj.name === "Laterality");

    return lateralityObject;
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw error; // Re-throw the error if you want to handle it later
  }
};

const removeOppositeLaterality = (historyResponses, laterality) => {
  console.log("laterality laterality", laterality);
  return historyResponses.filter((obj) => {
    const { visit_label } = obj;
    const containsOS = /\bOS\b/.test(visit_label);
    const containsOD = /\bOD\b/.test(visit_label);

    if (laterality === "R") {
      // Remove if 'OS' is in the middle, at the start, or at the end
      if (containsOS) {
        return !(
          visit_label.startsWith("OS ") ||
          visit_label.endsWith(" OS") ||
          visit_label.includes(" OS ")
        );
      }
    } else if (laterality === "L") {
      // Remove if 'OD' is in the middle, at the start, or at the end
      if (containsOD) {
        return !(
          visit_label.startsWith("OD ") ||
          visit_label.endsWith(" OD") ||
          visit_label.includes(" OD ")
        );
      }
    }

    // Keep the object if it doesn't meet the removal criteria
    return true;
  });
};
export const showPreviousValues = async (formData, lateralityValue) => {
  let lastResponse = formData?.previous_visit_data?.previous_record;
  let historyResponses = formData?.previous_visit_data?.previous_record_history;

  let allResponses = [...historyResponses, lastResponse];
  allResponses.sort(
    (a, b) => new Date(b?.visit_date) - new Date(a?.visit_date)
  );

  let filtered = removeOppositeLaterality(allResponses, lateralityValue);

  console.log("allResponses", { allResponses, filtered });

  lastResponse = { ...filtered[0] };

  filtered.splice(0, 1);

  Object.keys(lastResponse?.data)?.forEach(async (key) => {
    const targetDiv = document.querySelector(`.formio-component-${key}`);
    if (targetDiv) {
      let parentParagraphName = `added-component-${key}`;
      let parentParagraphComponent =
        document.getElementById(parentParagraphName);
      if (parentParagraphComponent) {
        parentParagraphComponent.style.display = "flex";
      } else {
        const paragraph = document.createElement("p");

        let content = "";
        if (key === "wasElipsoidZoneDefectAreaDone") {
          content = getLabelByValue(
            lastResponse?.data[key],
            formData?.form_schema
              ? formData?.form_schema?.components[0]?.components[0]?.values
              : formData?.adjudication_form_schema?.components[0]?.components[0]
                  ?.values
          );
        } else {
          content = lastResponse?.data[key];
        }
        paragraph.textContent = `${lastResponse?.visit_label}: ${
          content || "nil"
        } (${lastResponse?.visit_date}) ${statusSymbol(lastResponse?.status)}`;
        // paragraph.textContent = lastResponse[key];
        paragraph.id = parentParagraphName;
        paragraph.style.position = "relative"; // Ensure the paragraph is the reference point for the tooltip
        paragraph.style.marginTop = "7px";
        paragraph.style.display = "inline-block"; // Add this line to make the div fit the content
        paragraph.style.width = "auto";

        const additionalText = [];

        historyResponses = filtered;
        historyResponses?.forEach((obj) => {
          if (obj?.data) {
            if (key === "wasElipsoidZoneDefectAreaDone") {
              additionalText.push(
                `${obj?.visit_label}: ${getLabelByValue(
                  obj.data[key] || "nil",
                  formData?.form_schema
                    ? formData?.form_schema?.components[0]?.components[0]
                        ?.values
                    : formData?.adjudication_form_schema?.components[0]
                        ?.components[0]?.values
                )} (${obj?.visit_date}) ${statusSymbol(obj?.status)}`
              );
            } else {
              additionalText.push(
                `${obj?.visit_label}: ${obj?.data[key] || "nil"} (${
                  obj?.visit_date
                }) ${statusSymbol(obj?.status)} `
              );
            }
          }
        });
        if (additionalText.length > 0) {
          paragraph.style.cursor = "pointer";
          const tooltip = document.createElement("div");

          tooltip.style.display = "none"; // Initially hide the tooltip
          tooltip.style.position = "absolute";
          tooltip.style.color = "red";
          tooltip.style.background = "#fff";
          tooltip.style.border = "1px solid #ccc";
          tooltip.style.width = "auto"; // Allow width to be dynamic
          tooltip.style.whiteSpace = "nowrap"; // Prevent text from wrapping
          tooltip.style.paddingLeft = "15px";
          tooltip.style.paddingRight = "15px";

          tooltip.style.top = "100%"; // Position it below the text
          tooltip.style.left = "0";
          additionalText?.forEach((text) => {
            const p = document.createElement("div");
            p.textContent = text;
            tooltip.appendChild(p);
          });

          paragraph.addEventListener("mouseenter", () => {
            tooltip.style.display = "flex";
            tooltip.style.flexDirection = "column";
          });

          paragraph.addEventListener("mouseleave", () => {
            tooltip.style.display = "none";
          });

          paragraph.appendChild(tooltip);
        }

        targetDiv.appendChild(paragraph);
      }
    }
  });
};

// export const showPreviousValues = async (formData, lateralityValue) => {

//   let lastResponse = formData?.previous_visit_data?.previous_record;

//   Object.keys(lastResponse?.data)?.forEach(async (key) => {
//     const targetDiv = document.querySelector(`.formio-component-${key}`);
//     if (targetDiv) {
//       let parentParagraphName = `added-component-${key}`;
//       let parentParagraphComponent =
//         document.getElementById(parentParagraphName);
//       if (parentParagraphComponent) {
//         parentParagraphComponent.style.display = "flex";
//       } else {
//         const paragraph = document.createElement("p");

//         let content = "";
//         if (key === "wasElipsoidZoneDefectAreaDone") {
//           content = getLabelByValue(
//             lastResponse?.data[key],
//             formData?.form_schema
//               ? formData?.form_schema?.components[0]?.components[0]?.values
//               : formData?.adjudication_form_schema?.components[0]?.components[0]
//                   ?.values
//           );
//         } else {
//           content = lastResponse?.data[key];
//         }
//         paragraph.textContent = `${lastResponse?.visit_label}: ${
//           content || "nil"
//         } (${lastResponse?.visit_date}) ${statusSymbol(lastResponse?.status)}`;
//         // paragraph.textContent = lastResponse[key];
//         paragraph.id = parentParagraphName;
//         paragraph.style.position = "relative"; // Ensure the paragraph is the reference point for the tooltip
//         paragraph.style.marginTop = "7px";
//         paragraph.style.display = "inline-block"; // Add this line to make the div fit the content
//         paragraph.style.width = "auto";

//         const additionalText = [];

//         let historyResponses =
//           formData?.previous_visit_data?.previous_record_history;

//         historyResponses.sort(
//           (a, b) => new Date(b?.visit_date) - new Date(a?.visit_date)
//         );

//         // let optLateralityObject = await fetchLateralityObject(optUrl);
//         // console.log("optLateralityObject", optLateralityObject);
//         historyResponses = removeOppositeLaterality(
//           historyResponses,
//           lateralityValue
//         );
//         historyResponses?.forEach((obj) => {
//           if (obj?.data) {
//             if (key === "wasElipsoidZoneDefectAreaDone") {
//               additionalText.push(
//                 `${obj?.visit_label}: ${getLabelByValue(
//                   obj.data[key] || "nil",
//                   formData?.form_schema
//                     ? formData?.form_schema?.components[0]?.components[0]
//                         ?.values
//                     : formData?.adjudication_form_schema?.components[0]
//                         ?.components[0]?.values
//                 )} (${obj?.visit_date}) ${statusSymbol(obj?.status)}`
//               );
//             } else {
//               additionalText.push(
//                 `${obj?.visit_label}: ${obj?.data[key] || "nil"} (${
//                   obj?.visit_date
//                 }) ${statusSymbol(obj?.status)} `
//               );
//             }
//           }
//         });
//         if (additionalText.length > 0) {
//           paragraph.style.cursor = "pointer";
//           const tooltip = document.createElement("div");

//           tooltip.style.display = "none"; // Initially hide the tooltip
//           tooltip.style.position = "absolute";
//           tooltip.style.color = "red";
//           tooltip.style.background = "#fff";
//           tooltip.style.border = "1px solid #ccc";
//           tooltip.style.width = "105%";
//           tooltip.style.paddingLeft = "15px";

//           tooltip.style.top = "100%"; // Position it below the text
//           tooltip.style.left = "0";
//           additionalText?.forEach((text) => {
//             const p = document.createElement("div");
//             p.textContent = text;
//             tooltip.appendChild(p);
//           });

//           paragraph.addEventListener("mouseenter", () => {
//             tooltip.style.display = "flex";
//             tooltip.style.flexDirection = "column";
//           });

//           paragraph.addEventListener("mouseleave", () => {
//             tooltip.style.display = "none";
//           });

//           paragraph.appendChild(tooltip);
//         }

//         targetDiv.appendChild(paragraph);
//       }
//     }
//   });
// };
