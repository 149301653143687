import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import ViewStacks from "./ViewStacks";
import { styled } from "@mui/system";
import { Grid } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const TableCellHeading = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#ccebff",
  paddingTop: "8px",
  paddingBottom: "8px",
  width: "150px",
  border: "1px solid #09f",
}));

const TableCellDet = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#dff2ff",
  width: "200px",
  paddingTop: "8px",
  paddingBottom: "8px",
  border: "1px solid #09f",
}));

export default function NavbarWithMeta(props) {
  const { metadata } = props;
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container spacing={1}>
        <Grid item md={5}>
          <ViewStacks />
        </Grid>
        <Grid item md={7}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHeading>Study Code</TableCellHeading>
                  <TableCellDet>{metadata?.study_code}</TableCellDet>
                  <TableCellHeading>Subject Code</TableCellHeading>
                  <TableCellDet>{metadata?.subject_code}</TableCellDet>
                  <TableCellHeading>Visit Label</TableCellHeading>
                  <TableCellDet>{metadata?.visit_label}</TableCellDet>
                  <TableCellHeading>Site Code</TableCellHeading>
                  <TableCellDet>{metadata?.site_code}</TableCellDet>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {/* <Button href="/upload" color="inherit">Upload</Button> */}
    </Box>
  );
}
