import React, { useState, useEffect } from "react";
import { Card, Typography, Button } from "@mui/material";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AbcIcon from "@mui/icons-material/Abc";
// Import help icon
import HelpIcon from "@mui/icons-material/Help";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { API_URL } from "../../../Endpoints";
import DCMMetaTable from "./components/MetaTable";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function MetaDataDialog(props) {
  const [open, setOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("op");
  const [metaData, setMetaData] = useState({
    op: null,
    opt: null,
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.urls && open) {
      axios
        .post(`${API_URL}/api/v1/dicom/meta`, {
          url: props.urls[currentTab],
        })
        .then((resp) => {
          setMetaData({ ...metaData, [currentTab]: resp.data });
        })
        .catch((err) => {});
    }
  }, [currentTab, open]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue === 0 ? "op" : "opt");
  };

  return (
    <>
      <span style={{ padding: 4 }}>
        <Button
          size="small"
          style={{ paddingLeft: 1, paddingRight: 1, width: 30 }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <AbcIcon size={2} />
        </Button>
      </span>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Dicom Metadata</DialogTitle>
        <DialogContent style={{ minWidth: 800 }}>
          <Tabs
            value={currentTab === "op" ? 0 : 1}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="IR" {...a11yProps("op")} />
            <Tab label="OCT" {...a11yProps("opt")} />
          </Tabs>
          <DCMMetaTable data={metaData[currentTab]} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
