import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useParams, useLocation } from "react-router";
import axios from "axios";
import { API_URL } from "../Endpoints";
import { CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

export default function ViewStacks() {
  const [open, setOpen] = React.useState(false);
  const [stacks, setStacks] = React.useState([]);
  const [dropdownOptions, setDropdownOptions] = React.useState([]);
  const dicom_data = useSelector((state) => state.dcm);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const anchor = "left";
  const toggleDrawer = () => {
    setOpen(!open);
  };
  let { search } = useLocation();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = React.useState("");

  const handleChange = (event) => {
    if (selectedKey !== event.target.value) {
      setSelectedKey(event.target.value);
      localStorage.setItem(query.get("stack_id"), event.target.value);

      const timer = setTimeout(() => {
        // Code to execute after 500ms
        window.location.reload();
      }, 500);
    }
  };

  const query = new URLSearchParams(search);
  React.useEffect(() => {
    if (dicom_data?.formDataUserGrading?.data) {
      let keysDropdown = Object.keys(dicom_data?.formDataUserGrading?.data);
      let arrayObjs = [];

      for (let i = 0; i < keysDropdown.length; i++) {
        let key = keysDropdown[i];
        let value =
          dicom_data.formDataUserGrading.data[keysDropdown[i]].grader_name;
        let obj = {
          key: key,
          value: value + " (grader)",
        };
        arrayObjs.push(obj);
      }
      if (query.get("role") === "adjudicator") {
        arrayObjs.push({ key: query.get("user_id"), value: "Adjudicator" });
      }

      setDropdownOptions(arrayObjs);
    }
  }, [dicom_data.formDataUserGrading]);
  React.useEffect(() => {
    if (
      query.get("role") === "adjudicator" &&
      !localStorage.getItem(query.get("stack_id"))
    ) {
      setSelectedKey(query.get("user_id"));
    } else {
      if (
        query.get("role") === "adjudicator" &&
        localStorage.getItem(query.get("stack_id"))
      ) {
        if (dicom_data?.fetchedId) {
          setSelectedKey(dicom_data?.fetchedId);

          // localStorage.removeItem(query.get("stack_id"));
        } else {
          setSelectedKey(query.get("user_id"));
        }
      }
    }
  }, [dicom_data?.fetchedId]);
  React.useEffect(() => {
    axios
      .get(
        `${API_URL}/api/v1/stacks?id=${query.get(
          "stack_id"
        )}&series=${query.get("series_no")}`
      )
      .then((res) => {
        setStacks(res.data);
        setLoading(false);

        dispatch({
          type: "NO_OF_STACKS",
          stacks: res.data,
        });
      })
      .catch((err) => {
        setLoading(false);
      });
    if (query.get("role") === "adjudicator") {
      if (dicom_data?.fetchedId) {
        setSelectedKey(dicom_data?.fetchedId);
      }
    }
  }, []);

  const onSeriesClicK = (series_id) => {
    window.location.assign(
      `/viewer?stack_id=${query.get("stack_id")}&user_id=${query.get(
        "user_id"
      )}&series_no=${series_id}&role=${query.get("role")}`
    );
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <Typography variant="h6" sx={{ px: 2, py: 1 }}>
        Stack Series
      </Typography>
      <Divider />

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {stacks?.map((stack, index) => (
            <ListItem key={stack.id} disablePadding>
              <ListItemButton
                selected={stack.id === query.get("series_no")}
                onClick={() => onSeriesClicK(stack.id)}
              >
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={stack.id} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "10px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "left", ml: 1, mt: 0.7 }}>
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={toggleDrawer}
          >
            {"View Stacks"}
          </Button>
        </Box>
        {query.get("role") === "adjudicator" ? (
          <FormControl sx={{ ml: 1, mt: 0.7, width: "245px" }}>
            <InputLabel>Load annotations from</InputLabel>
            <Select value={selectedKey} onChange={handleChange}>
              {dropdownOptions?.map((option) => (
                <MenuItem key={option.value} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </div>
      <Drawer anchor={"left"} open={open} onClose={toggleDrawer}>
        {list(anchor)}
      </Drawer>
    </>
  );
}
