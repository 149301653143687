import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Viewer from "./Views/DCMViewer/DCMViewer";
import Home from "./Views/Home";
import Upload from "./Views/Upload";
import SimpleDCMViewer from "./Views/DCMViewerSimple/DCMViewer";

import { ErrorBoundary } from "react-error-boundary";
import NotFoundPage from "./Components/NotFound";

function App() {
  return (
    <ErrorBoundary
      fallbackRender={NotFoundPage}
      // onReset={(details) => {
      //   // Reset the state of your app so the error doesn't happen again
      // }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/viewer" element={<Viewer />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/dcm" element={<SimpleDCMViewer />} />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
