import * as React from 'react';

import cornerstoneTools from 'cornerstone-tools';
import BrushIcon from '@mui/icons-material/Brush';
import { Button, Slider } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useSelector, useDispatch } from 'react-redux';
const getModule = cornerstoneTools.getModule;
const { setters } = getModule('segmentation');

export default function BrushToolSettings(props) {
    const dispatch = useDispatch()
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const brushSettings = useSelector(state => state.dcm).settings.brush
    const onChangeRadius = (e, value) => {
        dispatch({ type: 'SET_BRUSH_BORDER_RADIUS', borderRadius: value })
        setters.radius(value);
    }

    const [activeLabelMap, setActiveLabelMap] = React.useState(0);


    const toggleDrawer = (type) => {
        setOpenDrawer(type);
    }

    const changeLabelMap = (index) => {
        dispatch({ type: 'SET_BRUSH_SEGMENTATION_INDEX', index: index })
    }

    React.useEffect(() => {
        setters.activeLabelmapIndex(activeLabelMap);
    }
        , [activeLabelMap]);

    const segmentColors = [
        "rgb(221, 84, 84)",
        "rgb(77, 228, 121)",
        "rgb(166, 70, 235)",
        "rgb(189, 180, 116)",
        "rgb(109, 182, 196)",
        "rgb(204, 101, 157)",
        "rgb(123, 211, 94)",
        "rgb(93, 87, 218)",
    ]



    return (
        <>

            <div style={{ padding: 4 }}>
                <Button onClick={() => toggleDrawer(true)} variant="contained"
                    color="secondary" size="small" startIcon={<BrushIcon />}>
                    Settings
                </Button>
            </div>

            <React.Fragment>
                <Drawer
                    anchor={'right'}
                    open={openDrawer}
                    onClose={() => toggleDrawer(false)}
                >
                    <div style={{ padding: "10px", minWidth: "400px" }}>
                        <h3>Brush Settings</h3>
                        <div style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                            <h4>Brush Size</h4>
                            {/* Add slider */}
                            <div style={{ display: "flex", flexDirection: "row", minHeight: "50px" }}>
                                <div style={{ minWidth: "120px", display: "flex", flexDirection: "column", justifyContent: 'center' }}>
                                    <div style={{
                                        height: brushSettings.radius / 2,
                                        width: brushSettings.radius / 2, borderRadius: "50%",
                                        border: "1px solid red",
                                        backgroundColor: "#F9AEAE"
                                    }}></div>
                                </div>
                                <div style={{ display: "flex", width: "80%", flexDirection: "column", justifyContent: 'center' }}>

                                    <Slider onChange={(e, v) => onChangeRadius(e, v)} value={brushSettings.radius} min={1} max={100} />
                                </div>
                            </div>
                        </div>
                        <h3>LabelMap</h3>
                        <div style={{ display: "flex", flexDirection: "row", maxWidth: "400px",
                        flexWrap: "wrap",
                        justifyContent: "left", padding: "10px" }}>

                            {segmentColors.map((color, index) => {
                                return (
                                    <div key={`seg-color-${index}`} style={{ width: "40px", height: "40px", cursor: "pointer",
                                     backgroundColor: color, border: "1px solid #ccc", 
                                     margin: "10px" }} 
                                     onClick={() => changeLabelMap(index + 1)}></div>
                                )
                            }
                            )}
                        </div>
                        <h3>Masks</h3>
                        <div style={{ padding: "10px" }}>
                            <input type="checkbox"
                            onChange={() => dispatch({ type: 'SET_BRUSH_SHOW_MASKS', showMasks: !brushSettings.showMasks })}
                            checked={brushSettings.showMasks} />
                        </div>
                    </div>






                </Drawer></React.Fragment>
        </>
    );
}