import { Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Navbar from '../../Components/Navbar';
import GridItem from './GridItem';
import Grid from '@mui/material/Grid';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ListItem from './ListItem';
import axios from 'axios';
import { API_URL } from '../../Endpoints';

export default function Home() {
    const [data, setData] = useState([])
    const [viewType, setViewType] = useState("grid");


    const fetchImages = async () => {
        const response = await axios.get(`${API_URL}/api/v1/files`);
        setData(response.data)
    }


    useEffect(() => {
        fetchImages();
    }, [])


    return (
        <div style={{ backgroundColor: "rgb(246, 249, 251)", height: "100vh" }}>
            <Navbar />
            <Container>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h4 style={{ marginTop: "20px" }}>Total Ims ({data.length})</h4>
                    <ToggleButtonGroup style={{ maxHeight: 40, marginTop: 12 }}
                        orientation="horizontal"
                        value={viewType}
                        exclusive
                        onChange={(e, v) => setViewType(v)}
                    >
                        <ToggleButton value="list" aria-label="list">
                            <ViewListIcon />
                        </ToggleButton>
                        <ToggleButton value="grid" aria-label="grid">
                            <ViewModuleIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {data.map(item => (
                        viewType === "grid" ? <GridItem key={item.id} item={item} /> : <ListItem key={item.id} item={item} />
                    ))}

                </Grid>
            </Container>

           
        
        </div>
    );
}

