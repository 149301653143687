import React from "react";

export default function ViewerNav(props) {
  return (
    <div
      style={{
        height: "20px",
        color: "rgb(150, 150, 150)",
        border: "0.01rem solid rgb(121, 121, 121)",
        backgroundColor: "rgb(70, 45, 45)",
        paddingLeft: "10px",
        fontSize: 14,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div>{props.metadata.acquisition_date}</div>
      {props.text !== undefined ? (
        <div style={{ marginLeft: "10px", color: "#F00" }}>({props.text})</div>
      ) : null}
    </div>
  );
}
