import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-formio";
import axios from "axios";
import { API_URL } from "../../../Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { processAnnotations } from "../actions/dcm_actions";
import { useLocation } from "react-router-dom";
import { extractFileName } from "../utils/basic_utils";
import AlertDialog from "../../../Components/Dialog";
import { IconButton } from "@mui/material";

import { getLabelByValue } from "../utils/dicom_utils";
import PreviousData from "../../../Components/previousDataButton";
let sss_Urls = {};

function GradingFormResponsesTable({
  labelsData,
  imageClicked,
  responses,
  labelsPair,
  query,
  // grading_screenshot_urls,
  // grading_screenshot_urls,
}) {
  let responsessCopy = { ...responses };

  let keeyshere = Object.keys(responsessCopy);

  for (let i = 0; i < keeyshere.length; i++) {
    if (!responsessCopy[keeyshere[i]]?.responses.data) {
      delete responsessCopy[keeyshere[i]];
    }
  }

  const uuids = Object.keys(responsessCopy);
  if (uuids.length === 0) {
    return <p>Grading Data Not Found</p>;
  }

  const labels = uuids.reduce((acc, uuid) => {
    return [Object.keys(responsessCopy[uuid]?.responses.data)];
  }, []);

  let temp = [...new Set(labels)];
  temp[0].push("leasions_area_img");

  const uniqueLabels = temp[0];

  // Find the index of the string to remove
  let indexToRemove = uniqueLabels?.indexOf("submit");

  // If the string exists in the array, remove it
  if (indexToRemove !== -1) {
    uniqueLabels?.splice(indexToRemove, 1);
  }

  let totalAreaIndex = uniqueLabels?.indexOf("totalArea");

  // Remove "totalArea" from its original position
  let totalArea = uniqueLabels?.splice(totalAreaIndex, 1)[0];

  // Insert "totalArea" at the beginning of the array
  uniqueLabels?.unshift(totalArea);
  return (
    <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
      }}
    >
      <thead>
        <tr>
          <th
            style={{
              border: "1px solid gray",
              padding: "8px",
              maxWidth: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Label
          </th>
          {uuids?.map((uuid) => (
            <th
              key={uuid}
              style={{
                border: "1px solid gray",
                padding: "8px",
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {}
              {responsessCopy[uuid]?.graded_by ?? uuid}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {uniqueLabels?.map((label, index) => (
          <tr key={index}>
            <td style={{ border: "1px solid gray", padding: "8px" }}>
              {labelsPair ? labelsPair[label] : label}
            </td>
            {uuids?.map((uuid, index) => (
              <>
                <td
                  key={index}
                  style={{ border: "1px solid gray", padding: "8px" }}
                >
                  {label === "leasions_area_img" ? (
                    sss_Urls[uuid]?.lesions_area &&
                    sss_Urls[uuid]?.lesions_area[0] ? (
                      <>
                        {}
                        <img
                          onClick={() => {
                            imageClicked(sss_Urls[uuid]?.lesions_area[0]);
                          }}
                          src={sss_Urls[uuid]?.lesions_area[0]}
                          // src={grading_screenshot_urls[uuid]?.leasions_area[0]}
                          alt="ScreenShot"
                          height="200px"
                          width="200px"
                        />
                      </>
                    ) : (
                      "N/A"
                    )
                  ) : label === "wasElipsoidZoneDefectAreaDone" ? (
                    getLabelByValue(
                      responsessCopy[uuid]?.responses?.data[label],
                      labelsData
                    )
                  ) : (
                    responsessCopy[uuid]?.responses?.data[label] ?? "N/A"
                  )}
                </td>
              </>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
export default function AdjQAForm(props) {
  // const [open, setOpen] = useState(false);
  const [gradingFormResponse, setGradingFormResponse] = useState({});

  const [formData, setFormData] = useState({});
  const [labels, setLabels] = useState(null);
  const [respMessage, setRespMessage] = useState({ error: false, message: "" });
  const dicom_data = useSelector((state) => state.dcm);
  const dispatch = useDispatch();
  let { search } = useLocation();

  const formikRef = useRef(null);

  const query = new URLSearchParams(search);
  const rq = props.request_info;

  const imageClicked = (url) => {
    if (url !== undefined || dicom_data?.mainScreenShotUrl !== "") {
      dispatch({
        type: "displayScreenShot_url_changed",
        displayScreenShotUrl:
          typeof url === "string" || url instanceof String
            ? url
            : dicom_data?.mainScreenShotUrl,
      });
      dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });
      dispatch({ type: "screenShot_Display_changed", screenShotDisplay: true });
    }
  };

  useEffect(() => {}, [gradingFormResponse]);

  const updateContentForKey = (data, keyToFind, newContent, imageUrl) => {
    if (data?.adjudication_form_schema?.components) {
      for (let parentComponent of data.adjudication_form_schema.components) {
        if (parentComponent?.components) {
          for (let component of parentComponent.components) {
            if (component.key === keyToFind) {
              const updatedContent = newContent.replace(
                'src=""',
                `src="${imageUrl}"`
              );
              component.content = updatedContent;
              return; // Assuming only one match is needed, exit after updating
            }
          }
        }
      }
    }
  };

  const loadInitialDataAdjudicator = () => {
    axios
      .get(
        `${API_URL}/api/v1/qa-form-adjudicator?stack_id=${
          rq.stack_id
        }&user_id=${rq.user_id}${
          query.get("form_version_id")
            ? `&form_version_id=${query.get("form_version_id")}`
            : ""
        }`
      )
      .then((res) => {
        if (res.data) {
          const index =
            res?.data?.adjudication_form_schema?.components[0]?.components.findIndex(
              (component) => component.key === "leasions_area_img"
            );

          if (index !== -1) {
            // Store the removed component in state
            const [removed] =
              res?.data?.adjudication_form_schema?.components[0]?.components.splice(
                index,
                1
              );

            // Now components array is updated without the removed component
            // You can update the state or handle the new array as needed
          }

          setFormData(res.data);

          setGradingFormResponse({ ...res?.data?.grading_form_responses });

          if (res.data?.grading_screenshot_urls) {
            sss_Urls = res.data?.grading_screenshot_urls;
          }
        }
        if (res?.data?.adjudication_id) {
          dispatch({
            type: "Adjudication_id_changed",
            adjudication_id: res?.data?.adjudication_id,
          });
        }

        // let userId=localStorage.getItem(query.get("stack_id"))??query.get("user_id")
        // console.log("dissssssspatching aaa", res?.data);
        if (res?.data?.adjudication_screenshot_urls?.lesions_area[0]) {
          if (
            res?.data?.adjudication_screenshot_urls?.lesions_area[0] &&
            dicom_data?.mainScreenShotUrl === null
          ) {
            // console.log(
            //   "dissssssspatching",
            //   res?.data?.adjudication_screenshot_urls
            // );
            if (dicom_data?.mainScreenShotUrl === null) {
              console.log("changed to ADJ ");
              dispatch({
                type: "mainScreenShot_url_changed",
                mainScreenShotUrl:
                  res?.data?.adjudication_screenshot_urls?.lesions_area[0],
              });
            }
          }
        }

        // if (
        //   localStorage.getItem(query.get("stack_id")) &&
        //   res?.data?.grading_form_responses[
        //     localStorage.getItem(query.get("stack_id"))
        //   ]
        // ) {
        //   if (
        //     res?.data?.grading_form_responses[
        //       localStorage.getItem(query.get("stack_id"))
        //     ]?.responses?.files
        //   ) {
        //     dispatch({
        //       type: "mainScreenShot_url_changed",
        //       mainScreenShotUrl:
        //         res?.data?.grading_form_responses[
        //           localStorage.getItem(query.get("stack_id"))
        //         ]?.responses?.files?.leasions_area_img,
        //     });
        //   }
        // }

        ///
        // if (
        //   !localStorage.getItem(query.get("stack_id")) &&
        //   res?.data?.grading_form_responses[query.get("user_id")]
        // ) {
        //   if (
        //     res?.data?.grading_form_responses[query.get("user_id")]?.responses
        //       ?.files
        //   ) {
        //     dispatch({
        //       type: "mainScreenShot_url_changed",
        //       mainScreenShotUrl:
        //         res?.data?.grading_form_responses[query.get("user_id")]
        //           ?.responses?.files?.leasions_area_img,
        //     });
        //   }
        // }

        // if (url) {
        //
        //   const newContent = `<div class="flex mt-1">
        //   <div class="image-container">
        //     <img onclick="imageClicked()" id="AOA" src="" alt="Attachment Image" name="leasions_area_img" width="100" height="100" style="cursor: pointer;">
        //     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" id="deleteIconId" style="cursor: pointer;"></svg>
        //   </div>
        // </div>`;
        //   const imageUrl = url;

        //   // Call the function to update the content
        //   updateContentForKey(
        //     res.data,
        //     "leasions_area_img",
        //     newContent,
        //     imageUrl
        //   );
        // } else {

        // }
        if (localStorage.getItem(query.get("stack_id"))) {
          localStorage.removeItem(query.get("stack_id"));
        }
        // const hasSubmitAction =
        //   res.data.adjudication_form_schema.components.some(
        //     (component) => component.action === "submit"
        //   );

        // if (!hasSubmitAction) {
        //   const submitButton = {
        //     key: "submit",
        //     type: "button",
        //     action: "submit",
        //     label: "Submit",
        //   };

        //   // Add the submit button object at the end of the components array
        //   res.data.adjudication_form_schema.components.push(submitButton);
        // }

        // let divElement = document.getElementById("AOA");

        // // Keep checking until divElement is available
        // const checkDivElement = setInterval(() => {
        //   divElement = document.getElementById("AOA");
        //   if (divElement) {
        //     clearInterval(checkDivElement); // Stop checking once divElement is available
        //     divElement.addEventListener("click", imageClicked);
        //   }
        // }, 100);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    loadInitialDataAdjudicator();
  }, []);
  // useEffect(() => {
  //   if (dicom_data?.mainScreenShotUrl) {
  //     loadInitialDataAdjudicator(dicom_data?.mainScreenShotUrl);
  //   }
  // }, [dicom_data.mainScreenShotUrl]);

  const handleExternalSubmit = () => {
    if (formikRef.current) {
      formikRef.current.props.onSubmit(formikRef.current.props.submission);
    }
  };

  const handleButtonClick = () => {
    if (
      formData?.adjudication_form_schema?.components?.filter(
        (obj) =>
          (obj?.components &&
            obj?.components.some(
              (component) => component?.key === "totalArea"
            )) ||
          obj?.key === "totalArea"
      )
    ) {
      console.log("changing toootal area", dicom_data.SelectedArea.TotalArea);
      setFormData((prevState) => ({
        ...prevState,
        responses: {
          ...prevState.responses,
          data: {
            ...prevState.responses?.data,
            totalArea: dicom_data.SelectedArea.TotalArea, // Updating the value here
          },
        },
      }));
    }

    if (
      formData?.adjudication_form_schema?.components?.filter(
        (obj) =>
          (obj?.components &&
            obj?.components.some(
              (component) => component?.key === "lesions_area"
            )) ||
          obj?.key === "lesions_area"
      )
    ) {
      setFormData((prevState) => ({
        ...prevState,
        responses: {
          ...prevState.responses,
          data: {
            ...prevState.responses?.data,
            lesions_area: dicom_data.SelectedArea.LisonsArea,
          },
        },
      }));
    }
  };

  useEffect(() => {
    console.log("dicom_data.SelectedArea", {
      selectedArea: dicom_data.SelectedArea,
    });
    if (
      dicom_data.SelectedArea.TotalArea !==
        formData?.responses?.data?.totalArea &&
      dicom_data.SelectedArea.TotalArea !== null &&
      formData
    ) {
      handleButtonClick();
    }
  }, [dicom_data.SelectedArea, formData, dicom_data.mainScreenShotUrl]);

  const submit_form = async (e) => {
    if (dicom_data?.mainScreenShotUrl) {
      if (e.files) {
        e.files = {
          ...e.files,
          lesions_area: [extractFileName(dicom_data?.mainScreenShotUrl)],
        };
      } else {
        e["files"] = {
          lesions_area: [extractFileName(dicom_data?.mainScreenShotUrl)],
        };
      }
    }

    // const iconElement = document.querySelector(
    //   ".fa.fa-refresh.fa-spin.button-icon-right"
    // );
    // if (iconElement) {
    //   iconElement.style.display = "block";
    // }

    dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });

    await axios
      .post(`${API_URL}/api/v1/qa-form-adjudicator`, {
        stack_id: rq.stack_id,
        user_id: rq.user_id,
        form_type: query.get("form_version_id")
          ? "additional_form"
          : "primary_form",
        form_version_id: query.get("form_version_id")
          ? query.get("form_version_id")
          : "",
        screenshot_url: dicom_data?.mainScreenShotUrl,
        annotations: processAnnotations(dicom_data),
        responses: e,
        status: "completed",
        id: formData.grading_form_id,
        adjudication_id: formData.adjudication_id,
      })
      .then((res) => {
        // setOpen(true);
        setRespMessage({ error: false, message: "Data saved successfully" });
      })
      .catch((err) => {
        setRespMessage({ error: true, message: "Unable to save data" });
      });
    // let iconElemen = document.querySelector(
    //   ".fa.fa-refresh.fa-spin.button-icon-right"
    // );
    // if (iconElemen) {
    //   iconElemen.style.display = "none";
    // }
    dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: false });
  };

  useEffect(() => {
    console.log("forrmDataa", formData);
    dispatch({
      type: "roledropdowndata_changed",
      formDataUserGrading: {
        data: formData.user_gradings,
      },
    });

    const labels_pair = {};
    if (formData) {
      formData.adjudication_form_schema?.components.forEach((element) => {
        element.components?.forEach((sub) => {
          labels_pair[sub.key] = sub.label;
        });
      });
      setLabels(labels_pair);
    }
  }, [formData]);

  if (!formData) {
    return "Form not found";
  }

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "0px 10px",
        overflow: "auto",
        height: "100%",
      }}
    >
      {formData?.previous_visit_data?.previous_record?.data &&
      dicom_data?.dicom_data[query.get("stack_id")]?.OPT?.url ? (
        <PreviousData formData={formData} />
      ) : null}

      {respMessage.message !== "" ? (
        <AlertDialog
          open={respMessage.message === "" ? false : true}
          handleClose={() => {
            setRespMessage({ error: false, message: "" });
          }}
          message={respMessage.message}
          error={respMessage.error}
        />
      ) : null}
      {Object.keys(gradingFormResponse).length > 0 && (
        <div>
          <h5>Previous Gradings</h5>
          <GradingFormResponsesTable
            labelsData={
              formData.adjudication_form_schema.components[0].components[0]
                .values
            }
            imageClicked={imageClicked}
            responses={gradingFormResponse}
            labelsPair={labels}
            query={query}
            // grading_screenshot_urls={formData?.grading_screenshot_urls}
            adjudication_form_schema={formData?.adjudication_form_schema}
          />
        </div>
      )}
      <h5 style={{ marginTop: "10px", marginBottom: "10px" }}>
        Adjudication Form...
      </h5>

      {formData.adjudication_form_schema && (
        <>
          <Form
            form={formData.adjudication_form_schema}
            submission={formData.responses}
            ref={formikRef}
            // renderer={customRenderers}
            // onRender={onRender}
            onSubmit={submit_form}
          />
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "45px",
              height: "300px",
            }}
          >
            {dicom_data?.mainScreenShotUrl ? (
              <img
                onClick={() => {
                  imageClicked(dicom_data?.mainScreenShotUrl);
                }}
                src={dicom_data?.mainScreenShotUrl}
                // src={grading_screenshot_urls[uuid]?.leasions_area[0]}
                alt="ScreenShot"
                height="200px"
                width="200px"
              />
            ) : null}
            <button
              onClick={handleExternalSubmit}
              // disabled={!dicom_data?.mainScreenShotUrl}
              disabled={dicom_data?.submitDisable}
              type="submit"
              style={{
                backgroundColor: dicom_data?.submitDisable ? "gray" : "#1565C0",
                color: "white",
                border: "0px",
                borderRadius: "5px",
                width: "250px",
                height: "50px",
                cursor: "pointer",
              }}
            >
              Submit Adjudication
            </button>
          </div>
        </>
      )}

      {/* <button onClick={handleButtonClick}>Fill Total Area </button> */}
      {/* {respMessage.message !== "" && <div>{respMessage.message}</div>} */}
    </div>
  );
}
