import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function get_value(val) {
  if (typeof val === 'string' || typeof val === 'number') {
    return val
  } else if (Array.isArray(val) && ['number', 'string'].includes(typeof val[0])) {
    var data = "";
    val.forEach((element, index) => {
      if (index !== 0) {
        data += ", ";
      }
      data += element;
    });
    return data;
  } else {
    return ''
  }
}


function renderValue(value, abc) {

  return (
    <>
      {value.map((row, index) => {
        if (Array.isArray(row)) {
          return renderValue(row, abc + 4)
        } else {
          return (
            <>
              <TableRow
                key={row.id}

              >
                <TableCell component="th" scope="row">
                  {String.fromCharCode(160).repeat(abc)}{row.id}
                </TableCell>
                <TableCell>{row.vr}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{get_value(row.Value)}</TableCell>
              </TableRow>
              {Array.isArray(row.Value) && !['number', 'string'].includes(typeof row.Value[0]) && renderValue(row.Value, abc + 4)}
            </>
          )
        }
      })}
    </>
  )
}

function DCMMetaTable(props) {
  return (
    <div className="App">
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead sx={{ bgcolor: "#09f" }}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>VR</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data?.map((row) => (
              <>
                <TableRow
                  key={row.id}
                  sx={{ fontSize: '12px', '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>{row.vr}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{get_value(row.Value)}</TableCell>
                </TableRow>

                {/* {Array.isArray(row.Value) && renderValue(row.Value, 4)} */}
                {Array.isArray(row.Value) && !['number', 'string'].includes(typeof row.Value[0]) && renderValue(row.Value, 4)}

              </>
            ))}

          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DCMMetaTable;
