import cornerstoneTools from "cornerstone-tools";
import path from "./annotation/drawing/path";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

const external = cornerstoneTools.external;

const draw = cornerstoneTools.importInternal("drawing/draw");
//const drawRect = cornerstoneTools.importInternal('drawing/drawRect');
const setShadow = cornerstoneTools.importInternal("drawing/setShadow");
const getNewContext = cornerstoneTools.importInternal("drawing/getNewContext");
//const drawTextBox = cornerstoneTools.importInternal('drawing/drawTextBox');
const drawHandles = cornerstoneTools.importInternal("drawing/drawHandles");
const drawCircle = cornerstoneTools.importInternal("drawing/drawCircle");
const drawLinkedTextBox = cornerstoneTools.importInternal(
  "drawing/drawLinkedTextBox"
);
const drawLine = cornerstoneTools.importInternal("drawing/drawLine");

const getToolState = cornerstoneTools.getToolState;
const toolStyle = cornerstoneTools.toolStyle;
//const textStyle = cornerstoneTools.textStyle;
const toolColors = cornerstoneTools.toolColors;
const getModule = cornerstoneTools.getModule;

//const lineSegDistance = cornerstoneTools.importInternal('util/lineSegDistance');
//const getRGBPixels = cornerstoneTools.importInternal('util/getRGBPixels');
const getPixelSpacing = cornerstoneTools.importInternal("util/getPixelSpacing");
const throttle = cornerstoneTools.importInternal("util/throttle");
const circleRoiCursor = cornerstoneTools.importInternal("cursors/index");
const getROITextBoxCoords = cornerstoneTools.importInternal(
  "util/getROITextBoxCoords"
);
const drawRect = cornerstoneTools.importInternal("drawing/drawRect");

/**
 * @public
 * @class ThicknessMap
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing circular regions of interest, and measuring
 * the statistics of the enclosed pixels.
 * @extends Tools.Base.BaseAnnotationTool
 */

let poly_out_mean = {},
  org_polygons;
let timeout;
let pointX, pointY;
export default class ThicknessMap extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "ThicknessMap",
      supportedInteractionTypes: ["Mouse", "Touch"],
      svgCursor: circleRoiCursor,
      configuration: {
        centerPointRadius: 0,
        renderDashed: false,
        hideHandlesIfMoving: false,
      },
    };

    super(props, defaultProps);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        initialRotation: eventData.viewport.rotation,
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords, interactionType) {
    var viewport = external.cornerstone.getViewport(element);
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;

    const getDistance = external.cornerstoneMath.point.distance;

    if (!hasStartAndEndHandles || data.visible === false) {
      return false;
    }

    const distance = interactionType === "mouse" ? 15 : 25;

    const startCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.start
    );

    var center = data.handles.start;
    var radius1 =
      data.circles_radii[data.circles_radii.length - 1] * viewport.scale;

    // Check if the mouse is inside the circle
    //
    //
    var distanceFromCenter1 = getDistance(startCanvas, coords);
    //
    if (distanceFromCenter1 < radius1) {
      return true;
    }
    return false;
  }

  updateCachedStats(image, element, data) {
    const seriesModule =
      external.cornerstone.metaData.get("generalSeriesModule", image.imageId) ||
      {};
    const modality = seriesModule.modality;
    const pixelSpacing = getPixelSpacing(image);

    data.invalidated = false;
  }

  renderToolData(evt) {
    //
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const getDistance = external.cornerstoneMath.point.distance;
    const eventData = evt.detail;
    const { image, element, canvasContext } = eventData;
    const lineWidth = toolStyle.getToolWidth();
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
      centerPointRadius,
    } = this.configuration;
    const newContext = getNewContext(canvasContext.canvas);
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
    const lineDash = getModule("globalConfiguration").configuration.lineDash;

    // Meta
    const seriesModule =
      external.cornerstone.metaData.get("generalSeriesModule", image.imageId) ||
      {};

    // Pixel Spacing
    const modality = seriesModule.modality;
    const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;

    draw(newContext, (context) => {
      var viewport = external.cornerstone.getViewport(element);
      // If we have tool data for this element, iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }
        let startTime = performance.now();
        // Configure
        const color = toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        setShadow(context, this.configuration);

        const startCanvas = external.cornerstone.pixelToCanvas(
          element,
          data.handles.start
        );

        const endCanvas = external.cornerstone.pixelToCanvas(
          element,
          data.handles.end
        );

        // Calculating the radius where startCanvas is the center of the circle to be drawn
        const radius = getDistance(startCanvas, endCanvas);
        //
        const circleOptions = { color: "blue", lineWidth: 1 };

        if (renderDashed) {
          circleOptions.lineDash = lineDash;
        }
        //
        // Draw Circle
        var circles_radii = [];
        data.circles_radii.forEach((radii) => {
          //

          drawCircle(
            context,
            element,
            data.handles.start,
            radii * viewport.scale, //0.2024739,
            circleOptions,
            "pixel"
          );
          circles_radii.push(radii);
        });

        var sq_root = Math.sqrt(2);

        var img_cols = image.columns * viewport.scale;
        var img_rows = image.rows * viewport.scale;

        var lines_starting_and_ending_points = [
          [
            [
              data.handles.start.x - img_cols / 2,
              data.handles.start.y - img_rows / 2,
            ],
            [data.handles.start.x, data.handles.start.y],
            [data.handles.start.x - circles_radii[2] / sq_root, 0],
            [data.handles.start.x - circles_radii[2] / sq_root, img_cols],
          ],

          [
            [
              data.handles.start.x + img_cols / 2,
              data.handles.start.y - img_rows / 2,
            ],
            [data.handles.start.x, data.handles.start.y],
            [data.handles.start.x + circles_radii[2] / sq_root, 0],
            [data.handles.start.x + circles_radii[2] / sq_root, img_cols],
          ],
          [
            [
              data.handles.start.x - img_cols / 2,
              data.handles.start.y + img_cols / 2,
            ],
            [data.handles.start.x, data.handles.start.y],
            [data.handles.start.x - circles_radii[2] / sq_root, 0],
            [data.handles.start.x - circles_radii[2] / sq_root, img_cols],
          ],

          [
            [
              data.handles.start.x + img_cols / 2,
              data.handles.start.y + img_cols / 2,
            ],
            [data.handles.start.x, data.handles.start.y],
            [data.handles.start.x + circles_radii[2] / sq_root, 0],
            [data.handles.start.x + circles_radii[2] / sq_root, img_cols],
          ],
        ];

        var ending_points = [
          [
            data.handles.start.x - circles_radii[0] / sq_root,
            data.handles.start.y - circles_radii[0] / sq_root,
          ],

          [
            data.handles.start.x + circles_radii[0] / sq_root,
            data.handles.start.y - circles_radii[0] / sq_root,
          ],

          [
            data.handles.start.x - circles_radii[0] / sq_root,
            data.handles.start.y + circles_radii[0] / sq_root,
          ],

          [
            data.handles.start.x + circles_radii[0] / sq_root,
            data.handles.start.y + circles_radii[0] / sq_root,
          ],
        ];

        //for intersecting_lines, ending_point in zip(lines_starting_and_ending_points, ending_points):
        //    A, B, C, D = intersecting_lines
        //    intersection_point = line_intersection((A, B), (C, D))
        //    line_coordinates.append([intersection_point, ending_point])

        var line_COORDINATES = [];
        for (let i = 0; i < lines_starting_and_ending_points.length; i++) {
          var line1 = [
            lines_starting_and_ending_points[i][0],
            lines_starting_and_ending_points[i][1],
          ];
          var line2 = [
            lines_starting_and_ending_points[i][2],
            lines_starting_and_ending_points[i][3],
          ];

          var xdiff = [line1[0][0] - line1[1][0], line2[0][0] - line2[1][0]];
          var ydiff = [line1[0][1] - line1[1][1], line2[0][1] - line2[1][1]];

          var div = xdiff[0] * ydiff[1] - xdiff[1] * ydiff[0];

          var d = [
            line1[0][0] * line1[1][1] - line1[0][1] * line1[1][0],
            line2[0][0] * line2[1][1] - line2[0][1] * line2[1][0],
          ];
          var x = (d[0] * xdiff[1] - d[1] * xdiff[0]) / div;
          var y = (d[0] * ydiff[1] - d[1] * ydiff[0]) / div;

          line_COORDINATES.push([[x, y], ending_points[i]]);
        }

        line_COORDINATES = [
          [
            // Top Left
            line_COORDINATES[0],
            [224, 316],
          ],
          [
            // Top Right
            line_COORDINATES[1],
            [-45, 45],
          ],
          [
            // Bottom Left
            line_COORDINATES[3],
            [45, 135],
          ],
          [
            // Bottom Right
            line_COORDINATES[2],
            [135, 225],
          ],
        ];

        var polygons = [];

        line_COORDINATES.slice(0, 4).forEach((point_data, line_index) => {
          // Draw Line

          var point = point_data[0];
          //

          drawLine(
            context,
            element,
            {
              x: point[0][0],
              y: point[0][1],
            },
            {
              x: point[1][0],
              y: point[1][1],
            },
            circleOptions
          );

          var start_angle = point_data[1][0];
          var end_angle = point_data[1][1];

          circles_radii.slice(0, 2).forEach((radius, index) => {
            if (index < 2) {
              var next_radius = circles_radii[index + 1];
              var circle_points = get_circle_pixels_m(
                data.handles.start.x,
                data.handles.start.y,
                radius,
                start_angle,
                end_angle
              );

              var next_circle_points = get_circle_pixels_m(
                data.handles.start.x,
                data.handles.start.y,
                next_radius,
                start_angle,
                end_angle
              );

              if (line_index == 0) {
                var first_line = get_line_pixels(
                  next_circle_points[0],
                  circle_points[0]
                );

                var last_line = get_line_pixels(
                  circle_points[circle_points.length - 1],
                  next_circle_points[next_circle_points.length - 1]
                );
                last_line = last_line.reverse();
                circle_points = circle_points.reverse();
              } else if (line_index == 1) {
                var first_line = get_line_pixels(
                  circle_points[0],
                  next_circle_points[0]
                );

                var last_line = get_line_pixels(
                  next_circle_points[next_circle_points.length - 1],
                  circle_points[circle_points.length - 1]
                );
                last_line = last_line.reverse();
                circle_points = circle_points.reverse();
              } else if (line_index == 2) {
                var first_line = get_line_pixels(
                  next_circle_points[0],
                  circle_points[0]
                );

                var last_line = get_line_pixels(
                  circle_points[circle_points.length - 1],
                  next_circle_points[next_circle_points.length - 1]
                );
                last_line = last_line.reverse();
                circle_points = circle_points.reverse();
              } else if (line_index == 3) {
                var first_line = get_line_pixels(
                  circle_points[0],
                  next_circle_points[0]
                );

                var last_line = get_line_pixels(
                  next_circle_points[next_circle_points.length - 1],
                  circle_points[circle_points.length - 1]
                );
                last_line = last_line.reverse();
                circle_points = circle_points.reverse();
              }

              // Concat all 4 arrays
              var poly = first_line.concat(
                next_circle_points,
                last_line,
                circle_points
              );

              polygons.push(poly);
            }
          });
        });
        polygons.push(
          get_circle_pixels_m(
            data.handles.start.x,
            data.handles.start.y,
            circles_radii[0],
            0,
            360
          )
        );

        if (
          data.handles.start.x === pointX &&
          data.handles.start.y === pointY &&
          poly_out_mean &&
          org_polygons
        ) {
          drawValues(poly_out_mean, context, element, org_polygons);
          return;
        } else {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            // code to run after 300ms delay

            polyMeanAndDraw(
              data,
              polygons,
              context,
              element,
              centerPointRadius,
              radius,
              image,
              circleOptions,
              startTime,
              eventData,
              handleOptions
            );
          }, 100);
        }
      }
    });
  }
}

function slope(a, b) {
  if (a[0] == b[0]) {
    return null;
  }

  return (b[1] - a[1]) / (b[0] - a[0]);
}

function intercept(point, slope) {
  if (slope === null) {
    // vertical line
    return point[0];
  }

  return point[1] - slope * point[0];
}

function get_line_pixels(A, B) {
  var m = slope(A, B);
  var b = intercept(A, m);

  var coordinates = [];
  for (var x = A[0]; x <= B[0]; x++) {
    var y = m * x + b;
    coordinates.push([parseInt(x), parseInt(y)]);
  }

  return coordinates;
}

function get_circle_pixels(element, centerX, centerY, radius, start, end) {
  var points = [];
  for (var degree = start; degree < end; degree++) {
    var radians = (degree * Math.PI) / 180;
    var x = centerX + radius * Math.cos(radians);
    var y = centerY + radius * Math.sin(radians);
    var noramlized = external.cornerstone.pixelToCanvas(element, {
      x: x,
      y: y,
    });
    points.push([parseInt(noramlized.x), parseInt(noramlized.y)]);
  }

  return points;
}

function get_circle_pixels_m(centerX, centerY, radius, start, end) {
  var points = [];
  for (var degree = start; degree < end; degree++) {
    var radians = (degree * Math.PI) / 180;
    var x = centerX + radius * Math.cos(radians);
    var y = centerY + radius * Math.sin(radians);

    points.push([parseInt(x), parseInt(y)]);
  }

  return points;
}

const is_inside_polygon = (poly, point) => {
  var x = point[0],
    y = point[1];

  var inside = false;
  for (var i = 0, j = poly.length - 1; i < poly.length; j = i++) {
    var xi = poly[i][0],
      yi = poly[i][1];
    var xj = poly[j][0],
      yj = poly[j][1];

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

const all_points_inside_polygon = (poly) => {
  // Get all pixel (x,y) points inside the polygon
  var points = [];
  var min_x = poly.reduce((min, p) => (p[0] < min ? p[0] : min), poly[0][0]);
  var max_x = poly.reduce((max, p) => (p[0] > max ? p[0] : max), poly[0][0]);
  var min_y = poly.reduce((min, p) => (p[1] < min ? p[1] : min), poly[0][1]);
  var max_y = poly.reduce((max, p) => (p[1] > max ? p[1] : max), poly[0][1]);

  for (var x = min_x; x <= max_x; x++) {
    for (var y = min_y; y <= max_y; y++) {
      if (is_inside_polygon(poly, [x, y])) {
        points.push([x, y]);
      }
    }
  }

  return points;
};

const drawValues = (poly_out_mean, context, element, org_polygons) => {
  for (const poly_index_m in poly_out_mean) {
    var poly = org_polygons[poly_index_m];
    var min_x = poly.reduce((min, p) => (p[0] < min ? p[0] : min), poly[0][0]);
    var max_x = poly.reduce((max, p) => (p[0] > max ? p[0] : max), poly[0][0]);
    var min_y = poly.reduce((min, p) => (p[1] < min ? p[1] : min), poly[0][1]);
    var max_y = poly.reduce((max, p) => (p[1] > max ? p[1] : max), poly[0][1]);

    var rect_center_x = (min_x + max_x) / 2;
    var rect_center_y = (min_y + max_y) / 2;
    var poly_mean = poly_out_mean[poly_index_m];
    var poly_mean_text = Math.round(poly_mean);
    var poly_mean_text_width = context.measureText(poly_mean_text).width;
    var poly_mean_text_height = 10;

    var poly_mean_text_x = rect_center_x - poly_mean_text_width / 2;
    var poly_mean_text_y = rect_center_y - poly_mean_text_height / 2;
    poly_mean_text_x -= 20;
    poly_mean_text_y += 10;
    var pp = external.cornerstone.pixelToCanvas(element, {
      x: poly_mean_text_x,
      y: poly_mean_text_y,
    });
    context.font = "12px Arial";
    context.fillStyle = "444";
    context.fillText(poly_mean_text, pp.x, pp.y);
  }
};

const polyMeanAndDraw = (
  data,
  polygons,
  context,
  element,
  centerPointRadius,
  radius,
  image,
  circleOptions,
  startTime,
  eventData,
  handleOptions
) => {
  // poly_out_mean = {};
  // return
  pointX = data.handles.start.x;
  pointY = data.handles.start.y;
  var topLeft = data.rect;
  org_polygons = polygons.slice(0, polygons.length);

  polygons = polygons.map((poly) => {
    // Subtract topLeft from all points
    return poly.map((point) => {
      return [point[0] - topLeft[0], point[1] - topLeft[1]];
    });
  });

  var poly_out = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
  };

  var thickness_image = data.thickness_image;
  if (thickness_image != null) {
    var height = thickness_image.length;
    var width = thickness_image[0].length;
    // Iterate on each point and check if inside any polygon

    //
    // Iterate on each polygon, get all points inside and calculate mean
    polygons.forEach((poly, poly_index) => {
      var min_x = poly.reduce(
        (min, p) => (p[0] < min ? p[0] : min),
        poly[0][0]
      );
      var max_x = poly.reduce(
        (max, p) => (p[0] > max ? p[0] : max),
        poly[0][0]
      );
      var min_y = poly.reduce(
        (min, p) => (p[1] < min ? p[1] : min),
        poly[0][1]
      );
      var max_y = poly.reduce(
        (max, p) => (p[1] > max ? p[1] : max),
        poly[0][1]
      );

      // If min_x or min_y is less than 0, make it zero
      min_x = min_x < 0 ? 0 : min_x;
      min_y = min_y < 0 ? 0 : min_y;

      // If max_x or max_y is greater than width or height, make it width or height
      max_x = max_x >= width ? width - 1 : max_x;
      max_y = max_y >= height ? height - 1 : max_y;

      for (var x = min_x; x <= max_x; x++) {
        for (var y = min_y; y <= max_y; y++) {
          if (is_inside_polygon(poly, [x, y])) {
            if (thickness_image[y][x] !== 0) {
              if (poly_index in poly_out) {
                poly_out[poly_index].push(thickness_image[y][x]);
              } else {
                poly_out[poly_index] = [thickness_image[y][x]];
              }
            }
          }
        }
      }
    });
  }
  // Iteratre on poly_out and calculate mean and assign to data
  // poly_out_mean = {};
  for (const poly_index in poly_out) {
    var mean =
      poly_out[poly_index].reduce((a, b) => a + b, 0) /
      poly_out[poly_index].length;
    poly_out_mean[poly_index] = mean;
  }

  // Iterate on each value and show in middle of polygon
  drawValues(poly_out_mean, context, element, org_polygons);

  if (centerPointRadius && radius > 3 * centerPointRadius) {
    drawCircle(
      context,
      element,
      data.handles.start,
      centerPointRadius,
      circleOptions,
      "pixel"
    );
  }

  if (data.handles) {
    data.handles.start.drawnIndependently = true;
    data.handles.end.drawnIndependently = true;
  }

  drawHandles(context, eventData, data.handles, handleOptions);
  var endTime = performance.now();

  // Update textbox stats
  if (data.invalidated === true) {
    if (data.cachedStats) {
      this.throttledUpdateCachedStats(image, element, data);
    } else {
      // this.updateCachedStats(image, element, data);
    }
  }
};
