import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import SingleFileAnnotations from "./SingleFileAnnotations";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";

import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { API_URL } from "../../../Endpoints";

import { addAnnotationsOPTUpdate } from "../Viewers/utils";

export default function Annotations() {
  const dicom_data = useSelector((state) => state.dcm);
  const dispatch = useDispatch();
  const [dialogData, setDialogData] = useState({ open: false, id: null });
  const handleClose = () => {
    setDialogData({ open: false, id: null });
  };
  const [requestStaus, setRequestStatus] = useState({
    loading: false,
    message: "",
    resp_status: "",
  });

  const fetchLayers = () => {
    setRequestStatus({ ...requestStaus, loading: true });
    const opData = dicom_data.dicom_data[dialogData.id];

    axios
      .post(`${API_URL}/api/v1/oct`, {
        dicom: opData.OPT.image_id.replace("wadouri:", ""),
      })
      .then((res) => {
        addAnnotationsOPTUpdate(
          opData.OPT.stack.imageIds,
          res.data.OPT,
          dicom_data.drawingTools,
          dicom_data.lineSettings.lineNames
        );
        setDialogData({ open: false, id: null });
        setRequestStatus({ loading: false, message: "", resp_status: "" });
        dispatch({ type: "UPDATE_IMAGE" });
      })
      .catch((err) => {});
  };

  const fetchLayersSingleFrame = (dcm_id) => {
    const opData = dicom_data.dicom_data[dcm_id];
    setDialogData({ open: true, id: null });
    setRequestStatus({ ...requestStaus, loading: true });
    cornerstone
      .loadImage(
        opData.OPT.stack.imageIds[opData.OPT.stack.currentImageIdIndex]
      )
      .then(function (image) {
        // convert the image data to a Blob object

        const blob = new Blob([image.getPixelData()]);

        // create a FormData object and add the blob to it
        axios
          .post(`${API_URL}/api/v1/oct`, {
            data: image.getPixelData(),
            width: image.width,
            height: image.height,
          })
          .then((res) => {
            dispatch({
              type: "UPDATE_DATA",
              data: {
                tool: "Line",
                update_type: "single_frame",
                data: res.data.OPT["frame=0"].Line.data,
              },
            });
            setRequestStatus({ loading: false, message: "", resp_status: "" });
            setDialogData({ open: false, id: null });
          })
          .catch((err) => {
            setRequestStatus({ ...requestStaus, message: "", resp_status: "" });
          });
      })
      .catch(function (error) {
        // handle the error
      });
  };

  return (
    <Box>
      <div
        elevation={0}
        sx={{ width: "100%", height: "95vh", overflow: "auto" }}
      >
        {Object.entries(dicom_data.dicom_data).map(
          ([single_dcm, index], index2) => {
            return (
              <Box key={"sdcm-d-" + index2} style={{ marginBottom: "0px" }}>
                <h6
                  style={{
                    margin: 0,
                    backgroundColor: "rgb(160, 165, 170)",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "10px",
                  }}
                >
                  <div style={{ padding: "10px" }}>{single_dcm}</div>{" "}
                  <RefreshIcon
                    onClick={() =>
                      setDialogData({
                        open: true,
                        id: single_dcm,
                      })
                    }
                    style={{ marginTop: "7px", cursor: "pointer" }}
                  />
                </h6>

                {["OP", "OPT"].map((dcm_item) => {
                  return (
                    <SingleFileAnnotations
                      dcm_key={single_dcm}
                      dcm_type={dcm_item}
                      key={`single-${index}-${dcm_item}`}
                      onFetchClick={fetchLayersSingleFrame}
                      dcm_id={single_dcm}
                      data={dicom_data.dicom_data[single_dcm][dcm_item]}
                    />
                  );
                })}
              </Box>
            );
          }
        )}
      </div>
      <Dialog
        open={dialogData.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {requestStaus.loading ? (
          <DialogContent>
            <DialogContentText>Loading...</DialogContentText>
          </DialogContent>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">
              {`Refetch layers using ML for ${dialogData.id}?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You want to fetch layers for all frames in OCT and updated
                layers data?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={fetchLayers} autoFocus>
                Continue
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
}
