import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";

const BaseTool = cornerstoneTools.importInternal("base/BaseTool");
const LengthTool = cornerstoneTools.LengthTool;
const drawTextBox = cornerstoneTools.importInternal("drawing/drawTextBox");

// Extend the LengthTool class
class CoordinateTool extends LengthTool {
  constructor(props = {}) {
    // Call the super constructor
    super(props);

    // Set the tool name
    this.name = "CoordinateTool";
  }

  // Override the _calculateLength method
  _calculateLength(data) {
    // Get the image pixel spacing
    const { rowPixelSpacing, colPixelSpacing } = cornerstone.metaData.get(
      "imagePlaneModule",
      data.image.imageId
    );

    // Get the start and end handles
    const { start, end } = data.handles;

    // Calculate the coordinates in mm
    const x1 = start.x * (colPixelSpacing || 1);
    const y1 = start.y * (rowPixelSpacing || 1);
    const x2 = end.x * (colPixelSpacing || 1);
    const y2 = end.y * (rowPixelSpacing || 1);

    // Return the coordinates as an object
    return {
      start: { x: x1, y: y1 },
      end: { x: x2, y: y2 },
    };
  }

  // Override the renderAnnotation method
  renderAnnotation(evt) {
    // Call the super method
    super.renderAnnotation(evt);

    // Get the tool state
    const toolState = cornerstoneTools.getToolState(
      evt.currentTarget,
      this.name
    );

    // Return if no tool state
    if (!toolState) {
      return;
    }

    // Get the canvas context
    const context = evt.detail.canvasContext;

    // Loop through the tool data
    for (let i = 0; i < toolState.data.length; i++) {
      // Get the data for the current annotation
      const data = toolState.data[i];

      // Get the coordinates from the cached stats
      const { start, end } = data.cachedStats;

      // Format the coordinates as text
      const text = `(${start.x.toFixed(2)}, ${start.y.toFixed(
        2
      )}) - (${end.x.toFixed(2)}, ${end.y.toFixed(2)})`;

      // Get the text color
      const color = cornerstoneTools.toolColors.getColorIfActive(data);

      // Draw the text box next to the annotation
      drawTextBox(context, text, data.handles.end, color);
    }
  }
}

// Export the tool class
export default CoordinateTool;
