import cornerstoneTools from "cornerstone-tools";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

const external = cornerstoneTools.external;

const draw = cornerstoneTools.importInternal("drawing/draw");
//const drawRect = cornerstoneTools.importInternal('drawing/drawRect');
const setShadow = cornerstoneTools.importInternal("drawing/setShadow");
const getNewContext = cornerstoneTools.importInternal("drawing/getNewContext");
//const drawTextBox = cornerstoneTools.importInternal('drawing/drawTextBox');
const drawHandles = cornerstoneTools.importInternal("drawing/drawHandles");
const drawCircle = cornerstoneTools.importInternal("drawing/drawCircle");
const drawLinkedTextBox = cornerstoneTools.importInternal(
  "drawing/drawLinkedTextBox"
);

const getToolState = cornerstoneTools.getToolState;
const toolStyle = cornerstoneTools.toolStyle;
//const textStyle = cornerstoneTools.textStyle;
const toolColors = cornerstoneTools.toolColors;
const getModule = cornerstoneTools.getModule;

//const lineSegDistance = cornerstoneTools.importInternal('util/lineSegDistance');
//const getRGBPixels = cornerstoneTools.importInternal('util/getRGBPixels');
const getPixelSpacing = cornerstoneTools.importInternal("util/getPixelSpacing");
const throttle = cornerstoneTools.importInternal("util/throttle");
const circleRoiCursor = cornerstoneTools.importInternal("cursors/index");
const getROITextBoxCoords = cornerstoneTools.importInternal(
  "util/getROITextBoxCoords"
);

// display area

/**
 * @public
 * @class CircleStatic
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing circular regions of interest, and measuring
 * the statistics of the enclosed pixels.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class CircleStatic extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "CircleStatic",
      supportedInteractionTypes: [],
      svgCursor: circleRoiCursor,
      configuration: {
        centerPointRadius: 0,
        renderDashed: false,
        hideHandlesIfMoving: false,
      },
    };

    super(props, defaultProps);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        initialRotation: eventData.viewport.rotation,
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords, interactionType) {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;

    const getDistance = external.cornerstoneMath.point.distance;

    if (!hasStartAndEndHandles || data.visible === false) {
      return false;
    }

    const distance = interactionType === "mouse" ? 15 : 25;

    const startCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.start
    );

    const endCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.end
    );

    // StartCanvas is the center of the circle
    const distanceFromCenter = getDistance(startCanvas, coords);

    // Getting radius of circle annotation in canvas
    const radius = getDistance(startCanvas, endCanvas);

    // Checking if point is near the tool by comparing its distance from the center of the circle
    return (
      distanceFromCenter > radius - distance / 2 &&
      distanceFromCenter < radius + distance / 2
    );
  }

  updateCachedStats(image, element, data) {
    const seriesModule =
      external.cornerstone.metaData.get("generalSeriesModule", image.imageId) ||
      {};
    const modality = seriesModule.modality;
    const pixelSpacing = getPixelSpacing(image);

    data.invalidated = false;
  }

  renderToolData(evt) {
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const getDistance = external.cornerstoneMath.point.distance;
    const eventData = evt.detail;
    const { image, element, canvasContext } = eventData;
    const lineWidth = toolStyle.getToolWidth();
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
      centerPointRadius,
    } = this.configuration;
    const newContext = getNewContext(canvasContext.canvas);
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
    const lineDash = getModule("globalConfiguration").configuration.lineDash;

    // Meta
    const seriesModule =
      external.cornerstone.metaData.get("generalSeriesModule", image.imageId) ||
      {};

    // Pixel Spacing
    const modality = seriesModule.modality;
    const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;

    draw(newContext, (context) => {
      // If we have tool data for this element, iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }

        // Configure
        const color = toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        setShadow(context, this.configuration);

        const startCanvas = external.cornerstone.pixelToCanvas(
          element,
          data.handles.start
        );

        const endCanvas = external.cornerstone.pixelToCanvas(
          element,
          data.handles.end
        );

        // Calculating the radius where startCanvas is the center of the circle to be drawn
        const radius = getDistance(startCanvas, endCanvas);

        const circleOptions = { color };

        if (renderDashed) {
          circleOptions.lineDash = lineDash;
        }

        // Draw Circle
        drawCircle(
          context,
          element,
          data.handles.start,
          radius,
          circleOptions,
          "pixel"
        );

        if (centerPointRadius && radius > 3 * centerPointRadius) {
          drawCircle(
            context,
            element,
            data.handles.start,
            centerPointRadius,
            circleOptions,
            "pixel"
          );
        }

        if (data.handles) {
          data.handles.start.drawnIndependently = true;
          data.handles.end.drawnIndependently = true;
        }

        drawHandles(context, eventData, data.handles, handleOptions);

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.cachedStats) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        // Default to textbox on right side of ROI
        if (!data.handles.textBox.hasMoved) {
          const defaultCoords = getROITextBoxCoords(
            eventData.viewport,
            data.handles
          );

          Object.assign(data.handles.textBox, defaultCoords);
        }

        const textBoxAnchorPoints = (handles) => (0, 0);

        const textBoxContent = null;

        drawLinkedTextBox(
          context,
          element,
          data.handles.textBox,
          textBoxContent,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          10,
          true
        );
      }
    });
  }
}
