
import React from 'react';
import { Grid, Card, CardMedia, CardContent, CardActions, Typography, Button } from '@mui/material';


export default function GridItem(props) {

    return (
        <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card>
                <CardMedia
                    component="img"
                    height="200"
                    image={props.item.thumbnail} 
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="body" component="div" style={{ minHeight: "47px" }}>
                        {props.item.id}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button href={`/viewer/${props.item.id}`} size="small">Start Grading</Button>
                </CardActions>
            </Card>
        </Grid>
    );
}

