import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  fetchLateralityObject,
  hidePreviousValues,
  showPreviousValues,
} from "../Views/DCMViewer/utils/dicom_utils";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const PreviousData = ({ formData, optUrl }) => {
  const [previousValuesVisible, setpreviousValuesVisible] = useState(false);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const dicom_data = useSelector((state) => state.dcm);
  const dispatch = useDispatch();
  const fetchOptMetaData = async () => {
    if (
      dicom_data?.dicom_data[query.get("stack_id")]?.OPT?.url &&
      !dicom_data?.optMetaData?.Value
    ) {
      console.log("here");
      let objLaterality = await fetchLateralityObject(
        dicom_data?.dicom_data[query.get("stack_id")]?.OPT?.url
      );

      console.log("dicom_data?.dicom_data", objLaterality);
      if (objLaterality?.name === "Laterality")
        dispatch({
          type: "optMetaData_changed",
          optMetaData: objLaterality,
        });
    }
  };
  useEffect(() => {
    fetchOptMetaData();
  }, [dicom_data?.dicom_data]);

  const onToogleButton = () => {
    if (previousValuesVisible) {
      hidePreviousValues(formData?.previous_visit_data);
    } else {
      showPreviousValues(formData, dicom_data?.optMetaData?.Value);
    }

    setpreviousValuesVisible((prev) => !prev);
  };

  return dicom_data?.optMetaData?.Value ? (
    <div
      style={{
        marginTop: "15px",
        marginBottom: "15px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <Button
        variant="outlined"
        color="primary"
        onClick={onToogleButton}
        startIcon={
          previousValuesVisible ? <VisibilityOffIcon /> : <VisibilityIcon />
        }
      >
        {previousValuesVisible ? "Hide " : "Show "}
        History
      </Button>
    </div>
  ) : null;
};

export default PreviousData;
