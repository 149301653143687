import cornerstoneTools from "cornerstone-tools";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

const external = cornerstoneTools.external;

const draw = cornerstoneTools.importInternal("drawing/draw");
const drawLine = cornerstoneTools.importInternal("drawing/drawLine");
const setShadow = cornerstoneTools.importInternal("drawing/setShadow");
const getNewContext = cornerstoneTools.importInternal("drawing/getNewContext");
const drawTextBox = cornerstoneTools.importInternal("drawing/drawTextBox");

const drawLinkedTextBox = cornerstoneTools.importInternal(
  "drawing/drawLinkedTextBox"
);
const getToolState = cornerstoneTools.getToolState;
//const toolStyle = cornerstoneTools.toolStyle
const getModule = cornerstoneTools.getModule;

const lineSegDistance = cornerstoneTools.importInternal("util/lineSegDistance");
const getPixelSpacing = cornerstoneTools.importInternal("util/getPixelSpacing");
const throttle = cornerstoneTools.importInternal("util/throttle");
const lengthCursor = cornerstoneTools.importInternal("cursors/index");

/**
 * @public
 * @class MultiLineTool
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring distances.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class MultiLineTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "MultiLine",
      supportedInteractionTypes: [],
      svgCursor: lengthCursor,
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        digits: 2,
      },
    };

    super(props, defaultProps);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      return;
    }

    const { x, y } = eventData.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: true,
        },
      },
    };
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    return (
      lineSegDistance(element, data.handles.start, data.handles.end, coords) <
      25
    );
  }

  updateCachedStats(image, element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
    const dx =
      (data.handles.end.x - data.handles.start.x) * (colPixelSpacing || 1);
    const dy =
      (data.handles.end.y - data.handles.start.y) * (rowPixelSpacing || 1);

    // Calculate the length, and create the text variable with the millimeters or pixels suffix
    const length = Math.sqrt(dx * dx + dy * dy);

    // Store the length inside the tool for outside access
    data.length = length;
    data.invalidated = false;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { renderDashed } = this.configuration;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const { element } = eventData;
    //const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    //const lineWidth = toolStyle.getToolWidth();
    const lineDash = getModule("globalConfiguration").configuration.lineDash;

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, (context) => {
        // Configurable shadow
        setShadow(context, this.configuration);

        const color = "red"; //toolColors.getColorIfActive(data);
        const size = 20;
        const textColor = "#91c920";
        //
        // const lineOptions = { color, size };
        let pointsArray = data.data[0].handles.points;

        for (let j = 0; j < pointsArray.length; j++) {
          const lineOptions = { color: data.data[0].color };
          if (renderDashed) {
            lineOptions.lineDash = lineDash;
          }

          let start = {
            x: pointsArray[j][0][0],
            y: pointsArray[j][0][1],
            highlight: true,
            active: false,
          };

          let end = {
            x: pointsArray[j][1][0],
            y: pointsArray[j][1][1],
            highlight: true,
            active: false,
          };
          let x1 = pointsArray[0][0][0];
          let y1 = pointsArray[0][0][1];

          let x2 = pointsArray[0][1][0];
          let y2 = pointsArray[0][1][1];

          let midxline = Math.floor((x1 + x2) / 2);
          let midyline = Math.floor((y1 + y2) / 2);
          //
          // Draw the measurement line
          drawLine(context, element, start, end, lineOptions);
          const xOffset = 10;
          const text = data.data[0].label;
          // let pointsArray = data[0].handles.points;

          // context.font = "bold 22px Arial";

          drawLinkedTextBox(
            context,
            element,
            data.data[0]?.label_coordinates,
            text,
            {
              end: {
                x: pointsArray[pointsArray.length - 1][1][0],
                y: pointsArray[pointsArray.length - 1][1][1],
              },
              start: {
                x: pointsArray[pointsArray.length - 2][1][0],
                y: pointsArray[pointsArray.length - 2][1][1],
              },
            },
            textBoxAnchorPoints,
            "white",
            5,
            xOffset,
            true
          );

          // lisons area display on bottom left

          const textarea = `${data.data[0]?.label}: ${
            data.data[0]?.area
          } mm${String.fromCharCode(178)}`;
          if (data.data[0]?.label === "L1" && data.data[0]?.total_area) {
            let total_coordinates = {
              x: data.data[0]?.area_coordinates.x,
              y: data.data[0]?.area_coordinates.y - 64,
            };
            const total_textarea = `${"TOT"}: ${
              data.data[0]?.total_area
            } mm${String.fromCharCode(178)}`;
            drawLinkedTextBox(
              context,
              element,
              total_coordinates,
              total_textarea,
              {
                end: {
                  x: pointsArray[pointsArray.length - 1][1][0],
                  y: pointsArray[pointsArray.length - 1][1][1],
                },
                start: {
                  x: pointsArray[pointsArray.length - 2][1][0],
                  y: pointsArray[pointsArray.length - 2][1][1],
                },
              },
              textBoxAnchorPoints,
              "white",
              5,
              xOffset,
              true
            );
          }
          drawLinkedTextBox(
            context,
            element,
            data.data[0]?.area_coordinates,
            textarea,
            {
              end: {
                x: pointsArray[pointsArray.length - 1][1][0],
                y: pointsArray[pointsArray.length - 1][1][1],
              },
              start: {
                x: pointsArray[pointsArray.length - 2][1][0],
                y: pointsArray[pointsArray.length - 2][1][1],
              },
            },
            textBoxAnchorPoints,
            "white",
            5,
            xOffset,
            true
          );
        }

        // const textarea = `${data[0]?.label}: ${data[0]?.area.toPrecision(
        //   4
        // )} mm2`;
        // drawLinkedTextBox(
        //   context,
        //   element,
        //   data[0]?.area_coordinates,
        //   textarea,
        //   {
        //     end: {
        //       x: pointsArray[pointsArray.length - 1].x,
        //       y: pointsArray[pointsArray.length - 1].y,
        //     },
        //     start: {
        //       x: pointsArray[pointsArray.length - 2].x,
        //       y: pointsArray[pointsArray.length - 2].y,
        //     },
        //   },
        //   textBoxAnchorPoints,
        //   "white",
        //   5,
        //   xOffset,
        //   true
        // );

        // // Coords for text
        // const coords = {
        //   // Translate the x/y away from the cursor
        //   x: 0,
        //   y: 0,
        // };
        // const textCoords = external.cornerstone.pixelToCanvas(
        //   eventData.element,
        //   coords
        // );

        // drawTextBox(
        //   context,
        //   data.frame,
        //   textCoords.x,
        //   textCoords.y + 5,
        //   textColor
        // );
      });
    }
    function textBoxAnchorPoints(handles) {
      const midpoint = {
        x: (handles.start.x + handles.end.x) / 2,
        y: (handles.start.y + handles.end.y) / 2,
      };

      return [handles.start, midpoint, handles.end];
    }
  }
}
