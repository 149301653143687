import * as React from "react";
import Box from "@mui/material/Box";
import SingleAnnotation from "./SingleAnnotation";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import RefreshIcon from "@mui/icons-material/Refresh";

export default function SingleFileAnnotations(props) {
  const dicom_data = useSelector((state) => state.dcm);
  const dispatch = useDispatch();

  const toggleLines = (type) => {
    dispatch({
      type: "ANNOTATION_CHANGE_REQUEST",
      stack_id: props.dcm_key,
      key: props.dcm_type,
      payload: {
        index: null,
        annot_type: "Line",
        action_type: type,
      },
    });
  };

  return (
    <div>
      <Box
        style={{
          padding: "6px 10px",
          backgroundColor: "rgb(215, 217, 220)",
          fontSize: 15,
          borderBottom: "1px solid #ccc",
        }}
      >
        {props.dcm_type}
      </Box>
      {props.data === undefined || props.data === null ? (
        <h6 style={{ color: "#09f" }}>No annotations available</h6>
      ) : (
        <Box>
          {Object.entries(props.data.annotations).map((key, value) => {
            return key[1] === undefined ||
              key[1].data.length === 0 ? null : key[0] !== "Brush" ||
              key[0] === "LisonProbe" ? (
              <Box
                key={"annotation-" + key}
                style={{ marginBottom: "0px" }}
                sx={{
                  bgcolor: "rgba(210, 205, 200, 0.2)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "10px",
                  }}
                >
                  <div style={{ display: "flex", paddingLeft: "4px" }}>
                    {key[0] === "Line" && (
                      <input
                        type="checkbox"
                        checked={key[1].visible}
                        onChange={() => toggleLines("visibility_all")}
                      />
                    )}
                    {key[0] !== "LisonProbe" ? (
                      <h6
                        style={{ margin: 0, fontSize: 14, padding: "6px 10px" }}
                      >
                        {key[0] === "MultiPointProbe" ||
                        key[0] === "MultiPointProbe"
                          ? `${key[1].data.length > 1 ? "Lesions" : "Lesion"} ${
                              dicom_data.lisonOptions.lisonAreaselected
                            } Area`
                          : key[0] === "Lison"
                          ? "Lesion"
                          : key[0]}
                        ({key[1].data.length})
                      </h6>
                    ) : null}
                  </div>
                  {key[0] === "Line" && (
                    <div style={{ display: "flex" }}>
                      <RefreshIcon
                        fontSize="small"
                        onClick={() => props.onFetchClick(props.dcm_id)}
                        style={{
                          marginTop: "4px",
                          cursor: "pointer",
                        }}
                      />
                      <span style={{ fontSize: 14, padding: "3px 10px" }}>
                        Handles
                        <input
                          style={{ marginLeft: "5px", paddingTop: 4 }}
                          type="checkbox"
                          checked={key[1].handles}
                          onChange={() => toggleLines("toggle_handles")}
                        />
                      </span>
                    </div>
                  )}
                </div>
                {key[1].data.map((annotation, index) => {
                  return (
                    <SingleAnnotation
                      dcm_key={props.dcm_key}
                      dcm_type={props.dcm_type}
                      annot_type={key[0]}
                      key={`annott-${key}-${index}`}
                      item={annotation}
                      label={key[0]}
                      index={index}
                    />
                  );
                })}
              </Box>
            ) : null;
          })}
        </Box>
      )}
    </div>
  );
}
