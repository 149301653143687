import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-formio";
import axios from "axios";
import { API_URL } from "../../../Endpoints";
import { useDispatch, useSelector } from "react-redux";

import { extractFileName } from "../utils/basic_utils";
import AlertDialog from "../../../Components/Dialog";
import { useLocation } from "react-router-dom";
import PreviousData from "../../../Components/previousDataButton";
export default function QAForm(props) {
  const [formData, setFormData] = useState({});
  const [formDataLoading, setFormDataLoading] = useState(true);
  const dicom_data = useSelector((state) => state.dcm);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [removedComponent, setRemovedComponent] = useState(null);
  const [respMessage, setRespMessage] = useState({
    error: false,
    message: "",
  });
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const formikRef = useRef(null);

  const imageClicked = () => {
    if (dicom_data?.mainScreenShotUrl !== "") {
      dispatch({
        type: "displayScreenShot_url_changed",
        displayScreenShotUrl: dicom_data?.mainScreenShotUrl,
      });

      dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });
      dispatch({ type: "screenShot_Display_changed", screenShotDisplay: true });
    }
  };

  const uploadFile = async () => {
    if (!file) {
      return;
    }

    try {
      // Generate the payload object from the selected file
      const payload = await getPayloadFromFile(file);

      const response = await fetch(
        "https://staging.eyesol.net/rails/active_storage/direct_uploads",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token":
              "xb93fRKO7RJOt8zmy0HroOcFuH8Imn2mp8eba+WAV/2q2wik0QMZq/kZqyx7l0Kxemv78qsFWjLTIe4z8e5XSA==",
            // Add any other required headers here
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const uploadedUrl = data.url;
        // Assuming the server returns the uploaded URL
      } else {
      }
    } catch (error) {}
  };

  // Helper function to generate the payload object from the selected file
  const getPayloadFromFile = (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const byteArray = new Uint8Array(arrayBuffer);
        const checksum = btoa(
          String.fromCharCode(
            ...new Array(16).fill(0).map((_, i) => byteArray[i] || 0)
          )
        );

        resolve({
          blob: {
            filename: file.name,
            content_type: file.type,
            byte_size: file.size,
            checksum,
          },
        });
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsArrayBuffer(file);
    });
  };
  const rq = props.request_info;
  const handleButtonClick = () => {
    if (
      formData?.form_schema?.components?.filter(
        (obj) =>
          (obj?.components &&
            obj?.components.some(
              (component) => component?.key === "totalArea"
            )) ||
          obj?.key === "totalArea"
      )
    ) {
      setFormData((prevState) => ({
        ...prevState,
        response: {
          ...prevState.response,
          data: {
            ...prevState.response?.data,

            totalArea: dicom_data.SelectedArea.TotalArea, // Updating the value here
          },
        },
      }));
    }

    if (
      formData?.form_schema?.components?.filter(
        (obj) =>
          (obj?.components &&
            obj?.components.some(
              (component) => component?.key === "lesions_area"
            )) ||
          obj?.key === "lesions_area"
      )
    ) {
      setFormData((prevState) => ({
        ...prevState,
        response: {
          ...prevState.response,
          data: {
            ...prevState.response?.data,
            lesions_area: dicom_data.SelectedArea.LisonsArea,
          },
        },
      }));
    }
  };

  useEffect(() => {
    if (
      dicom_data.SelectedArea.TotalArea !==
        formData?.response?.data?.totalArea &&
      formData
    ) {
      console.log("handling click");
      handleButtonClick();
    }
  }, [dicom_data.SelectedArea, formData]);
  useEffect(() => {
    console.log("handling click Data", formData);
  }, [formData]);

  const loadInitialData = async () => {
    setFormDataLoading(true);

    console.log("rqq", rq);
    await axios
      .get(
        `${API_URL}/api/v1/${
          props.request_info.role === "quality_control" ? "qc-form" : "qa-form"
        }?stack_id=${rq.stack_id}&user_id=${rq.user_id}${
          query.get("form_version_id")
            ? `&form_version_id=${query.get("form_version_id")}`
            : ""
        }`
      )
      .then((res) => {
        const index =
          res?.data?.form_schema?.components[0]?.components.findIndex(
            (component) => component.key === "leasions_area_img"
          );

        if (index !== -1) {
          // Store the removed component in state
          const [removed] =
            res?.data?.form_schema?.components[0]?.components.splice(index, 1);
          setRemovedComponent(removed);

          // Now components array is updated without the removed component
          // You can update the state or handle the new array as needed
        }

        ///

        let data = res.data;
        const modifiedData = {
          ...data, // Spread the original data object
          form_schema: {
            ...data.form_schema, // Spread the form_schema object
            components: data.form_schema.components.map((component) => {
              if (component.components) {
                // If the component has nested components
                return {
                  ...component,
                  components: component.components.map((nestedComponent) => {
                    if (
                      nestedComponent.key === "totalArea" ||
                      nestedComponent.key === "lesions_area"
                    ) {
                      // If the nested component has the key "totalArea" or "lesions_area"
                      return {
                        ...nestedComponent,
                        disabled: true, // Add the disabled property
                      };
                    }
                    return nestedComponent;
                  }),
                };
              }
              return component;
            }),
          },
        };
        setFormData(modifiedData);
      })
      .catch((err) => {})
      .finally(() => {
        setFormDataLoading(false);
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    // loadInitialData();

    if (
      formData?.screenshot_urls?.lesions_area &&
      dicom_data?.mainScreenShotUrl === null
    ) {
      if (dicom_data?.mainScreenShotUrl === null) {
        console.log("changed to QA ");
        dispatch({
          type: "mainScreenShot_url_changed",
          mainScreenShotUrl: formData.screenshot_urls.lesions_area[0],
        });
      }
    }
  }, [formData]);
  const handleExternalSubmit = () => {
    if (formikRef.current) {
      formikRef.current.props.onSubmit(formikRef.current.props.submission);
    }
  };

  const submit_form = async (e) => {
    if (dicom_data?.mainScreenShotUrl) {
      if (e.files) {
        e.files = {
          ...e.files,
          lesions_area: [extractFileName(dicom_data?.mainScreenShotUrl)],
        };
      } else {
        e["files"] = {
          lesions_area: [extractFileName(dicom_data?.mainScreenShotUrl)],
        };
      }
    }

    dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });
    await axios
      .post(
        `${API_URL}/api/v1/${
          props.request_info.role === "quality_control" ? "qc-form" : "qa-form"
        }`,
        {
          stack_id: rq.stack_id,
          user_id: rq.user_id,
          responses: e,
          form_type: query.get("form_version_id")
            ? "additional_form"
            : "primary_form",
          form_version_id: formData.form_version_id,
          screenshot_url: dicom_data?.mainScreenShotUrl,
          status: "completed",
          disclaimer: false,
        }
      )
      .then((res) => {
        setRespMessage({ error: false, message: "Data saved successfully" });
      })
      .catch((err) => {
        setRespMessage({ error: true, message: "Unable to save data" });
      })
      .finally(() => {
        dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: false });
      });
  };

  if (formDataLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Added to center vertically
          height: "100%", // Added to center vertically
        }}
      >
        <h3>Loading...</h3>
      </div>
    );
  }
  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "0px 10px",
        overflow: "auto",
        height: "100%",
      }}
    >
      {formData?.previous_visit_data?.previous_record?.data ? (
        <PreviousData formData={formData} />
      ) : null}

      {respMessage.message !== "" ? (
        <AlertDialog
          open={respMessage.message === "" ? false : true}
          handleClose={() => {
            setRespMessage({ error: false, message: "" });
          }}
          message={respMessage.message}
          error={respMessage.error}
        />
      ) : null}
      {formData ? (
        <>
          {formData.form_schema && (
            <Form
              form={formData.form_schema}
              submission={formData.response}
              onSubmit={submit_form}
              ref={formikRef}
              // renderer={customRenderers}
            ></Form>
          )}

          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "45px",
              height: "300px",
            }}
          >
            {dicom_data?.mainScreenShotUrl ? (
              <img
                onClick={() => {
                  imageClicked(dicom_data?.mainScreenShotUrl);
                }}
                src={dicom_data?.mainScreenShotUrl}
                // src={grading_screenshot_urls[uuid]?.leasions_area[0]}
                alt="ScreenShot"
                height="200px"
                width="200px"
              />
            ) : null}
            <button
              onClick={handleExternalSubmit}
              // disabled={
              //   query.get("role") !== "quality_control" &&
              //   !dicom_data?.mainScreenShotUrl === null
              // }
              disabled={dicom_data?.submitDisable}
              type="submit"
              style={{
                backgroundColor: dicom_data?.submitDisable ? "gray" : "#1565C0",
                color: "white",
                border: "0px",
                borderRadius: "5px",
                width: "250px",
                height: "50px",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        <div>Unable to load form</div>
      )}

      {respMessage.message !== "" && <div>{respMessage.message}</div>}
    </div>
  );
}
