import cornerstoneTools from "cornerstone-tools";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

const external = cornerstoneTools.external;

const draw = cornerstoneTools.importInternal("drawing/draw");
const getNewContext = cornerstoneTools.importInternal("drawing/getNewContext");
const drawHandles = cornerstoneTools.importInternal("drawing/drawHandles");

const getToolState = cornerstoneTools.getToolState;
const toolColors = cornerstoneTools.toolColors;
const getModule = cornerstoneTools.getModule;
const textStyle = cornerstoneTools.textStyle;
const toolStyle = cornerstoneTools.toolStyle;

const drawLinkedTextBox = cornerstoneTools.importInternal(
  "drawing/drawLinkedTextBox"
);
const getRGBPixels = cornerstoneTools.importInternal("util/getRGBPixels");
const calculateSUV = cornerstoneTools.importInternal("util/calculateSUV");
const throttle = cornerstoneTools.importInternal("util/throttle");
const probeCursor = cornerstoneTools.importInternal("cursors/index");

/**
 * @public
 * @class MultiPointProbeTool
 * @memberof Tools.Annotation
 * @classdesc Tool which provides probes of the image data at multiple
 * desired positions.
 * @extends Tools.Base.BaseAnnotationTool
 */

let colorMap = {
  "rgba(255, 0, 0, 0.09)": "red",
  "rgba(0, 128, 0, 0.09)": "green",
  "rgba(255, 255, 0, 0.09)": "yellow",
  "rgba(0, 0, 255, 0.09)": "blue",
  "rgba(128, 0, 128, 0.09)": "purple",
};
export default class MultiPointProbeTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "MultiPointProbe",
      supportedInteractionTypes: ["Mouse", "Touch"],
      svgCursor: probeCursor,
      configuration: {
        drawHandles: true,
        renderDashed: false,
      },
    };

    super(props, defaultProps);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        points: [
          {
            x: eventData.currentPoints.image.x,
            y: eventData.currentPoints.image.y,
            highlight: true,
            active: true,
          },
        ],
      },
    };
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    const hasPoints = data && data.handles && data.handles.points;
    const validParameters = hasPoints;

    if (!validParameters || data.visible === false) {
      return false;
    }

    for (let i = 0; i < data.handles.points.length; i++) {
      const probeCoords = external.cornerstone.pixelToCanvas(
        element,
        data.handles.points[i]
      );

      if (external.cornerstoneMath.point.distance(probeCoords, coords) < 5) {
        return true;
      }
    }

    return false;
  }

  updateCachedStats(image, element, data) {
    const stats = {
      points: [],
    };

    for (let i = 0; i < data.handles.points.length; i++) {
      const x = Math.round(data.handles.points[i].x);
      const y = Math.round(data.handles.points[i].y);

      if (x >= 0 && y >= 0 && x < image.columns && y < image.rows) {
        const pointStats = {
          x,
          y,
        };

        if (image.color) {
          pointStats.storedPixels = getRGBPixels(element, x, y, 1, 1);
        } else {
          pointStats.storedPixels = external.cornerstone.getStoredPixels(
            element,
            x,
            y,
            1,
            1
          );
          pointStats.sp = pointStats.storedPixels[0];
          pointStats.mo = pointStats.sp * image.slope + image.intercept;
          pointStats.suv = calculateSUV(image, pointStats.sp);
        }

        stats.points.push(pointStats);
      }
    }

    data.cachedStats = stats;
    data.invalidated = false;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { handleRadius, renderDashed } = this.configuration;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const { image, element } = eventData;
    const fontHeight = textStyle.getFontSize();
    const lineWidth = toolStyle.getToolWidth();
    const lineDash = getModule("globalConfiguration").configuration.lineDash;

    let toolDataLength = toolData.data.length;

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i].data;

      // if (data.visible === false) {
      //   continue;
      // }

      draw(context, (context) => {
        // const color = toolColors.getColorIfActive(data);
        let colorData = data[0].color;

        if (this.configuration.drawHandles) {
          // Draw the handles
          // let handleOptions = { handleRadius, color };
          // if (renderDashed) {
          //   handleOptions.lineDash = lineDash;
          // }
          //   let pointsA = data.data.handles.points;

          for (let j = 0; j < data[0].handles.points.length; j++) {
            //
            // //
            //
            let options = {
              color: colorData,
              handleRadius: 1,
            };
            drawHandles(
              context,
              eventData,
              {
                end: { ...data[0].handles.points[j] },
              },
              options
            );
          }
        }

        // data.handles.textBox.x = coords.x;
        // data.handles.textBox.y = coords.y;
        const xOffset = 10;
        const text = data[0]?.label;
        let pointsArray = data[0].handles.points;

        context.font = "bold 22px Arial";

        drawLinkedTextBox(
          context,
          element,
          data[0]?.label_coordinates,
          text,
          {
            end: {
              x: pointsArray[pointsArray.length - 1].x,
              y: pointsArray[pointsArray.length - 1].y,
            },
            start: {
              x: pointsArray[pointsArray.length - 2].x,
              y: pointsArray[pointsArray.length - 2].y,
            },
          },
          textBoxAnchorPoints,
          "white",
          5,
          xOffset,
          true
        );

        //printing area of each on bottom Left
        const textarea = `${data[0]?.label}: ${
          data[0]?.area
        } mm${String.fromCharCode(178)}`;

        if (data[0]?.label === "L1" && data[0]?.total_area) {
          let total_coordinates = {
            x: data[0]?.area_coordinates.x,
            y: data[0]?.area_coordinates.y - 64,
          };
          const total_textarea = `${"TOT"}: ${
            data[0]?.total_area
          } mm${String.fromCharCode(178)}`;
          drawLinkedTextBox(
            context,
            element,
            total_coordinates,
            total_textarea,
            {
              end: {
                x: pointsArray[pointsArray.length - 1].x,
                y: pointsArray[pointsArray.length - 1].y,
              },
              start: {
                x: pointsArray[pointsArray.length - 2].x,
                y: pointsArray[pointsArray.length - 2].y,
              },
            },
            textBoxAnchorPoints,
            "white",
            5,
            xOffset,
            true
          );
        }
        drawLinkedTextBox(
          context,
          element,
          data[0]?.area_coordinates,
          textarea,
          {
            end: {
              x: pointsArray[pointsArray.length - 1].x,
              y: pointsArray[pointsArray.length - 1].y,
            },
            start: {
              x: pointsArray[pointsArray.length - 2].x,
              y: pointsArray[pointsArray.length - 2].y,
            },
          },
          textBoxAnchorPoints,
          "white",
          5,
          xOffset,
          true
        );
        // Update textbox stats
        // if (data.invalidated === true) {
        //   if (data.cachedStats) {
        //     this.throttledUpdateCachedStats(image, element, data);
        //   } else {
        //     this.updateCachedStats(image, element, data);
        //   }
        // }
        // for (let j = 0; j < data.cachedStats.points.length; j++) {
        //   const { x, y } = data.cachedStats.points[j];
        //   if (x >= 0 && y >= 0 && x < image.columns && y < image.rows) {
        //     const text = `${x}, ${y}`;
        //     let str = "";
        //     if (data.label !== undefined) {
        //       str = data.label;
        //     }
        //     if (data.calibration !== undefined) {
        //       str += " - " + data.calibration;
        //     }
        //     // Coords for text
        //     const coords = {
        //       x: data.handles.points[j].x + 3,
        //       y: data.handles.points[j].y - 3,
        //     };
        //     const textCoords = external.cornerstone.pixelToCanvas(
        //       eventData.element,
        //       coords
        //     );
        //     // drawTextBox(context, text, textCoords.x, textCoords.y, color);
        //   }
        // }
      });
    }
    function textBoxAnchorPoints(handles) {
      const midpoint = {
        x: (handles.start.x + handles.end.x) / 2,
        y: (handles.start.y + handles.end.y) / 2,
      };

      return [handles.start, midpoint, handles.end];
    }
  }
}
