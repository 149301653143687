import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MovingIcon from "@mui/icons-material/Moving";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import LensIcon from "@mui/icons-material/Lens";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CallMade from "@mui/icons-material/CallMade";
import MouseIcon from "@mui/icons-material/Mouse";
import cornerstoneTools from "cornerstone-tools";
import { useSelector, useDispatch } from "react-redux";
import { addTools } from "./Viewers/utils";
import BrushIcon from "@mui/icons-material/Brush";
import BrushToolSettings from "./Settings/BrushTool";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import { Alert, Button, IconButton } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import RedoIcon from "@mui/icons-material/Redo";
import axios from "axios";
import Select from "@mui/material/Select";
import {
  Submit_Lison_annotations,
  extractPointsCoordinates,
  get_annotations,
  sendFileUpload,
} from "./actions/dcm_actions";
import { useLocation, useParams } from "react-router-dom";
import { responseLison } from "./Viewers/testMl";
import { toPng } from "html-to-image";
import { CircularProgress } from "@mui/material";

let prevSs = "";
export default function ToolButtons({ props }) {
  const [checked, setChecked] = React.useState(false);
  const [submiting, setsubmitting] = React.useState(false);
  const [imageReceieved, setImageReceieved] = React.useState(true);
  const [imageDownloadPressed, setImageDownloadPressed] = React.useState(false);

  const [file, setFile] = React.useState(null);
  const dispatch = useDispatch();
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const dicom_data = useSelector((state) => state.dcm);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    // try {
    //   const response = await axios.post(
    //     "http://localhost:5000/api/v1/file-upload",
    //     formData,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );
    //
    // } catch (error) {
    //
    // }
  };

  React.useEffect(() => {
    if (
      dicom_data.dicom_data[query.get("stack_id")] === undefined ||
      dicom_data.dicom_data[query.get("stack_id")].OP === null ||
      dicom_data.dicom_data[query.get("stack_id")].OPT === null
    ) {
      setImageReceieved(true);
    } else {
      setImageReceieved(false);
    }
  }, [dicom_data?.dicom_data[query.get("stack_id")]]);

  const handleChange = (event) => {
    setChecked(event.target.checked);

    dispatch({
      type: "Change_Lison_Active",
      lisonStatus: event.target.checked,
    });
    dispatch({
      type: "ANNOTATION_CHANGE_REQUEST",
      stack_id: query.get("stack_id"),
      key: "OPT",
      payload: {
        index: 0,
        annot_type: "Lison",
        action_type: "toggle_Lison",
      },
    });
  };

  React.useEffect(() => {
    if (dicom_data?.lisonsAreasData?.finish_time) {
      //   handleChange({ target: { checked: true } });
      const timeout = setTimeout(() => {
        // Code to be executed after 300ms
        handleChange({ target: { checked: true } });
      }, 500);

      // Clear the timeout if the component unmounts or if dependency changes
      return () => clearTimeout(timeout);
    }
  }, [dicom_data.lisonsAreasData]);

  // React.useEffect(() => {
  //   setChecked(dicom_data.LisonActive);
  // }, [dicom_data.LisonActive]);

  const otherTools = ["Zoom", "Pan", "Eraser"];
  const dcmData = useSelector((state) => state.dcm);
  const drawingTools = dcmData.drawingTools;

  const changeTool = (event, tool_name) => {
    // if (tool_name !== "Lison") {
    //   setChecked(false);
    // }

    if (drawingTools.includes(tool_name) || otherTools.includes(tool_name)) {
      addTools(tool_name);
    } else if (tool_name === null) {
      cornerstoneTools.setToolPassive(dcmData.selectedTool);
      dispatch({ type: "CHANGE_SELECTED_TOOL", payload: "MOUSE" });
      return;
    } else {
      cornerstoneTools.setToolPassive(dcmData.selectedTool);
    }
    dispatch({ type: "CHANGE_SELECTED_TOOL", payload: tool_name });
  };
  const SaveInputLisonData = (inputData) => {
    dispatch({ type: "Lison_Input_changed", MlLisonsInputData: inputData });
  };

  const OnAreaTransfer = () => {
    dispatch({
      type: "selected_area_Changed",
      SelectedArea: {
        ...dicom_data.SelectedArea,
        status: true,
      },
    });
  };

  const mlDataUpdated = (inputData, outputData) => {
    dispatch({
      type: "mlLesion_Data_changed",
      mlLesionPlayloadData: inputData,
      mlLesionResponseData: outputData,
    });
  };
  const onSubmitClick = async () => {
    dispatch({
      type: "submitDisabled_changed",
      submitDisable: true,
    });
    if (dicom_data.mainScreenShotUrl !== "") {
      prevSs = dicom_data.mainScreenShotUrl;
    }
    console.log("changed to empty ");
    dispatch({
      type: "mainScreenShot_url_changed",
      mainScreenShotUrl: "",
    });
    dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });
    dispatch({ type: "Lison_Submit_Status_changed", SubmitLisonsStatus: true });

    setsubmitting(true);
    let submitResponse = await Submit_Lison_annotations(
      dcmData,
      query.get("stack_id"),
      SaveInputLisonData,
      mlDataUpdated
    );

    submitResponse?.area_versions.forEach((area) => {
      // Calculate total area size for each area_type
      const total_area = area.lesions.reduce(
        (total, lesion) => total + lesion.area_size_mm2,
        0
      );

      // Add total_area property to each area_type object
      area.total_area = total_area;
    });

    const areaTypes = submitResponse?.area_versions.map((obj) => obj.area_type);

    dispatch({ type: "Set_Lisons_Area_Data", lisonsAreasData: submitResponse });

    // Now 'areaTypes' is an array containing the "area_type" values

    let options = {
      ...dicom_data.lisonOptions,
    };

    options["lisonAreaOptions"] = areaTypes;
    dispatch({
      type: "Lison_Option_changed",
      lisonOptions: options,
    });

    const executeScreenshot = async () => {
      await captureScreenshot();
    };

    setTimeout(() => {
      executeScreenshot();
    }, 3000);

    // dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: false });
  };

  const onChangeLisonArea = (event) => {
    let options = {
      ...dicom_data.lisonOptions,
    };

    options[event.target.name] = event.target.value;
    dispatch({
      type: "Lison_Option_changed",
      lisonOptions: options,
    });
  };

  const downloadScreenShot = async () => {
    setImageDownloadPressed(true);
    await toPng(dicom_data.dicomRef.current)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${dicom_data?.basicMetaData?.study_code}_${dicom_data?.basicMetaData?.subject_code}_${dicom_data?.basicMetaData?.visit_label}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {})
      .finally(() => {
        setImageDownloadPressed(false);
      });
  };
  const captureScreenshot = async () => {
    // dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });
    try {
      const dataUrl = await toPng(dicom_data.OpRef.current);
      const blob = await (await fetch(dataUrl)).blob();
      const file = new File(
        [blob],
        `${dicom_data?.basicMetaData?.study_code}_${dicom_data?.basicMetaData?.subject_code}_${dicom_data?.basicMetaData?.visit_label}.png`,
        { type: blob.type }
      );
      let responseFileUplaod = await sendFileUpload(file);
      // const formData = new FormData();
      // formData.append('file', file);

      // const response = await axios.post('http://localhost:5000/api/v1/file-upload', formData, {
      //     headers: {
      //         'Content-Type': 'multipart/form-data'
      //     }
      // });

      console.log("changed to new ");
      dispatch({
        type: "submitDisabled_changed",
        submitDisable: false,
      });
      dispatch({
        type: "mainScreenShot_url_changed",
        mainScreenShotUrl: responseFileUplaod.urls[0],
      });
    } catch (error) {
    } finally {
      // dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: false });

      setsubmitting(false);
      dispatch({
        type: "Lison_Input_Submit_Status_changed",
        MlLisonsInputsSubmitStatus: false,
      });
    }
    //
    // dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: true });
    // // Simulate async operation

    // await toPng(dicom_data.OpRef.current)
    //   .then((dataUrl) => {

    //     const link = document.createElement("a");
    //     link.download = `${dicom_data?.basicMetaData?.study_code}_${dicom_data?.basicMetaData?.subject_code}_${dicom_data?.basicMetaData?.visit_label}.png`;
    //     link.href = dataUrl;
    //     link.click();
    //   })
    //   .catch((error) => {
    //
    //   });

    // dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: false });
  };

  const handleDownload = (data, fileName, fileType) => {
    let fileContent;
    let fileMimeType;

    if (fileType === "txt") {
      fileContent = JSON.stringify(data, null, 2);
      fileMimeType = "text/plain";
      fileName += ".txt";
    } else if (fileType === "json") {
      fileContent = JSON.stringify(data, null, 2);
      fileMimeType = "application/json";
      fileName += ".json";
    } else {
      return;
    }

    const blob = new Blob([fileContent], { type: fileMimeType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      {dicom_data.LoadingOverlay ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Adjust the z-index as needed
          }}
        >
          {dicom_data?.displayScreenShotUrl && dicom_data?.screenShotDisplay ? (
            <>
              <img
                src={dicom_data?.displayScreenShotUrl}
                alt="Your Image"
                height="85%"
                width={"40%"}
              />
              <button
                onClick={() => {
                  dispatch({
                    type: "LoadingOverlay_changed",
                    LoadingOverlay: false,
                  });
                  dispatch({
                    type: "screenShot_Display_changed",
                    screenShotDisplay: false,
                  });
                }}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  borderRadius: "50%",
                  backgroundColor: "black",
                  color: "white",
                  width: "30px",
                  height: "30px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                X
              </button>
            </>
          ) : (
            <CircularProgress color="primary" />
          )}
        </div>
      ) : null}

      <div style={{ display: "flex", color: "#9C27B0" }}>
        <ToggleButtonGroup
          disabled={imageReceieved}
          style={{ height: "100%" }}
          value={dcmData.selectedTool}
          color="secondary"
          exclusive
          onChange={changeTool}
          aria-label="text alignment"
        >
          <ToggleButton value="Probe" aria-label="Probe Tool">
            <Tooltip title="Probe Tool">
              <LensIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="EllipticalRoi" aria-label="Ellipse Tool">
            <Tooltip title="Ellipse Tool">
              <PanoramaFishEyeIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="CircleRoi" aria-label="Circle Tool">
            <Tooltip title="Circle Tool">
              <PanoramaFishEyeIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="Length" aria-label="Length Tool">
            <Tooltip title="Length Tool">
              <CallMade />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="Angle" aria-label="Angle Tool">
            <Tooltip title="Angle Tool">
              <SubdirectoryArrowRightIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="RectangleRoi" aria-label="Rectangle Tool">
            <Tooltip title="Rectangle Tool">
              <CropSquareIcon />
            </Tooltip>
          </ToggleButton>

          <ToggleButton value="Brush" aria-label="Brush Tool">
            <Tooltip title="Brush Tool">
              <BrushIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="DragRL" aria-label="Drag Left Right">
            <Tooltip title="Drag Left Right">
              <AlignHorizontalLeftIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="ThicknessMap" aria-label="ThicknessMap">
            <Tooltip title="ThicknessMap">
              <AddRoadIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="MOUSE" aria-label="Mouse">
            <Tooltip title="Mouse">
              <MouseIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>

        {!imageReceieved ? <BrushToolSettings /> : null}
        {checked ? (
          <ToggleButtonGroup
            disabled={imageDownloadPressed} // This will disable all ToggleButtons inside the group
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingInline: "10px",
            }}
          >
            <Tooltip title="IR screenshot Download">
              <ToggleButton value="screenshot" onClick={downloadScreenShot}>
                <ScreenshotMonitorIcon
                  sx={{ color: imageDownloadPressed ? "gray" : "#7B1FA2" }}
                />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        ) : null}

        <Switch
          disabled={imageReceieved}
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        {checked ? (
          <ToggleButtonGroup
            disabled={imageReceieved}
            style={{ height: "100%" }}
            value={dcmData.selectedTool}
            color="secondary"
            exclusive
            onChange={changeTool}
            aria-label="text alignment"
          >
            <ToggleButton
              value="Lison"
              aria-label="Lison Tool"
              disabled={!checked}
            >
              <Tooltip title="Lesion Tool">
                <SettingsEthernetIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        ) : null}

        {checked ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Button
              disabled={imageReceieved}
              variant="contained"
              style={{ marginLeft: 10 }}
              // disabled={dicom_data?.MlLisonsInputsSubmitStatus}
              color={
                dicom_data?.MlLisonsInputsSubmitStatus ? "error" : "secondary"
              }
              size="medium"
              onClick={() => onSubmitClick()}
            >
              {!submiting && !dicom_data.SubmitLisonsStatus
                ? "Process"
                : "Processing"}
            </Button>

            {/* <p style={{ color: "red" }}>Please submit again for latest lisons.</p> */}
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // Optional, to center vertically as well
          }}
        >
          {/* {dicom_data?.lisonOptions?.lisonAreaOptions.length > 1 && checked ? (
            <>
              <FormControl style={{ marginLeft: 25, display: "flex" }}>
                <InputLabel id="lisonAreaselected">Area</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled={true}
                  value={dicom_data.lisonOptions.lisonAreaselected}
                  label="Age"
                  onChange={onChangeLisonArea}
                  name="lisonAreaselected"
                  sx={{
                    height: 30, // Adjust this value to your desired height
                    width: 165, // Adjust this value to your desired width
                  }}
                >
                  {dicom_data.lisonOptions.lisonAreaOptions.map(
                    (lisonAreaoption) => {
                      return (
                        <MenuItem value={lisonAreaoption}>
                          {lisonAreaoption}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
              <FormControl style={{ marginLeft: 25 }}>
                <InputLabel id="lisonDisplaySelected">Display</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dicom_data.lisonOptions.lisonDisplaySelected}
                  label="Display"
                  name="lisonDisplaySelected"
                  onChange={onChangeLisonArea}
                  sx={{
                    height: 30, // Adjust this value to your desired height
                    width: 135, // Adjust this value to your desired width
                  }}
                >
                  {dicom_data.lisonOptions.lisonDisplayOptions.map(
                    (lisonDisplayoption) => {
                      return (
                        <MenuItem value={lisonDisplayoption}>
                          {lisonDisplayoption}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </>
          ) : null} */}
        </div>
        {checked ? (
          <>
            {query.get("role") === "dev" || query.get("role") === "DEV" ? (
              <button
                disabled={
                  !dicom_data?.mlLesionPlayloadData ||
                  !dicom_data?.mlLesionResponseData
                }
                onClick={() => {
                  handleDownload(
                    dicom_data?.mlLesionPlayloadData,
                    "InputData",
                    "json"
                  );
                  handleDownload(
                    dicom_data?.mlLesionResponseData,
                    "OutputData",
                    "json"
                  );
                }}
              >
                Lesion Data
              </button>
            ) : null}
          </>
        ) : null}

        {/* <form onSubmit={handleSubmit}>
          <input type="file" onChange={handleFileChange} />
          <button type="submit">Upload</button>
        </form> */}
      </div>
    </>
  );
}
