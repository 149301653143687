import React, { useState, useEffect } from 'react';
import { Card, Typography, Button } from '@mui/material';
import axios from 'axios';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Import help icon
import HelpIcon from '@mui/icons-material/Help';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import MouseIcon from '@mui/icons-material/Mouse';
import BrushIcon from '@mui/icons-material/Brush';
import MovingIcon from '@mui/icons-material/Moving';

export default function HelpDialog(props) {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const shortCuts = [
        {
            name: "General",
            icon: <MouseIcon color="primary" />,
            items: [
                {
                    name: "Change Slice",
                    value: "Mouse Wheel"
                },
                {
                    name: "Zoom In",
                    value: "Shift + Mouse Wheel Up"
                },
                {
                    name: "Zoom Out",
                    value: "Shift + Mouse Wheel Down"
                },
                {
                    name: "Pan",
                    value: "Shift + Mouse Drag"
                }
            ]
        },
        {
            name: "Brush",
            icon: <BrushIcon color="primary" />,
            items: [
                {
                    name: "Erase",
                    value: "Ctrl + Mouse Drag"
                },
                {
                    name: "Brush Size",
                    value: "Z + Mouse Wheel Up/Down"
                }
            ]
        },
        
        {
            name: "Line",
            icon: <MovingIcon color="primary" />,
            items: [
                {
                    name: "End Line Creation",
                    value: "Double Click"
                },
                {
                    name: "Add Point",
                    value: "Alt + Mouse Click on Line"
                },
                {
                    name: "Delete Point",
                    value: "Alt + Mouse Click on Point"
                }
            ]
        }
    ]

    return (
        <>
            <span style={{ padding: 4 }}>
                <Button
                    size='small'
                    style={{ paddingLeft: 1, paddingRight: 1, width: 30 }}
                    variant='contained'
                    onClick={() => setOpen(true)}>
                    <HelpIcon size={2} />
                </Button></span>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Keyboard and Mouse</DialogTitle>
                <DialogContent style={{ width: 500 }}>
                    <MenuList>
                        {shortCuts.map((shortCutType, index) => {

                            return (
                                <span key={`st-${index}`}>
                                    <MenuItem style={{ backgroundColor: "#eee" }}>
                                        <ListItemIcon>
                                            {shortCutType.icon}
                                        </ListItemIcon>
                                        <ListItemText style={{ fontWeight: "bold", color: "#06f" }}>{shortCutType.name}</ListItemText>
                                    </MenuItem>

                                    {shortCutType.items.map((shortCut, indexq) => {
                                        return (
                                            <MenuItem key={`sc-${index}-${indexq}`} style={{ paddingLeft: 40 }}>
                                                <ListItemText>{shortCut.name}
                                                </ListItemText>
                                                <Typography variant="body2" color="text.secondary">
                                                    {shortCut.value}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })}

</span>
                            )
                        })}
                    </MenuList>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

