import React, { useState, useRef, useEffect } from "react";

import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router";

import {
  drawRectangleStatic,
  addStaticLineWithText,
  panImageEvent,
  ZoomMouseWheel,
  addAnnotations,
} from "./utils";
import {
  get_slope_from_line,
  intercept_from_line,
  is_inside_polygon,
} from "../utils/basic_utils";
import { drawBrushPixels } from "../tools/util/drawBrush";

import {
  createContours,
  simplifyContour,
  get_thickness_map,
} from "./processing/thickness";
import {
  extractPointsCoordinates,
  get_annotations,
  opMetadataFetch,
  opPoints,
  save_annotations,
  veriticalMaxDistance,
} from "../actions/dcm_actions";
import MultiPointProbeTool from "../tools/annotation/MultiPointProbeTool";
import MultiLineTool from "../tools/annotation/MultiLineTool";
import { responseLison } from "./testMl";
import LisonTool from "../tools/annotation/LisonTool";

const getModule = cornerstoneTools.getModule;
const { getters, setters } = getModule("segmentation");

function get_line_pixels(A, B) {
  var m = slope(A, B);
  var b = intercept(A, m);

  var coordinates = [];
  for (var x = A[0]; x <= B[0]; x++) {
    var y = m * x + b;
    coordinates.push([parseInt(x), parseInt(y)]);
  }

  return coordinates;
}
function get_circle_pixels_m(centerX, centerY, radius, start, end) {
  var points = [];
  for (var degree = start; degree < end; degree++) {
    var radians = (degree * Math.PI) / 180;
    var x = centerX + radius * Math.cos(radians);
    var y = centerY + radius * Math.sin(radians);

    points.push([parseInt(x), parseInt(y)]);
  }

  return points;
}

function slope(a, b) {
  if (a[0] == b[0]) {
    return null;
  }

  return (b[1] - a[1]) / (b[0] - a[0]);
}

function intercept(point, slope) {
  if (slope === null) {
    // vertical line
    return point[0];
  }

  return point[1] - slope * point[0];
}

function roundNumbers(number) {
  // Check if the input is a string and convert it to a number
  if (typeof number === "string") {
    number = parseFloat(number);
  }

  // Check if number is a finite number and has more than 3 digits after the decimal point
  if (Number.isFinite(number) && Math.abs(number) >= 0.001) {
    return parseFloat(number.toFixed(3));
  } else {
    return number;
  }
}
export default function OPViewer(props) {
  const dispatch = useDispatch();
  const dicom_data = useSelector((state) => state.dcm);
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const opData = dicom_data.dicom_data[props.id];

  const dcmRef = useRef(null);
  const [currentNumber, setCurrentNumber] = useState(0);
  const [elmentEnabled, setElementEnabled] = useState(false);

  const onSaveClick = async () => {
    await save_annotations(
      dicom_data,
      query.get("stack_id"),
      query.get("user_id"),
      query.get("series_no"),
      query.get("role"),
      query.get("form_version_id") ? "additional_form" : "primary_form",
      query.get("form_version_id") ? query.get("form_version_id") : ""
    );
    dispatch({
      type: "Lison_Submit_Status_changed",
      SubmitLisonsStatus: false,
    });
    dispatch({ type: "LoadingOverlay_changed", LoadingOverlay: false });
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Define the function you want to call after 500ms of last call
  const handleChange = debounce(() => {
    // Do whatever you want with the input value after 500ms
    onSaveClick();
  }, 500);

  const getToolsState = () => {
    // Get tool state for all enabled tools
    const state = {};
    dicom_data.drawingTools.forEach((tool) => {
      const msms = cornerstoneTools.getToolState(dcmRef.current, tool);

      if (tool == "Length" && msms) {
        const pixel_spacing = opData.OP.metadata.pixel_spacing;
        msms.pixelSpacing = pixel_spacing;
        msms.data = msms.data.map((obj) => ({
          ...obj,
          pixelSpacing: {
            rowPixelSpacing: pixel_spacing.rowPixelSpacing,
            colPixelSpacing: pixel_spacing.columnPixelSpacing,
          },
        }));
      }
      state[tool] = msms;
    });

    dispatch({
      type: "SET_ANNOTATION_DATA",
      payload: state,
      id: props.id,
      key: "OP",
    });
    if (dicom_data.SubmitLisonsStatus) {
      handleChange();
    }
  };

  useEffect(() => {
    if (props?.annotations?.lisonsAreasData) {
      dispatch({
        type: "Set_Lisons_Area_Data",
        lisonsAreasData: props?.annotations?.lisonsAreasData,
      });
      const areaTypes = props?.annotations?.lisonsAreasData?.area_versions?.map(
        (obj) => obj.area_type
      );

      let options = {
        ...dicom_data.lisonOptions,
      };

      options["lisonAreaOptions"] = areaTypes;
      dispatch({
        type: "Lison_Option_changed",
        lisonOptions: options,
      });
    }
  }, [props.annotations]);

  useEffect(() => {
    if (elmentEnabled) cornerstone.resize(dcmRef.current);
  }, [props.windowWidth, dicom_data.width]);

  function compareMlLisonsInput(data1, data2) {
    if (data1.length !== data2.length) {
      return false; // Different number of elements in the arrays
    }

    for (let i = 0; i < data1.length; i++) {
      const scan1 = data1[i];
      const scan2 = data2[i];

      if (scan1.b_scan_id !== scan2.b_scan_id) {
        return false; // Different b_scan_id
      }

      if (scan1.lines.length !== scan2.lines.length) {
        return false; // Different number of lines in the scans
      }

      for (let j = 0; j < scan1.lines.length; j++) {
        const line1 = scan1.lines[j];
        const line2 = scan2.lines[j];

        if (
          line1.line_id !== line2.line_id ||
          line1.start_x_coor !== line2.start_x_coor ||
          line1.end_x_coor !== line2.end_x_coor
        ) {
          return false; // Different line data
        }
      }
    }

    return true;
  }
  function checkInputData() {
    if (dicom_data.dicom_data) {
      // var annotations = get_annotations(
      //   dicom_data.dicom_data,
      //   dicom_data.drawingTools
      // );
      // //
      // var stack_ids = Object.keys(annotations);
      // let lisonLines = extractPointsCoordinates(annotations[stack_ids[0]].OPT);
      // dispatch({
      //   type: "Lison_Input_Submit_Status_changed",
      //   MlLisonsInputsSubmitStatus: compareMlLisonsInput(
      //     lisonLines,
      //     dicom_data?.MlLisonsInputData
      //   ),
      // });
    }
  }
  useEffect(() => {
    let timeoutId;

    const delayedCheckInputData = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        if (!elmentEnabled) {
          return;
        } else {
          if (
            dicom_data?.MlLisonsInputData.length !== 0 &&
            dicom_data.LisonActive
          ) {
            checkInputData();
          }
        }
      }, 300);
    };

    delayedCheckInputData();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [dicom_data.dicom_data]);

  const onMeasurementAdded = (e) => {
    // Get tool state for all enabled tools on measurementAdded
    getToolsState();
  };

  const onMeasurementModified = (e) => {
    // Measurement modified
    getToolsState();
  };
  const onWindowResize = () => {
    cornerstone.updateImage(dcmRef.current);
  };

  function findClosest(points, target) {
    let closestDiff = Infinity;
    let closestList = null;

    for (let i = 0; i < points.length; i++) {
      let diff = Math.abs(points[i][1] - target);
      if (diff < closestDiff) {
        closestDiff = diff;
        closestList = i;
      }
    }

    return closestList;
  }

  const onMouseClick = (e) => {
    setCurrentNumber(currentNumber + 1);
    if (
      dicom_data.selectedTool === "MOUSE" ||
      dicom_data.selectedTool === "ThicknessMap"
    ) {
      const resp = findClosest(
        opData.OPT.metadata.frames,
        e.detail.currentPoints.image.y
      );

      if (resp) {
        dispatch({
          type: "DICOM_CHANGE_FRAME",
          changeId: props.id,
          changeType: "OP",
          frame: resp,
        });
      }
    }
  };

  const addRectangleStatic = () => {
    const RectangleStatic_v = cornerstoneTools.RectangleStatic;
    cornerstoneTools.addTool(RectangleStatic_v);
    cornerstoneTools.setToolActive("RectangleStatic", { mouseButtonMask: 1 });
  };

  const addLineStatic = () => {
    const LineStatic_v = cornerstoneTools.LineStatic;
    cornerstoneTools.addTool(LineStatic_v);
    cornerstoneTools.setToolActive("LineStatic", { mouseButtonMask: 1 });
  };

  const addThicknessMapStatic = () => {
    const ThicknessMap_v = cornerstoneTools.ThicknessMap;
    cornerstoneTools.addTool(ThicknessMap_v);
    cornerstoneTools.setToolActive("ThicknessMap", { mouseButtonMask: 1 });
  };

  // On Frame Change
  useEffect(() => {
    if (elmentEnabled) {
      // if(!dicom_data.syncScans){
      //   if(dicom_data.changeId !== props.id){
      //     return
      //   }
      // }
      if (dicom_data.changeId !== props.id) {
        return;
      }

      cornerstoneTools.clearToolState(dcmRef.current, "LineStatic");
      const verticalDistances = [];
      let pointsArray = opData.OPT.metadata.frames;
      for (let i = 1; i < pointsArray.length; i++) {
        const startYDistance = pointsArray[i][1] - pointsArray[i - 1][1];
        const endYDistance = pointsArray[i][3] - pointsArray[i - 1][3];
        verticalDistances.push([startYDistance, endYDistance]);
      }

      addStaticLineWithText(
        dcmRef,
        opData.OPT.metadata.frames[dicom_data.currentFrame],
        `Frame ${dicom_data.currentFrame + 1}`
      );
      if (opData.OP.vertical_line !== null) {
        addStaticLineWithText(dcmRef, opData.OP.vertical_line, ``);
      }

      cornerstone.updateImage(dcmRef.current);
    }
  }, [dicom_data.currentFrame, opData.OP.vertical_line]);

  const onMouseDrag = (e) => {
    if (dicom_data.selectedKey === "Shift") {
      panImageEvent(e);
      return;
    }

    if (dicom_data.selectedTool !== "DragRL") {
      return;
    }
    // Check if mouse is inside rectangle otherwise ignore
    if (
      !is_inside_polygon(
        [e.detail.currentPoints.image.x, e.detail.currentPoints.image.y],
        opData.OPT.metadata.rect_poly
      )
    ) {
      return;
    }

    // Drag Left to right or right to left
    let xmin = opData.OPT.metadata.rect[0];
    let xmax = opData.OPT.metadata.rect[2];

    let currentFrameData = opData.OPT.metadata.frames[dicom_data.currentFrame];

    let total_width = xmax - xmin;
    let normalizedX = (e.detail.currentPoints.image.x - xmin) / total_width;

    let slope = get_slope_from_line(currentFrameData);
    let intercept = intercept_from_line(currentFrameData);

    let yh = slope * e.detail.currentPoints.image.x + intercept;

    dispatch({
      type: "DICOM_CHANGE_VERTICAL_LINE",
      OP: [
        e.detail.currentPoints.image.x,
        yh - 50,
        e.detail.currentPoints.image.x,
        yh + 50,
      ],
      OPT: [
        normalizedX * opData.OPT.metadata.meta.width,
        0,
        normalizedX * opData.OPT.metadata.meta.width,
        opData.OPT.metadata.meta.height,
      ],
      id: props.id,
    });
  };

  const onKeyboardKeyDown = (e) => {
    if (e.key === "ArrowUp" && dicom_data.currentFrame === 0) {
      dispatch({
        type: "DICOM_CHANGE_FRAME",
        changeId: props.id,
        changeType: "OP",
        frame: dicom_data.currentFrame + 1,
      });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyboardKeyDown);
    // document.addEventListener("keyup", onKeyboardKeyUp);
    // return () => {
    //   document.removeEventListener("keydown", onKeyboardKeyDown);
    //   // document.removeEventListener("keyup", onKeyboardKeyUp);
    // };
  }, []);
  const onMouseWheel = (e) => {
    if (dicom_data.selectedKey === "Shift") {
      // If control is pressed with mouse wheel scroll, we perform zoom in and out
      ZoomMouseWheel(e, cornerstone);
      return;
    }
    // If mouse is on image, we perform custom stack scroll
    if (e.detail.spinY < 0) {
      if (dicom_data.currentFrame > 0) {
        setCurrentNumber(currentNumber - 1);
        dispatch({
          type: "DICOM_CHANGE_FRAME",
          changeId: props.id,
          changeType: "OP",
          frame: dicom_data.currentFrame - 1,
        });
      }
    } else {
      if (dicom_data.currentFrame < opData.OPT.metadata.frames.length - 1) {
        setCurrentNumber(currentNumber + 1);
        dispatch({
          type: "DICOM_CHANGE_FRAME",
          changeId: props.id,
          changeType: "OP",
          frame: dicom_data.currentFrame + 1,
        });
      }
    }
  };
  const pushPoints = (currentRef, pointx, pointy) => {
    // pointx = 35;
    // pointy = 135;
    cornerstoneTools.addToolState(currentRef, "LisonProbe", {
      active: false,
      color: "yellow",
      cachedStats: {
        mo: 125,
        sp: 125,
        storedPixels: [125],
        x: pointx,
        y: pointy,
      },
      handles: {
        end: {
          active: false,
          highlight: true,
          moving: false,
          x: pointx,
          y: pointy,
        },
      },
      invalidated: false,
      uuid: "1d17782a-f5c0-40b8-afa4-51830f01bf6f",
      visible: true,
    });
  };
  const AddLisonAreas = (dcmRef, objectType, displayType) => {
    let totalArea, lisonsAreas, midx, midy;

    if (dicom_data?.lisonsAreasData.area_versions) {
      let area_versions = dicom_data?.lisonsAreasData?.area_versions;
      const lisonsColors = [
        "rgba(255, 0, 0, 0.09)",
        "rgba(0, 128, 0, 0.09)",
        "rgba(255, 255, 0, 0.09)",
        "rgba(0, 0, 255, 0.09)",
        "rgba(128, 0, 128, 0.09)",
      ];

      const pixelatedObject = area_versions.find(
        (obj) => obj.area_type === objectType
      );
      if (displayType === "Edges") {
        lisonsAreas = "";
        cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");
        cornerstoneTools.clearToolState(dcmRef.current, "MultiLine");
        cornerstoneTools.addTool(MultiPointProbeTool);
        cornerstoneTools.setToolActive("MultiPointProbe", {
          mouseButtonMask: 1,
        });
        // cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");

        totalArea = roundNumbers(pixelatedObject.total_area_size_mm2);
        for (let i = 0; i < pixelatedObject.lesions.length; i++) {
          let concatenatedArray = [];

          // Add the tool to the enabledElementBinding
          lisonsAreas += `L${i + 1}:${roundNumbers(
            pixelatedObject.lesions[i].area_size_mm2
          )}; `;

          concatenatedArray = pixelatedObject.lesions[i].vertical_lines.concat(
            pixelatedObject.lesions[i].horizontal_lines
          );

          //
          const transformedArray = concatenatedArray.flatMap((line) =>
            line.map(([x, y]) => ({
              x,
              y,
              highlight: true,
              active: true,
              moving: false,
            }))
          );

          let x1 = pixelatedObject.lesions[i].horizontal_lines[0][0][0];
          let y1 = pixelatedObject.lesions[i].horizontal_lines[0][0][1];

          let x2 = pixelatedObject.lesions[i].horizontal_lines[0][1][0];
          let y2 = pixelatedObject.lesions[i].horizontal_lines[0][1][1];

          midx = Math.floor((x1 + x2) / 2);
          midy = Math.floor((y1 + y2) / 2);

          // Add the tool state to the enabledElementBinding
          cornerstoneTools.addToolState(
            dcmRef.current,
            "MultiPointProbe",
            {
              data: [
                {
                  visible: true,
                  active: true,
                  label: `L${i + 1}`,
                  label_coordinates: {
                    x: midx,
                    y: midy - 32,
                  },
                  area_coordinates: {
                    x: 10,
                    y:
                      opData.OP.metadata.height -
                      (pixelatedObject.lesions.length - i) * 64,
                  },
                  total_area: roundNumbers(pixelatedObject.total_area_size_mm2),
                  color: lisonsColors[i % lisonsColors.length],
                  invalidated: true,
                  area: roundNumbers(pixelatedObject.lesions[i].area_size_mm2),
                  handles: {
                    points: transformedArray,
                  },
                },
              ],
            },
            "enabledElementBinding"
          );
          cornerstone.updateImage(dcmRef.current);
          // cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");
        }
        cornerstoneTools.setToolPassive("MultiPointProbe");
      }

      if (displayType === "filled") {
        lisonsAreas = "";
        cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");
        cornerstoneTools.clearToolState(dcmRef.current, "MultiLine");
        cornerstoneTools.addTool(MultiLineTool);
        cornerstoneTools.setToolActive("MultiLine", {
          mouseButtonMask: 1,
        });
        // cornerstoneTools.clearToolState(dcmRef.current, "MultiLine");
        // area_versions = responseLison.area_versions;

        totalArea = roundNumbers(pixelatedObject.total_area_size_mm2);
        for (let i = 0; i < pixelatedObject.lesions.length; i++) {
          let verticalLinesDraw = pixelatedObject.lesions[i].horizontal_lines;

          lisonsAreas += `L${i + 1}:${roundNumbers(
            pixelatedObject.lesions[i].area_size_mm2
          )}; `;

          let x1 = pixelatedObject.lesions[i].horizontal_lines[0][0][0];
          let y1 = pixelatedObject.lesions[i].horizontal_lines[0][0][1];

          let x2 = pixelatedObject.lesions[i].horizontal_lines[0][1][0];
          let y2 = pixelatedObject.lesions[i].horizontal_lines[0][1][1];

          let midxline = Math.floor((x1 + x2) / 2);
          let midyline = Math.floor((y1 + y2) / 2);

          // Add the tool state to the enabledElementBinding
          cornerstoneTools.addToolState(
            dcmRef.current,
            "MultiLine",
            {
              data: [
                {
                  visible: true,
                  active: true,
                  label: `L${i + 1}`,
                  label_coordinates: {
                    x: midx ?? midxline,
                    y: midy ?? midyline - 32,
                  },
                  area_coordinates: {
                    x: 10,
                    y:
                      opData.OP.metadata.height -
                      (pixelatedObject.lesions.length - i) * 64,
                  },
                  total_area: roundNumbers(pixelatedObject.total_area_size_mm2),
                  color: lisonsColors[i % lisonsColors.length],
                  area: roundNumbers(
                    pixelatedObject?.lesions[i]?.area_size_mm2
                  ),
                  invalidated: true,
                  handles: {
                    points: verticalLinesDraw,
                  },
                },
              ],
            },
            "enabledElementBinding"
          );
          cornerstone.updateImage(dcmRef.current);
          // cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");
        }
        cornerstoneTools.setToolPassive("MultiLine");
      }
    }

    if (dicom_data.selectedTool === "Lison") {
      cornerstoneTools.addTool(LisonTool);
      cornerstoneTools.setToolActive("Lison", { mouseButtonMask: 1 });
    }
    dispatch({
      type: "selected_area_Changed",
      SelectedArea: {
        TotalArea: totalArea,
        LisonsArea: lisonsAreas,
      },
    });
    getToolsState();
  };
  useEffect(() => {
    if (!elmentEnabled) {
      return;
    }

    if (!dicom_data.LisonActive && dicom_data?.lisonsAreasData.area_versions) {
      cornerstoneTools.clearToolState(dcmRef.current, "MultiLine");
      cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");
    } else {
      AddLisonAreas(
        dcmRef,
        dicom_data.lisonOptions.lisonAreaselected,
        dicom_data.lisonOptions.lisonDisplaySelected
      );
    }
  }, [dicom_data.lisonOptions, dicom_data.LisonActive]);
  useEffect(() => {
    if (elmentEnabled) {
      let update_d = opData.OP.annotation_toggle;

      if (update_d === undefined) {
        return;
      }
      const toolState = cornerstoneTools.getToolState(
        dcmRef.current,
        update_d.annot_type
      );

      if (toolState === null) {
        return;
      }

      if (update_d.action_type === "delete_LisonProbes") {
        const toolState = cornerstoneTools.getToolState(
          dcmRef.current,
          "LisonProbe"
        );

        if (toolState?.data) {
          toolState.data = [];
          // cornerstoneTools.clearToolState(dcmRef.current, "LisonProbe")
        }
        // toolState.data.splice(update_d.index, 1);
        getToolsState();
      }

      if (update_d.action_type === "Add_Lison_Length_point") {
        let opWidth =
          dicom_data?.dicom_data[query.get("stack_id")]?.OP.metadata?.width;

        // let scalingFactor =

        const toolState = cornerstoneTools.getToolState(
          dcmRef.current,
          "LisonProbe"
        );

        if (toolState?.data) {
          toolState.data = [];
          // cornerstoneTools.clearToolState(dcmRef.current, "LisonProbe")
        }
        // let previous_probs = [...toolState.data];
        const LisonProbeTool = cornerstoneTools.LisonProbeTool;
        //
        cornerstoneTools.addTool(LisonProbeTool);
        // cornerstoneTools.setToolActive("LisonProbe", { mouseButtonMask: 1 });

        // cornerstoneTools.clearToolState(dcmRef.current, "LisonProbe");

        ///////
        // toolState.data = [];
        var annotations = get_annotations(
          dicom_data.dicom_data,
          dicom_data.drawingTools
        );
        //
        var stack_ids = Object.keys(annotations);
        let lisonLines = extractPointsCoordinates(
          annotations[stack_ids[0]].OPT
        );

        for (let i = 0; i < lisonLines.length; i++) {
          for (let j = 0; j < lisonLines[i].lines.length; j++) {
            let frame_coords =
              opData.OPT.metadata.frames[lisonLines[i].b_scan_id];

            let optWidth =
              dicom_data?.dicom_data[query.get("stack_id")]?.OPT?.metadata?.meta
                ?.width;

            let Framecoords =
              opData?.OPT?.metadata?.frames[lisonLines[i]?.b_scan_id];

            const dx = Framecoords[2] - Framecoords[0];
            const dy = Framecoords[3] - Framecoords[1];
            let redLineLength = Math.sqrt(dx * dx + dy * dy);

            let scalingFactor = redLineLength / optWidth;

            //slope= y2-y1/x2-x1
            // calculate the coordinates of the point on the oblique line placed in cartesian grid by knowing the distance from starting poing

            // // step : 1 calculating slope of line
            // const slope =
            //   (frame_coords[3] - frame_coords[1]) /
            //   (frame_coords[2] - frame_coords[0]);

            // // step : 2 Direction vector (x2-x1,y2-y1)
            // let directionVector = [
            //   frame_coords[2] - frame_coords[0],
            //   frame_coords[3] - frame_coords[1],
            // ];

            // step :3 Length of direction vector sqrt((x2-x1)^2,(y2-y1)^2) which is same as redLineLength we already calculated above

            // step : 4 Unit vector (x2-x1/length,y2-y1/length)
            let unitVector = [
              (frame_coords[2] - frame_coords[0]) / redLineLength,
              (frame_coords[3] - frame_coords[1]) / redLineLength,
            ];

            let newStartPoint = [
              frame_coords[0] +
                lisonLines[i].lines[j].start_x_coor *
                  scalingFactor *
                  unitVector[0],

              frame_coords[1] +
                lisonLines[i].lines[j].start_x_coor *
                  scalingFactor *
                  unitVector[1],
            ];

            pushPoints(
              dcmRef.current,
              parseInt(newStartPoint[0]),
              parseInt(newStartPoint[1])
            );

            let newEndPoint = [
              frame_coords[0] +
                lisonLines[i].lines[j].end_x_coor *
                  scalingFactor *
                  unitVector[0],

              frame_coords[1] +
                lisonLines[i].lines[j].end_x_coor *
                  scalingFactor *
                  unitVector[1],
            ];
            pushPoints(
              dcmRef.current,
              parseInt(newEndPoint[0]),
              parseInt(newEndPoint[1])
            );
          }
        }
        ////////
        // for (let i = 0; i < previous_probs; i++) {
        //
        //   toolState.data.push(previous_probs[i]);
        // }
      }
      if (update_d.action_type === "delete") {
        const updatedData = {
          ...dicom_data?.lisonsAreasData,
          area_versions: dicom_data?.lisonsAreasData.area_versions.map(
            (area) => ({
              ...area,
              lesions: area.lesions.filter(
                (_, index) => index !== update_d.index
              ),
            })
          ),
        };
        dispatch({
          type: "Set_Lisons_Area_Data",
          lisonsAreasData: updatedData,
        });

        toolState.data.splice(update_d.index, 1);
        getToolsState();
      }
      cornerstone.updateImage(dcmRef.current);
    }
  }, [opData.OP.annotation_toggle]);

  const add_thickness_map_grid = (image) => {
    let element = dcmRef.current;
    var rect = opData.OPT.metadata.rect;

    var scale_x = opData.OP.metadata.pixel_spacing.columnPixelSpacing;
    var circles_radii = [1 / scale_x / 2, 3 / scale_x / 2, 6 / scale_x / 2];

    cornerstoneTools.clearToolState(element, "ThicknessMap");
    cornerstoneTools.addToolState(element, "ThicknessMap", {
      rect: rect,
      circles_radii: circles_radii,
      thickness_image: image,
      handles: {
        start: {
          x: (rect[2] - rect[0]) / 2 + rect[0],
          y: (rect[3] - rect[1]) / 2 + rect[1],
        },
        end: {
          x: rect[2],
          y: rect[3],
        },
      },
    });

    cornerstoneTools.setToolPassive("ThicknessMap", { mouseButtonMask: 1 });
  };

  const metaDataProvider = (type, imageId) => {
    // Custom meta data provider for the viewport(OCT)
    if (
      type === "imagePlaneModule" &&
      dicom_data.selectedTool === "ThicknessMap"
    ) {
      if (imageId.includes(opData.OP.metadata.url)) {
        return {
          rowPixelSpacing: opData.OP.metadata.pixel_spacing.rowPixelSpacing,
          columnPixelSpacing:
            opData.OP.metadata.pixel_spacing.columnPixelSpacing,
        };
      }
    } else if (type === "imagePlaneModule") {
      if (imageId.includes(opData.OP.metadata.url)) {
        return {
          rowPixelSpacing: opData.OP.metadata.pixel_spacing.rowPixelSpacing,
          columnPixelSpacing:
            opData.OP.metadata.pixel_spacing.columnPixelSpacing,
        };
      }
    }
  };

  useEffect(() => {
    cornerstone.metaData.addProvider(metaDataProvider);
  }, [Object.keys(dicom_data.dicom_data).length, dicom_data.selectedTool]);

  useEffect(() => {
    if (!elmentEnabled) {
      return;
    }
    if (dicom_data.selectedTool === "ThicknessMap") {
      // On U key press, we calculate thickness map for 2 selected layers and overlay on Fundus image

      var layers = cornerstone.getLayers(dcmRef.current);
      if (layers.length > 1) {
        cornerstone.removeLayer(dcmRef.current, layers[1].layerId);
      }
      cornerstone.updateImage(dcmRef.current);

      var first_point = opData.OPT.metadata.frames[0];
      var last_point =
        opData.OPT.metadata.frames[opData.OPT.metadata.frames.length - 1];

      // Calculate width and height of rectangle
      var width = Math.abs(first_point[0] - last_point[2]);
      var height = Math.abs(first_point[1] - last_point[1]);

      var imgs = get_thickness_map(dcmRef.current, opData.OPT.stack.imageIds, {
        width: opData.OPT.metadata.meta.width,
        height: opData.OPT.metadata.meta.width,
        rowPixelSpacing: opData.OPT.metadata.pixel_spacing.rowPixelSpacing,
        columnPixelSpacing:
          opData.OPT.metadata.pixel_spacing.columnPixelSpacing,
        lut: dicom_data.thicknessLUT,
        angle: opData.OPT.metadata.rect_angle,
        rect: opData.OPT.metadata.rect,
        rect_wh: [width, height],
        op_meta: opData.OP.metadata,
        frames: [
          opData.OPT.metadata.frames[0],
          opData.OPT.metadata.frames[opData.OPT.metadata.frames.length - 1],
        ],
        total_frames: opData.OPT.metadata.frames.length,
      });

      if (imgs === null) {
        return;
      }
      var img = imgs.cnsImage;
      img.rowPixelSpacing = opData.OP.metadata.pixel_spacing.rowPixelSpacing;
      img.columnPixelSpacing =
        opData.OP.metadata.pixel_spacing.columnPixelSpacing;

      add_thickness_map_grid(imgs.thickness_image_array);

      cornerstone.addLayer(dcmRef.current, img, {
        name: "Thickness",
        opacity: 0.7,
        visible: true,
      });
      cornerstone.updateImage(dcmRef.current);
    } else {
      // Remove thickness map layer if any
      var layers = cornerstone.getLayers(dcmRef.current);
      if (layers.length > 1) {
        cornerstoneTools.clearToolState(dcmRef.current, "ThicknessMap");
        cornerstone.removeLayer(dcmRef.current, layers[1].layerId);
        cornerstone.updateImage(dcmRef.current);
      }
    }
  }, [dicom_data.selectedTool]);

  const layers = [
    {
      imageId: opData.OP.stack.imageIds[0],
      options: {
        name: "OP",
        opacity: 0.7,
      },
    },
  ];

  const loadImages = () => {
    const promises = [];

    layers.forEach(function (layer) {
      const loadPromise = cornerstone.loadAndCacheImage(layer.imageId);
      promises.push(loadPromise);
    });

    return Promise.all(promises);
  };

  const addFilteredAnnotations = () => {
    const keysToRemove = ["LisonProbe"];
    let filteredObj = { ...props.annotations };

    console.log("filtered objectss", filteredObj);
    if (Object.keys(filteredObj).length > 0) {
      if (!dicom_data.LisonActive) {
        filteredObj = Object.keys(props.annotations)
          .filter((key) => !keysToRemove.includes(key))
          .reduce((acc, key) => {
            acc[key] = props.annotations[key];
            return acc;
          }, {});
      }
      if (filteredObj.lisonsAreasData) delete filteredObj.lisonsAreasData;
      addAnnotations(filteredObj, dcmRef);
    }
  };
  useEffect(() => {
    if (!elmentEnabled) {
      return;
    }
    addFilteredAnnotations();
  }, [dicom_data.LisonActive]);
  useEffect(() => {
    let element = dcmRef.current;
    //cornerstone.loadImage(opData.OP.stack.imageIds[0]).then(function (image) {
    //  cornerstone.enable(element);
    //    cornerstone.displayImage(element, image)
    loadImages()
      .then(function (images) {
        cornerstone.enable(element);
        images.forEach(function (image, index) {
          const layer = layers[index];
          cornerstone.addLayer(element, image, layer.options);

          cornerstone.updateImage(element);
        });
      })
      .then(function () {
        //cornerstoneTools.addStackStateManager(element, ['stack']);
        //cornerstoneTools.addToolState(element, 'stack', opData.OP.stack);

        addRectangleStatic();
        addLineStatic();
        addThicknessMapStatic();

        element.addEventListener("cornerstoneelementresized", onWindowResize);

        drawRectangleStatic(
          dcmRef,
          opData.OPT.metadata.rect,
          -opData.OPT.metadata.rect_angle
        );
        cornerstone.drawImage(dcmRef.current);

        // Get viewport
        addFilteredAnnotations();

        // add_thickness_map_grid(null)

        setElementEnabled(true);
      });
  }, [Object.keys(dicom_data.dicom_data).length]);

  const onMouseDown = (e) => {
    // Record initial position of static rectangle is shift is pressed
    // cornerstoneTools.clearToolState(dcmRef.current, "Lison");
    cornerstoneTools.setToolPassive("Lison");
  };

  function onMouseOver(evt) {
    // Your event handling code here
  }

  useEffect(() => {
    let element = dcmRef.current;
    element.addEventListener("cornerstonetoolsmousedown", onMouseDown);
    element.addEventListener("cornerstonetoolsmousewheel", onMouseWheel);
    element.addEventListener("cornerstonetoolsmousedrag", onMouseDrag);
    element.addEventListener("cornerstonetoolsmouseclick", onMouseClick);
    element.addEventListener(
      "cornerstonetoolsmeasurementadded",
      onMeasurementAdded
    );
    element.addEventListener(
      "cornerstonetoolsmeasurementmodified",
      onMeasurementModified
    );

    return () => {
      element.removeEventListener("cornerstonetoolsmousedown", onMouseDown);
      element.removeEventListener("cornerstonetoolsmousewheel", onMouseWheel);
      element.removeEventListener("cornerstonetoolsmousedrag", onMouseDrag);
      element.removeEventListener("cornerstonetoolsmouseclick", onMouseClick);
      element.removeEventListener(
        "cornerstonetoolsmeasurementadded",
        onMeasurementAdded
      );
      element.removeEventListener(
        "cornerstonetoolsmeasurementmodified",
        onMeasurementModified
      );
    };
  }, [
    dicom_data.currentFrame,
    dicom_data.selectedTool,
    dicom_data.selectedKey,
    dicom_data.processing,
  ]);

  useEffect(() => {
    if (dcmRef) {
      dispatch({
        type: "Op_Ref_Changed",
        OpRef: dcmRef,
      });
    }
  }, [dcmRef]);
  return (
    <>
      <div
        style={{
          height: props.height,
          width: props.width,
          backgroundColor: "#000",
        }}
        ref={dcmRef}
      ></div>
    </>
  );
}
