import React from "react";
import { Grid, Card, Typography, Button } from "@mui/material";

export default function ListItem(props) {
  return (
    <Grid item xs={12} md={12} lg={12}>
      <Card style={{ padding: "5px 10px", border: "1px solid #ccc" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            <img
              alt="img-thumbnail"
              src={props.item.url}
              style={{ width: "60px", height: "50px", borderRadius: 5 }}
            />

            <Typography
              gutterBottom
              variant="body"
              style={{ padding: "10px" }}
              component="div"
            >
              {props.item.id}
            </Typography>
          </div>

          <Button href={`/viewer/${props.item.id}`} size="small">
            Start Grading
          </Button>
        </div>
      </Card>
    </Grid>
  );
}
