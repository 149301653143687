import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Annotations from "./AnnotationsList";
import QAForm from "./QAForm";
import AdjQAForm from "./AdjudicatorForm";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AnnotationsData(props) {
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const [value, setValue] = React.useState(
    query.get("role") === "grader" ||
      query.get("role") === "quality_control" ||
      query.get("role") === "adjudicator"
      ? 0
      : 1
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ width: "100%" }}
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        height: "100%",
        paddingBottom: "90px",
      }}
    >
      {["grader", "quality_control", "adjudicator"].includes(
        props.request_info.role
      ) ? (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="fullWidth"
            >
              <Tab
                style={{ fontSize: "12px" }}
                label={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {props.request_info.role === "quality_control"
                        ? "QC Form"
                        : props.request_info.role === "adjudicator"
                        ? "Adjudication Form"
                        : "Grading Form"}
                    </span>
                    {query.get("form_title") ? (
                      <span
                        style={{ fontSize: "10px", color: "gray" }}
                      >{`( ${query.get("form_title")} )`}</span>
                    ) : null}
                  </div>
                }
                {...a11yProps(0)}
              />
              <Tab
                style={{ fontSize: "12px" }}
                label="Annotations"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel
            value={value}
            index={0}
            style={{ paddingLeft: 0, paddingRight: 2, height: "100%" }}
          >
            {props.request_info.role === "grader" ||
            props.request_info.role === "quality_control" ? (
              <QAForm request_info={props.request_info} />
            ) : (
              <AdjQAForm request_info={props.request_info} />
            )}
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            style={{
              paddingLeft: 0,
              paddingRight: 2,
              height: "100%",
              overflow: "auto",
            }}
          >
            <Annotations />
          </TabPanel>
        </>
      ) : (
        <Annotations />
      )}
    </Box>
  );
}
