import { contours as d3Contours } from "d3-contour";
import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import drawJoinedLines from "../../tools/annotation/drawing/drawJoinedLines";
import { lineColors } from "../../configs/layers";
import createImage from "./createImage";

const resizeImageData = require("resize-image-data");

function get_OPT_image_data(imgIds, drawingTools) {
  var frames = [];
  for (var i = 0; i < imgIds.length; i++) {
    // Get frame annotations for all tools
    var toolAnnotations =
      cornerstoneTools.globalImageIdSpecificToolStateManager.getImageIdToolState(
        imgIds[i],
        "Line"
      );
    frames.push(toolAnnotations);
  }
  return frames;
}

function rotatePoint(point, center, angle) {
  const angleRadians = angle * (Math.PI / 180); // Convert to radians

  const rotatedX =
    Math.cos(angleRadians) * (point.x - center.x) -
    Math.sin(angleRadians) * (point.y - center.y) +
    center.x;

  const rotatedY =
    Math.sin(angleRadians) * (point.x - center.x) +
    Math.cos(angleRadians) * (point.y - center.y) +
    center.y;

  return {
    x: rotatedX,
    y: rotatedY,
  };
}

export function getRectPoly(rect, rotation) {
  // Convert to new coordinates after rotation

  var top = rect.top;
  var left = rect.left;
  var width = rect.width;
  var height = rect.height;

  var center = {
    x: left + width / 2,
    y: top + height / 2,
  };
  rotation = -rotation;
  if (rotation < 0) {
    var rotation = (-rotation * Math.PI) / 180;
  } else {
    var rotation = (rotation * Math.PI) / 180;
  }
  // New top left
  var new_top_left = {
    x:
      center.x +
      (left - center.x) * Math.cos(rotation) -
      (top - center.y) * Math.sin(rotation),
    y:
      center.y +
      (left - center.x) * Math.sin(rotation) +
      (top - center.y) * Math.cos(rotation),
  };

  // New top right
  var new_top_right = {
    x:
      center.x +
      (left + width - center.x) * Math.cos(rotation) -
      (top - center.y) * Math.sin(rotation),
    y:
      center.y +
      (left + width - center.x) * Math.sin(rotation) +
      (top - center.y) * Math.cos(rotation),
  };

  // New bottom left
  var new_bottom_left = {
    x:
      center.x +
      (left - center.x) * Math.cos(rotation) -
      (top + height - center.y) * Math.sin(rotation),
    y:
      center.y +
      (left - center.x) * Math.sin(rotation) +
      (top + height - center.y) * Math.cos(rotation),
  };

  // New bottom right
  var new_bottom_right = {
    x:
      center.x +
      (left + width - center.x) * Math.cos(rotation) -
      (top + height - center.y) * Math.sin(rotation),
    y:
      center.y +
      (left + width - center.x) * Math.sin(rotation) +
      (top + height - center.y) * Math.cos(rotation),
  };

  // Rotation Point (Center of top line)
  var new_angle = {
    x: new_top_left.x + (new_top_right.x - new_top_left.x) / 2,
    y: new_top_left.y + (new_top_right.y - new_top_left.y) / 2,
  };

  if (rotation < 0) {
    var poly = [
      [new_top_left.x, new_top_left.y],
      [new_top_right.x, new_top_right.y],
      [new_bottom_right.x, new_bottom_right.y],
      [new_bottom_left.x, new_bottom_left.y],
    ];
  } else {
    var poly = [
      [new_top_left.y, new_top_left.x],
      [new_top_right.y, new_top_right.x],
      [new_bottom_right.y, new_bottom_right.x],
      [new_bottom_left.y, new_bottom_left.x],
    ];
  }
  return {
    poly: poly,
    angle: [new_angle.x, new_angle.y],
  };
}

export function get_thickness_map(element, imageIds, meta) {
  /* 
    Get thickness map for all layers in the image
    */

  const LineData = get_OPT_image_data(imageIds, "Line");
  if (LineData[0] === undefined) {
    return null;
  }
  // Iterate through each layer
  const thicknessMap = [];
  for (let i = 0; i < LineData.length; i++) {
    var linePoints = [];
    for (let j = 0; j < LineData[i]?.data.length; j++) {
      if (LineData[i]?.data[j]?.visible) {
        linePoints.push(LineData[i]?.data[j]);
      }
    }

    thicknessMap.push(get_distance_for_layer(linePoints, meta));
  }
  // Reverse array
  thicknessMap.reverse();

  const image = [];

  // Iterate through each value in the thickness map
  for (let i = 0; i < thicknessMap.length; i++) {
    for (let j = 0; j < thicknessMap[i].length; j++) {
      if (thicknessMap[i][j] in meta.lut) {
        var l = meta.lut[thicknessMap[i][j]];
        image.push(l[0]);
        image.push(l[1]);
        image.push(l[2]);
        image.push(255);
      } else {
        image.push(0);
        image.push(0);
        image.push(0);
        image.push(0);
      }
    }
  }

  var remaining = meta.height - thicknessMap.length;
  var steps = Math.round(remaining / thicknessMap.length);

  var thickness_image = [];
  var thicknessImageArray = [];
  var st = 0;
  for (let i = 0; i < thicknessMap.length; i++) {
    for (let j = 0; j < thicknessMap[i].length; j++) {
      if (thicknessMap[i][j] in meta.lut) {
        var l = meta.lut[thicknessMap[i][j]];
        thickness_image.push(l[0]);
        thickness_image.push(l[1]);
        thickness_image.push(l[2]);
        thickness_image.push(255);
      } else {
        thickness_image.push(0);
        thickness_image.push(0);
        thickness_image.push(0);
        thickness_image.push(0);
      }
    }
    for (let k = 0; k < steps; k++) {
      for (let j = 0; j < thicknessMap[i].length; j++) {
        thickness_image.push(0);
        thickness_image.push(0);
        thickness_image.push(0);
        thickness_image.push(0);
      }
    }
  }

  for (let i = 0; i < thicknessMap.length; i++) {
    if (i !== thicknessMap.length - 1) {
      thicknessImageArray.push(thicknessMap[i]);
    }
    var stepArray = [];
    for (let j = 0; j < thicknessMap[i].length; j++) {
      stepArray.push(0);
    }
    if (i === thicknessMap.length - 1) {
      steps = meta.height - thicknessImageArray.length - 1;
    }
    for (let k = 0; k < steps; k++) {
      thicknessImageArray.push(stepArray);
    }
    if (i === thicknessMap.length - 1) {
      thicknessImageArray.push(thicknessMap[i]);
    }
  }

  var new_height = parseInt(thickness_image.length / (meta.width * 4));

  // Convert to Uint8
  const imageUint8_t1 = new Uint8ClampedArray(thickness_image);

  const imageUint8Data_t1 = new ImageData(
    imageUint8_t1,
    meta.width,
    new_height
  );

  // Resize to match the image, using bilinear interpolation
  const resized_t1 = resizeImageData(
    imageUint8Data_t1,
    meta.width,
    meta.width,
    "bilinear-interpolation"
  );

  var canvas2 = document.createElement("canvas");
  canvas2.width = meta.width;
  canvas2.height = meta.width;

  var ctx2 = canvas2.getContext("2d");
  ctx2.putImageData(resized_t1, 0, 0, 0, 0, meta.width, meta.width);

  // var link = document.createElement('a');
  // link.download = 'thickness_lut.png';
  // link.href = canvas2.toDataURL("image/png")
  // link.click();

  canvas2.remove();

  // Convert to Uint8
  const imageUint8 = new Uint8ClampedArray(image);
  const imageUint8Data = new ImageData(
    imageUint8,
    meta.width,
    meta.total_frames
  );

  // Resize to match the image, using bilinear interpolation
  const resized = resizeImageData(
    imageUint8Data,
    meta.width,
    meta.width,
    "bilinear-interpolation"
  );

  const canvas = document.createElement("canvas");
  canvas.width = meta.width;
  canvas.height = meta.width;

  const ctx1 = canvas.getContext("2d");
  ctx1.putImageData(resized, 0, 0, 0, 0, meta.width, meta.width);

  var rotation_angle = -meta.angle;
  // -3.985707940187177
  rotation_angle = (rotation_angle * Math.PI) / 180;

  var canvas_temp = document.createElement("canvas");
  canvas_temp.width = meta.op_meta.width;
  canvas_temp.height = meta.op_meta.height;
  var ctx_temp = canvas_temp.getContext("2d");

  var topleft = {
    x: meta.frames[1][0],
    y: meta.frames[1][1],
  };

  var topright = {
    x: meta.frames[1][2],
    y: meta.frames[1][3],
  };

  var bottomleft = {
    x: meta.frames[0][0],
    y: meta.frames[0][1],
  };

  var bottomright = {
    x: meta.frames[0][2],
    y: meta.frames[0][3],
  };

  // var colors = [
  //     'red',
  //     'green',
  //     'blue',
  //     'yellow'
  // ]
  // var points = [
  //     topleft,
  //     topright,
  //     bottomleft,
  //     bottomright
  // ]

  // Calculate center of the rect
  var center = {
    x: (topleft.x + bottomright.x) / 2,
    y: (topleft.y + bottomright.y) / 2,
  };

  // //Draw points
  // for (var i = 0; i < points.slice(0, 1).length; i++) {
  //     ctx_temp.beginPath();
  //     ctx_temp.arc(points[i].x, points[i].y, 10, 0, 2 * Math.PI, false);
  //     ctx_temp.fillStyle = colors[i];
  //     ctx_temp.fill();
  //     ctx_temp.lineWidth = 5;
  //     ctx_temp.strokeStyle = '#003300';
  //     ctx_temp.stroke();
  // }

  // Draw image between the rect with the angle
  ctx_temp.translate(center.x, center.y);
  // Set scale
  ctx_temp.rotate(-rotation_angle);
  ctx_temp.translate(-center.x, -center.y);

  // topLeft after rotation
  var topleft = rotatePoint(topleft, center, -meta.angle);
  var topright = rotatePoint(topright, center, -meta.angle);
  var bottomleft = rotatePoint(bottomleft, center, -meta.angle);

  // ctx_temp.beginPath();
  // ctx_temp.arc(topleft.x, topleft.y, 10, 0, 2 * Math.PI, false);
  // ctx_temp.fillStyle = "black"
  // ctx_temp.fill();
  // ctx_temp.lineWidth = 5;
  // ctx_temp.strokeStyle = '#003300';
  // ctx_temp.stroke();

  ctx_temp.drawImage(
    canvas,
    0,
    0,
    meta.width,
    meta.width,
    topleft.x,
    topleft.y,
    topright.x - topleft.x,
    bottomleft.y - topleft.y
  );

  // //Download image
  // var link = document.createElement('a');
  // link.download = 'thickness.png';
  // link.href = ctx_temp.toDataURL("image/png")
  // link.click();

  const newImg = ctx_temp.getImageData(
    0,
    0,
    meta.op_meta.width,
    meta.op_meta.width
  );

  canvas_temp.remove();
  canvas.remove();

  var random_num = Math.floor(Math.random() * 1000000);
  const cnsImage = createImage(newImg, `thickness_${random_num}`);

  return {
    cnsImage: cnsImage,
    thickness_image_array: thicknessImageArray,
  };
}

function get_contour_points(LineAnnots, meta) {
  var temp_points = [];
  for (var i = 0; i < LineAnnots.handles.points.length; i++) {
    temp_points.push([
      LineAnnots.handles.points[i].x,
      LineAnnots.handles.points[i].y,
    ]);
  }

  // Fill missing points
  var points = {};
  for (var i = 0; i < temp_points.length - 1; i++) {
    var x1 = temp_points[i][0];
    var y1 = temp_points[i][1];

    var x2 = temp_points[i + 1][0];
    var y2 = temp_points[i + 1][1];

    var x_diff = x2 - x1;
    var y_diff = y2 - y1;

    var num_points = Math.max(Math.abs(x_diff), Math.abs(y_diff));

    var x_step = x_diff / num_points;
    var y_step = y_diff / num_points;

    for (var j = 0; j < num_points; j++) {
      points[x1 + j * x_step] = y1 + j * y_step;
    }
  }
  return points;

  const canvas = document.createElement("canvas");
  canvas.width = meta.width;
  canvas.height = meta.height;
  const ctx1 = canvas.getContext("2d");

  var points = LineAnnots.handles.points;
  var options = {
    color: lineColors[LineAnnots.name],
    lineWidth: 1,
    fill: false,
  };
  // Draw line on canvas for layer and get image data
  drawJoinedLines(ctx1, "other", points[0], points, options, "other");
  const tempImageData = ctx1.getImageData(0, 0, meta.width, meta.height);

  // Convert image data to monochrome
  const pixels = imageDataAsRgbaObject(tempImageData);
  const monochromePixels = pixels.map((p) =>
    p.alpha === 0 ? -1 : (p.red + p.green + p.blue) / 3
  );

  // Get contour points
  const contours = d3Contours().size([meta.width, meta.height])(
    // .thresholds([-1, 0, 100, 200, 255])
    //.thresholds(2)
    monochromePixels
  );

  var contour = contours[1].coordinates;
  if (contour.length > 1) {
    // keep the largest contour
    var max = 0;
    var max_index = 0;
    for (var i = 0; i < contour.length; i++) {
      if (contour[i][0].length > max) {
        max = contour[i][0].length;
        max_index = i;
      }
    }
    contour = [contour[max_index]];
  }

  // Simplify contour points (Remove points that are duplicates in floating point)
  var contoursSimplified = [];
  for (var contour_index = 0; contour_index < contour.length; contour_index++) {
    var simplified = simplifyContour(contour[contour_index][0]);
    // List to object
    var simplifiedObject = {};
    for (var i = 0; i < simplified.length; i++) {
      simplifiedObject[simplified[i][0]] = simplified[i][1];
    }
    contoursSimplified.push(simplifiedObject);
  }

  canvas.remove();

  return contoursSimplified[0];
}

function get_distance_for_layer(LineAnnots, meta) {
  // Get contour points for each layer and get distance between them
  var simplifiedPoints = [];

  for (let i = 0; i < LineAnnots.length; i++) {
    simplifiedPoints.push(get_contour_points(LineAnnots[i], meta));
  }

  const distanceList = createContours(simplifiedPoints, meta);

  return distanceList;
}

export function createContours(contoursSimplified, meta) {
  // Iterate over first contour
  //meta.rowPixelSpacing = null
  var distance = [];
  for (var i = 0; i < meta.width; i++) {
    if (contoursSimplified.length < 2) {
      distance.push(0);
      continue;
    }

    // Check if pixel is in contour
    if (i in contoursSimplified[0] && i in contoursSimplified[1]) {
      // Check y distance\

      var y1 = contoursSimplified[0][i];
      var y2 = contoursSimplified[1][i];
      var yDistance = Math.abs(y1 - y2);
      if (meta.rowPixelSpacing) {
        yDistance = parseInt(yDistance * meta.rowPixelSpacing * 1000);
      } else {
        yDistance = parseInt(yDistance * 1000);
      }
      distance.push(yDistance);
    } else {
      distance.push(0);
    }
  }

  return distance;
}

export function simplifyContour(contour) {
  // Conver points to integers and remove duplicates
  const points = contour.map((p) => [Math.round(p[0]), Math.round(p[1])]);
  const uniquePoints = points.filter((p, i) => {
    if (i === 0) {
      return true;
    }
    const prev = points[i - 1];
    return p[0] !== prev[0] || p[1] !== prev[1];
  });

  // Sort points by x
  const sortedPoints = uniquePoints.sort((a, b) => a[0] - b[0]);

  // Remove duplicate x values
  const uniqueX = sortedPoints.filter((p, i) => {
    if (i === 0) {
      return true;
    }
    const prev = sortedPoints[i - 1];
    return p[0] !== prev[0];
  });

  // return uniquePoints;
  return uniqueX;
}

function imageDataAsRgbaObject(imageData) {
  const data = imageData.data; // => [r,g,b,a,...]

  const pixels = [];
  for (let i = 0; i < data.length; i += 4) {
    pixels.push({
      red: data[i],
      green: data[i + 1],
      blue: data[i + 2],
      alpha: data[i + 3] / 255,
    });
  }
  return pixels;
}
