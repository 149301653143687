import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import { is_inside_polygon } from "../utils/basic_utils";
import LengthTool from "../tools/annotation/LengthTool";
import CoordinateTool from "../tools/annotation/CordinateTool";
import LisonTool from "../tools/annotation/LisonTool";
import MultiPointsProbeTool from "../tools/annotation/MultiPointProbeTool";
import { responseLison } from "./testMl";
const freehandUtils = cornerstoneTools.importInternal("util/freehandUtils");
const external = cornerstoneTools.external;

//import freehandUtils from '../../util/freehand/index.js';
//import { getLogger } from '../../util/logger.js';
//import throttle from '../../util/throttle';
//import { getModule } from '../../store/index';

const { FreehandHandleData } = freehandUtils;

//import EVENTS from './../../events.js';
//import external from './../../externalModules.js';
//import BaseAnnotationTool from './../base/BaseAnnotationTool.js';

export function panImageEvent(evt) {
  const eventData = evt.detail;
  const { element, viewport } = eventData;

  const translation = _getTranslation(eventData);

  _applyTranslation(viewport, translation);
  external.cornerstone.setViewport(element, viewport);
}

function _getTranslation(eventData) {
  const { viewport, image, deltaPoints } = eventData;

  let widthScale = viewport.scale;
  let heightScale = viewport.scale;

  if (image.rowPixelSpacing < image.columnPixelSpacing) {
    widthScale *= image.columnPixelSpacing / image.rowPixelSpacing;
  } else if (image.columnPixelSpacing < image.rowPixelSpacing) {
    heightScale *= image.rowPixelSpacing / image.columnPixelSpacing;
  }

  return {
    x: deltaPoints.page.x / widthScale,
    y: deltaPoints.page.y / heightScale,
  };
}

function _applyTranslation(viewport, translation) {
  viewport.translation.x += translation.x;
  viewport.translation.y += translation.y;
}

function changeViewportScale(viewport, ticks, scaleLimits) {
  const { maxScale, minScale } = scaleLimits;
  const pow = 1.7;
  const oldFactor = Math.log(viewport.scale) / Math.log(pow);
  const factor = oldFactor + ticks;
  const scale = Math.pow(pow, factor);

  if (maxScale && scale > maxScale) {
    viewport.scale = maxScale;
  } else if (minScale && scale < minScale) {
    viewport.scale = minScale;
  } else {
    viewport.scale = scale;
  }

  return viewport;
}

export const addTools = (tool_name) => {
  // if (tool_name === "Lison") {
  //   return;
  // }
  // Add tools to the cornerstone

  if (tool_name === "Lison") {
    const tool_add = cornerstoneTools["LisonProbeTool"];

    cornerstoneTools.addTool(tool_add);
    cornerstoneTools.setToolActive("LisonProbe", {
      mouseButtonMask: 1,
    });
  }
  const tool_v = cornerstoneTools[tool_name + "Tool"];

  cornerstoneTools.addTool(tool_v);
  cornerstoneTools.setToolActive(tool_name, {
    mouseButtonMask: 1,
  });
};

export function ZoomMouseWheel(e, cornerstone) {
  const { element, viewport, spinY } = e.detail;

  const invert = false,
    maxScale = 20.0,
    minScale = 0.25;
  const ticks = invert ? spinY / 4 : -spinY / 4;

  const updatedViewport = changeViewportScale(viewport, ticks, {
    maxScale,
    minScale,
  });

  cornerstone.setViewport(element, updatedViewport);
}

export const drawRectangleStatic = (ref, points, angle) => {
  cornerstoneTools.clearToolState(ref.current, "RectangleStatic");
  cornerstoneTools.addToolState(ref.current, "RectangleStatic", {
    visible: true,
    handles: {
      start: {
        x: points[0],
        y: points[1],
      },
      end: {
        x: points[2],
        y: points[3],
      },
      initialRotation: angle,
    },
    uuid: "c77922bd-0e12-4591-bab4-7a00d9d37a6b",
  });
  cornerstone.drawImage(ref);
};

export const drawCircleStatic = (ref, points) => {
  cornerstoneTools.clearToolState(ref.current, "CircleStatic");
  cornerstoneTools.addToolState(ref.current, "CircleStatic", {
    visible: true,
    handles: {
      start: {
        x: points[0],
        y: points[1],
      },
      end: {
        x: points[2],
        y: points[3],
      },
    },
    uuid: "c77922bd-0e12-4591-bab4-7a00d9d37a6b",
  });
  cornerstone.drawImage(ref);
};

export const addVerticalLineOCT = (
  cornerstoneTools,
  viewPortRef,
  xmin,
  ymin,
  xmax,
  ymax
) => {
  cornerstoneTools.clearToolState(viewPortRef.current, "LineStatic");

  cornerstoneTools.addToolState(viewPortRef.current, "LineStatic", {
    visible: true,
    handles: {
      start: {
        x: xmin,
        y: ymin,
      },
      end: {
        x: xmax,
        y: ymax,
      },
    },
    uuid: "c77922bd-0e12-4591-bab4-7a00d9d37a6b",
    frame: ``,
  });
};

export const addStaticLineWithText = (ref, points, textData) => {
  // Function to add a line (horizontal and vertical with frame number) to the fundus image

  cornerstoneTools.addToolState(ref.current, "LineStatic", {
    visible: true,
    handles: {
      start: {
        x: points[0],
        y: points[1],
      },
      end: {
        x: points[2],
        y: points[3],
      },
    },
    uuid: "c77922bd-0e12-4591-bab4-7a00d9d37a6b",
    frame: textData,
  });
};

export const addAnnotations = (annotations, dcmRef) => {
  let count = 1;
  Object.entries(annotations).forEach(([annotation_type, annotations]) => {
    cornerstoneTools.addTool(cornerstoneTools[`${annotation_type}Tool`]);
    cornerstoneTools.setToolActive(annotation_type, { mouseButtonMask: 1 });
    cornerstoneTools.clearToolState(dcmRef.current, annotation_type);

    // if (annotation_type === "Probe") {
    //   cornerstoneTools.addTool(MultiPointsProbeTool);
    //   cornerstoneTools.setToolActive("MultiPointProbe", { mouseButtonMask: 1 });
    //   cornerstoneTools.clearToolState(dcmRef.current, "MultiPointProbe");
    //   AddLisonAreas(dcmRef);
    // }
    // const toolState = cornerstoneTools.getToolState(
    //   dcmRef.current,
    //   "MultiPointProbe"
    // );
    // if (toolState?.data) {
    //   toolState.data.push({
    //     // Add any initial data points here
    //     data: [
    //       {
    //         visible: true,
    //         active: true,
    //         color: "yellow",
    //         invalidated: true,
    //         handles: {
    //           points: [
    //             {
    //               x: 600,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 601,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 602,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 603,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 604,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 605,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 606,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 607,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 608,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 609,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 610,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 611,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 612,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 613,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 614,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 615,
    //               y: 600,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //             {
    //               x: 700,
    //               y: 700,
    //               highlight: true,
    //               active: true,
    //               moving: false,
    //             },
    //           ],
    //         },
    //       },
    //     ],
    //   });
    // }
    //
    Object.entries(annotations.data).forEach(([index, annotation]) => {
      // annotation["color"] = "red";

      let modified_annotation = {};
      cornerstoneTools.addToolState(
        dcmRef.current,
        annotation_type,
        annotation
      );
    });
    cornerstoneTools.setToolPassive(annotation_type);
  });

  cornerstone.updateImage(dcmRef.current);
};

export const addAnnotationsOPT = (imageIds, annotations, LisonActive) => {
  // Restore annotations for all frames (Annotations from backend server)
  var toolsR = [];
  for (var i = 0; i < imageIds.length; i++) {
    // Iterate on each image/frame
    var image_id = imageIds[i].split("?").pop(); // Get image id (split url by ? and last is id) = 'frame=0'
    if (image_id in annotations) {
      // Restore annotations
      cornerstoneTools.globalImageIdSpecificToolStateManager.restoreImageIdToolState(
        imageIds[i],
        annotations[image_id]
      );

      // Activate tools to make them visible
      Object.keys(annotations[image_id]).forEach((key) => {
        if (!toolsR.includes(key)) {
          toolsR.push(key);
          if (key === "Lison" && !LisonActive) {
            return;
          }
          cornerstoneTools.addTool(cornerstoneTools[`${key}Tool`]);
          cornerstoneTools.setToolActive(key, { mouseButtonMask: 1 });
          cornerstoneTools.setToolPassive(key);
        }
      });
    }
  }
};

const linesToObj = (data) => {
  var final = {};
  if (data.data) {
    for (var i = 0; i < data.data.length; i++) {
      final[data.data[i].name] = data.data[i];
    }
  }
  return final;
};

export const addAnnotationsOPTUpdate = (
  imageIds,
  annotations,
  drawingTools,
  lineNames
) => {
  // Function to update annotations (Line tool) fetched from Deep Learning models
  for (var i = 0; i < imageIds.length; i++) {
    // Iterate on each image id
    var image_id = imageIds[i].split("?").pop(); // Split image id by ? and get only frame number like 'frame=0'
    if (image_id in annotations) {
      // If there is any annotation for that image
      var out = {};
      drawingTools.forEach((item) => {
        // Iterate on each drawing tool, and get any annotation (if there is already some annotation on image)
        //if (item !== 'Line') { // Ignore Line tool annotations
        var toolAnnotations =
          cornerstoneTools.globalImageIdSpecificToolStateManager.getImageIdToolState(
            imageIds[i],
            item
          );
        if (toolAnnotations) {
          out[item] = toolAnnotations;
        }
        //}
      });

      var old_lines = linesToObj(out["Line"]);
      var new_lines = linesToObj(annotations[image_id].Line);

      var final_lines = [];
      for (const lName of lineNames) {
        if (lName in old_lines) {
          // If Line is already in old annotations
          if (old_lines[lName].visible && lName in new_lines) {
            // If it is visible and is in models output
            final_lines.push(new_lines[lName]);
          } else {
            final_lines.push(old_lines[lName]);
          }
        } else if (lName in new_lines) {
          // Line is not available in old data
          final_lines.push(new_lines[lName]);
        }
      }

      // Add Line tool annotations from request data
      out["Line"] = { data: final_lines }; //annotations[image_id].Line

      // Update annotations data
      cornerstoneTools.globalImageIdSpecificToolStateManager.restoreImageIdToolState(
        imageIds[i],
        out
      );
    }
  }
};

function is_point_inside_bounding_box(x, y, x1, y1, x2, y2) {
  // ensure that (x1,y1) is top left and (x2,y2) is bottom right corner of rectangle
  var rect = {};
  if (x1 < x2) {
    rect.x1 = x1;
    rect.x2 = x2;
  } else {
    rect.x1 = x2;
    rect.x2 = x1;
  }
  if (y1 < y2) {
    rect.y1 = y1;
    rect.y2 = y2;
  } else {
    rect.y1 = y2;
    rect.y2 = y1;
  }

  if (x >= rect.x1 && x <= rect.x2 && y >= rect.y1 && y <= rect.y2) {
    return true;
  } else {
    return false;
  }
}

function dist_to_line(x, y, x1, y1, x2, y2) {
  if (is_point_inside_bounding_box(x, y, x1, y1, x2, y2)) {
    var dy = y2 - y1;
    var dx = x2 - x1;
    var nr = Math.abs(dy * x - dx * y + x2 * y1 - y2 * x1);
    var dr = Math.sqrt(dx * dx + dy * dy);
    var dist = nr / dr;
    return Math.round(dist);
  } else {
    return Number.MAX_SAFE_INTEGER;
  }
}

function is_on_polygon_edge(points, px, py) {
  var i, n, di, d;
  let VIA_POLYGON_RESIZE_VERTEX_OFFSET = 0;
  let VIA_POLYGON_VERTEX_MATCH_TOL = 10;

  n = points.length;
  d = [];
  for (i = 0; i < n - 1; ++i) {
    di = dist_to_line(
      px,
      py,
      points[i].x,
      points[i].y,
      points[i + 1].x,
      points[i + 1].y
    );
    d.push(di);
  }
  // closing edge
  // di = dist_to_line(px, py, all_points_x[n - 1], all_points_y[n - 1], all_points_x[0], all_points_y[0]);
  // d.push(di);

  var smallest_value = d[0];
  var smallest_index = 0;
  n = d.length;
  for (i = 1; i < n; ++i) {
    if (d[i] < smallest_value) {
      smallest_value = d[i];
      smallest_index = i;
    }
  }
  if (smallest_value < VIA_POLYGON_VERTEX_MATCH_TOL) {
    return VIA_POLYGON_RESIZE_VERTEX_OFFSET + smallest_index;
  } else {
    return -1;
  }
}

function is_inside_point(cx, cy, px, py) {
  var dx = px - cx;
  var dy = py - cy;
  return dx * dx + dy * dy < 15 * 15;
}

const is_mouse_on_polyline_edge = (data, mouse_p) => {
  // Iteate over all lines and points in each line in nested for loop
  for (let i = 0; i < data.length; i++) {
    let points = data[i].handles.points;
    for (let j = 0; j < points.length; j++) {
      // Iterate over each point in a line
      if (
        is_inside_point(
          // Check if clicked region is on a point
          points[j].x,
          points[j].y,
          mouse_p.x,
          mouse_p.y
        )
      )
        return { line: i, point: j };
    }
  }
  return { line: -1, point: -1 };
};

const is_mouse_on_polyline_vertex = (data, mouse_x, mouse_y) => {
  for (let i = 0; i < data.length; i++) {
    //
    let point_on_polygon = is_on_polygon_edge(
      data[i].handles.points,
      mouse_x,
      mouse_y
    );
    if (point_on_polygon !== -1) {
      return {
        on_line: true,
        line: i,
        point: point_on_polygon + 1,
      };
    }
  }
  return { on_line: false };
};

export const modifyOnPolylineClick = (data, mouse_p) => {
  // Delete or add a point to a polyline
  // If mouse is on an existing point, delete it else add a new point
  let resp_edge = is_mouse_on_polyline_edge(data, mouse_p);
  if (resp_edge.line !== -1) {
    // Remove point from points
    if (data[resp_edge.line].handles.points.length > 3)
      data[resp_edge.line].handles.points.splice(resp_edge.point, 1);
  } else {
    let resp = is_mouse_on_polyline_vertex(data, mouse_p.x, mouse_p.y);
    // Add new point to line at specific index
    if (resp.on_line) {
      data[resp.line].handles.points.splice(
        resp.point,
        0,
        new FreehandHandleData(mouse_p)
      );
    }
  }
};

export const is_mouse_on_line = (line, mouse_x, mouse_y) => {
  let is_inside = false;
  if (line[0].x < line[1].x) {
    if (line[0].x <= mouse_x && mouse_x <= line[1].x) {
      is_inside = true;
    }
  } else {
    if (line[1].x <= mouse_x && mouse_x <= line[0].x) {
      is_inside = true;
    }
  }
  return is_inside;
};

const point_inside_rect = (x, y, rect) => {
  if (x >= rect[0] && x <= rect[2] && y >= rect[1] && y <= rect[3]) {
    return true;
  } else {
    return false;
  }
};

export const updateSelectedPointsLine = (data, rect) => {
  if (data == null) return;
  if (data.data == null) return;
  data = data.data;

  for (var i = 0; i < data.length; i++) {
    for (var j = 0; j < data[i].handles.points.length; j++) {
      if (
        point_inside_rect(
          data[i].handles.points[j].x,
          data[i].handles.points[j].y,
          rect
        )
      ) {
        data[i].handles.points[j].selected = true;
      } else {
        data[i].handles.points[j].selected = false;
      }
    }
  }
};

export const deleteSelectedPointsLine = (data) => {
  // Delete Selected points
  if (data.data == null) return;
  data = data.data;
  for (var i = 0; i < data.length; i++) {
    data[i].handles.points = data[i].handles.points.filter(
      (point) => !point.selected
    );
    if (data[i].handles.points.length < 3) {
      data[i].delete = true;
    }
  }
  data = data.filter((line) => !line.delete);
};

function get_line_pixels(A, B) {
  var m = slope(A, B);
  var b = intercept(A, m);

  var coordinates = [];
  for (var x = A[0]; x <= B[0]; x++) {
    var y = m * x + b;
    coordinates.push([parseInt(x), parseInt(y)]);
  }

  return coordinates;
}
function get_circle_pixels_m(centerX, centerY, radius, start, end) {
  var points = [];
  for (var degree = start; degree < end; degree++) {
    var radians = (degree * Math.PI) / 180;
    var x = centerX + radius * Math.cos(radians);
    var y = centerY + radius * Math.sin(radians);

    points.push([parseInt(x), parseInt(y)]);
  }

  return points;
}

function slope(a, b) {
  if (a[0] == b[0]) {
    return null;
  }

  return (b[1] - a[1]) / (b[0] - a[0]);
}

function intercept(point, slope) {
  if (slope === null) {
    // vertical line
    return point[0];
  }

  return point[1] - slope * point[0];
}

export const calculate_thickness_data = (data, img_rows, img_cols) => {
  const circles_radii = data.circles_radii;
  var sq_root = Math.sqrt(2);

  var lines_starting_and_ending_points = [
    [
      [
        data.handles.start.x - img_cols / 2,
        data.handles.start.y - img_rows / 2,
      ],
      [data.handles.start.x, data.handles.start.y],
      [data.handles.start.x - circles_radii[2] / sq_root, 0],
      [data.handles.start.x - circles_radii[2] / sq_root, img_cols],
    ],

    [
      [
        data.handles.start.x + img_cols / 2,
        data.handles.start.y - img_rows / 2,
      ],
      [data.handles.start.x, data.handles.start.y],
      [data.handles.start.x + circles_radii[2] / sq_root, 0],
      [data.handles.start.x + circles_radii[2] / sq_root, img_cols],
    ],
    [
      [
        data.handles.start.x - img_cols / 2,
        data.handles.start.y + img_cols / 2,
      ],
      [data.handles.start.x, data.handles.start.y],
      [data.handles.start.x - circles_radii[2] / sq_root, 0],
      [data.handles.start.x - circles_radii[2] / sq_root, img_cols],
    ],

    [
      [
        data.handles.start.x + img_cols / 2,
        data.handles.start.y + img_cols / 2,
      ],
      [data.handles.start.x, data.handles.start.y],
      [data.handles.start.x + circles_radii[2] / sq_root, 0],
      [data.handles.start.x + circles_radii[2] / sq_root, img_cols],
    ],
  ];

  var ending_points = [
    [
      data.handles.start.x - circles_radii[0] / sq_root,
      data.handles.start.y - circles_radii[0] / sq_root,
    ],

    [
      data.handles.start.x + circles_radii[0] / sq_root,
      data.handles.start.y - circles_radii[0] / sq_root,
    ],

    [
      data.handles.start.x - circles_radii[0] / sq_root,
      data.handles.start.y + circles_radii[0] / sq_root,
    ],

    [
      data.handles.start.x + circles_radii[0] / sq_root,
      data.handles.start.y + circles_radii[0] / sq_root,
    ],
  ];

  //for intersecting_lines, ending_point in zip(lines_starting_and_ending_points, ending_points):
  //    A, B, C, D = intersecting_lines
  //    intersection_point = line_intersection((A, B), (C, D))
  //    line_coordinates.append([intersection_point, ending_point])

  var line_COORDINATES = [];
  for (let i = 0; i < lines_starting_and_ending_points.length; i++) {
    var line1 = [
      lines_starting_and_ending_points[i][0],
      lines_starting_and_ending_points[i][1],
    ];
    var line2 = [
      lines_starting_and_ending_points[i][2],
      lines_starting_and_ending_points[i][3],
    ];

    var xdiff = [line1[0][0] - line1[1][0], line2[0][0] - line2[1][0]];
    var ydiff = [line1[0][1] - line1[1][1], line2[0][1] - line2[1][1]];

    var div = xdiff[0] * ydiff[1] - xdiff[1] * ydiff[0];

    var d = [
      line1[0][0] * line1[1][1] - line1[0][1] * line1[1][0],
      line2[0][0] * line2[1][1] - line2[0][1] * line2[1][0],
    ];
    var x = (d[0] * xdiff[1] - d[1] * xdiff[0]) / div;
    var y = (d[0] * ydiff[1] - d[1] * ydiff[0]) / div;

    line_COORDINATES.push([[x, y], ending_points[i]]);
  }

  line_COORDINATES = [
    [
      // Top Left
      line_COORDINATES[0],
      [224, 316],
    ],
    [
      // Top Right
      line_COORDINATES[1],
      [-45, 45],
    ],
    [
      // Bottom Left
      line_COORDINATES[3],
      [45, 135],
    ],
    [
      // Bottom Right
      line_COORDINATES[2],
      [135, 225],
    ],
  ];

  var polygons = [];

  const lines_to_draw = [];

  line_COORDINATES.slice(0, 4).forEach((point_data, line_index) => {
    // Draw Line

    var point = point_data[0];
    //
    // drawLine(context, element, {
    //     x: point[0][0], y: point[0][1]
    // }, {
    //     x: point[1][0], y: point[1][1]
    // }, circleOptions)
    lines_to_draw.push({
      start: { x: point[0][0], y: point[0][1] },
      end: { x: point[1][0], y: point[1][1] },
    });

    var start_angle = point_data[1][0];
    var end_angle = point_data[1][1];

    circles_radii.slice(0, 2).forEach((radius, index) => {
      if (index < 2) {
        var next_radius = circles_radii[index + 1];
        var circle_points = get_circle_pixels_m(
          data.handles.start.x,
          data.handles.start.y,
          radius,
          start_angle,
          end_angle
        );

        var next_circle_points = get_circle_pixels_m(
          data.handles.start.x,
          data.handles.start.y,
          next_radius,
          start_angle,
          end_angle
        );

        if (line_index == 0) {
          var first_line = get_line_pixels(
            next_circle_points[0],
            circle_points[0]
          );

          var last_line = get_line_pixels(
            circle_points[circle_points.length - 1],
            next_circle_points[next_circle_points.length - 1]
          );
          last_line = last_line.reverse();
          circle_points = circle_points.reverse();
        } else if (line_index == 1) {
          var first_line = get_line_pixels(
            circle_points[0],
            next_circle_points[0]
          );

          var last_line = get_line_pixels(
            next_circle_points[next_circle_points.length - 1],
            circle_points[circle_points.length - 1]
          );
          last_line = last_line.reverse();
          circle_points = circle_points.reverse();
        } else if (line_index == 2) {
          var first_line = get_line_pixels(
            next_circle_points[0],
            circle_points[0]
          );

          var last_line = get_line_pixels(
            circle_points[circle_points.length - 1],
            next_circle_points[next_circle_points.length - 1]
          );
          last_line = last_line.reverse();
          circle_points = circle_points.reverse();
        } else if (line_index == 3) {
          var first_line = get_line_pixels(
            circle_points[0],
            next_circle_points[0]
          );

          var last_line = get_line_pixels(
            next_circle_points[next_circle_points.length - 1],
            circle_points[circle_points.length - 1]
          );
          last_line = last_line.reverse();
          circle_points = circle_points.reverse();
        }

        // Concat all 4 arrays
        var poly = first_line.concat(
          next_circle_points,
          last_line,
          circle_points
        );

        polygons.push(poly);
      }
    });
  });
  polygons.push(
    get_circle_pixels_m(
      data.handles.start.x,
      data.handles.start.y,
      circles_radii[0],
      0,
      360
    )
  );

  // return

  var topLeft = data.rect;
  var org_polygons = polygons.slice(0, polygons.length);

  polygons = polygons.map((poly) => {
    // Subtract topLeft from all points
    return poly.map((point) => {
      return [point[0] - topLeft[0], point[1] - topLeft[1]];
    });
  });

  var poly_out = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
  };

  var thickness_image = data.thickness_image;

  if (thickness_image != null) {
    var height = thickness_image.length;
    var width = thickness_image[0].length;
    // Iterate on each point and check if inside any polygon

    // Iterate on each polygon, get all points inside and calculate mean
    polygons.forEach((poly, poly_index) => {
      var min_x = poly.reduce(
        (min, p) => (p[0] < min ? p[0] : min),
        poly[0][0]
      );
      var max_x = poly.reduce(
        (max, p) => (p[0] > max ? p[0] : max),
        poly[0][0]
      );
      var min_y = poly.reduce(
        (min, p) => (p[1] < min ? p[1] : min),
        poly[0][1]
      );
      var max_y = poly.reduce(
        (max, p) => (p[1] > max ? p[1] : max),
        poly[0][1]
      );

      // If min_x or min_y is less than 0, make it zero
      min_x = min_x < 0 ? 0 : min_x;
      min_y = min_y < 0 ? 0 : min_y;

      // If max_x or max_y is greater than width or height, make it width or height
      max_x = max_x >= width ? width - 1 : max_x;
      max_y = max_y >= height ? height - 1 : max_y;

      for (var x = min_x; x <= max_x; x++) {
        for (var y = min_y; y <= max_y; y++) {
          if (is_inside_polygon(poly, [x, y])) {
            if (thickness_image[y][x] !== 0) {
              if (poly_index in poly_out) {
                poly_out[poly_index].push(thickness_image[y][x]);
              } else {
                poly_out[poly_index] = [thickness_image[y][x]];
              }
            }
          }
        }
      }
    });
  }

  // Iteratre on poly_out and calculate mean and assign to data
  var poly_out_mean = {};
  for (const poly_index in poly_out) {
    var mean =
      poly_out[poly_index].reduce((a, b) => a + b, 0) /
      poly_out[poly_index].length;
    poly_out_mean[poly_index] = mean;
  }

  return {
    poly_out_mean: poly_out_mean,
    lines_to_draw: lines_to_draw,
  };
};
