import React from "react";
import LoadingIcon from '../assets/Eclipse-1s-400px.svg'

export default function LoaderScreen(){
    return (
        <div>
            <img src={LoadingIcon} alt="Loading..." style={{width: "120px", height: "120px", marginBottom: "50px" }} />
            <h4>Loading...</h4>
        </div>
    )
}