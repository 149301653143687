
function eraseIfSegmentIndex(
    pixelIndex,
    pixelData,
    segmentIndex
  ) {
    if (pixelData[pixelIndex] === segmentIndex) {
      pixelData[pixelIndex] = 0;
    }
  }


function drawBrushPixels(
    pointerArray,
    pixelData,
    segmentIndex,
    columns,
    shouldErase = false
  ) {
    const getPixelIndex = (x, y) => y * columns + x;
  
    pointerArray.forEach(point => {
      const spIndex = getPixelIndex(...point);
  
      if (shouldErase) {
        eraseIfSegmentIndex(spIndex, pixelData, segmentIndex);
      } else {
        pixelData[spIndex] = segmentIndex;
      }
    });
  }
  
  export { drawBrushPixels };