import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import ViewStacks from '../ViewStacks';
export default function Navbar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <ViewStacks />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link style={{ color: "#FFF", textDecoration: "none" }} underline="none" to="/">Dicom Viewer</Link>
          </Typography>
          {/* <Button href="/upload" color="inherit">Upload</Button> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}