import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowRight from "@mui/icons-material/ArrowRight";

import CropSquareIcon from "@mui/icons-material/CropSquare";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import MovingIcon from "@mui/icons-material/Moving";
import LensIcon from "@mui/icons-material/Lens";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import { Checkbox } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";

import BrushIcon from "@mui/icons-material/Brush";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
const iconsData = {
  Probe: <LensIcon />,
  EllipticalRoi: <PanoramaFishEyeIcon />,
  CircleRoi: <CircleOutlined />,
  Length: <MovingIcon />,
  RectangleRoi: <CropSquareIcon />,
  Angle: <SubdirectoryArrowRightIcon />,
  Brush: <BrushIcon />,
  Lison: <SettingsEthernetIcon />,
  MultiPointProbe: <CropSquareIcon />,
  MultiLine: <CropSquareIcon />,
};

export default function SingleAnnotation(props) {
  const get_coordinates = (annotation, label) => {
    if (label === "Brush") {
      return "Layers";
    }
    if (label === "MultiPointProbe" || label === "MultiLine") {
      return `${props.item?.data[0]?.label}: ${
        annotation?.data[0]?.area
      } mm${String.fromCharCode(178)}`;
    }
    let coord_str = "";
    if (label === "Line") {
      return `${annotation.name}`; // - ${annotation.handles.points.length} points`
    }
    if (annotation?.handles?.start !== undefined) {
      //coord_str += "Start: " + parseInt(annotation.handles.start.x) + "," + parseInt(annotation.handles.start.y) + " - ";
    }
    //coord_str += "End: " + parseInt(annotation.handles.end.x) + "," + parseInt(annotation.handles.end.y);
    return coord_str;
  };

  const precise = (x) => {
    return x?.toPrecision(4);
  };

  const getMiscData = (annotation, label) => {
    let final_str = "";
    if (label === "Lison") {
      final_str += `Length: ${
        annotation.length ? parseFloat(annotation.length).toFixed(2) : "0.00"
      } ${annotation.unit ?? ""}`;
    } else if (
      (label === "EllipticalRoi" ||
        label === "RectangleRoi" ||
        label === "CircleRoi") &&
      annotation.cachedStats !== undefined
    ) {
      final_str += `Area: ${
        annotation.cachedStats.area
          ? precise(annotation.cachedStats.area)
          : "0.00"
      } ${annotation.unit ?? "px"}`;
    } else if (label === "Length") {
      final_str += `Length: ${
        annotation.length ? parseFloat(annotation.length).toFixed(2) : "0.00"
      } ${annotation.unit ?? ""}`;
    } else if (label === "Angle") {
      final_str += `Angle: ${
        annotation.rAngle ? precise(annotation.rAngle) : "0"
      }° `;
    } else if (label === "Probe") {
      //
      // final_str += "AOA";
      final_str += `x:${Math.round(annotation.handles.end.x)} , y:${Math.round(
        annotation.handles.end.y
      )}`;
    }

    return final_str;
  };

  const dispatch = useDispatch();
  const onCheckboxChange = (index) => {
    dispatch({
      type: "ANNOTATION_CHANGE_REQUEST",
      stack_id: props.dcm_key,
      key: props.dcm_type,
      payload: {
        index: index,
        annot_type: props.annot_type,
        action_type: "visibility",
      },
    });
  };

  const deleteMeasurement = (index) => {
    dispatch({
      type: "ANNOTATION_CHANGE_REQUEST",
      stack_id: props.dcm_key,
      key: props.dcm_type,
      payload: {
        index: index,
        annot_type: props.annot_type,
        action_type: "delete",
      },
    });
  };
  if (props.label === "LisonProbe") {
    return null;
  }

  return (
    <>
      {(props.label === "MultiPointProbe" || props.label === "MultiLine") &&
      props.item.data[0].total_area ? (
        props?.item?.data[0]?.label === "L1" ? (
          // <ListItemText
          //   primary={get_coordinates(props.item, props.label)}
          //   primaryTypographyProps={{ fontSize: 12, fontWeight: "medium" }}
          //   secondary={getMiscData(props.item, props.label)}
          // />
          <div
            style={{
              display: "flex",
              backgroundColor: "white",
              paddingLeft: "16px",
              margin: "0px",
            }}
          >
            <p>
              <React.Fragment>
                <b>Total Area: </b>
                {`${props.item?.data[0]?.total_area} mm${String.fromCharCode(
                  178
                )}`}
              </React.Fragment>
            </p>
          </div>
        ) : null
      ) : null}
      <ListItem
        style={{ backgroundColor: "rgba(255, 255,255)" }}
        key={props.label}
        sx={{ py: 0, minHeight: 28, color: "rgba(0,0,0)" }}
      >
        {props.label === "Line" ? (
          <Checkbox
            checked={props.item.visible}
            size="small"
            onChange={() => onCheckboxChange(props.index)}
          />
        ) : (
          <>
            {/* {props.label === "MultiPointProbe" || props.label === "MultiLine"
              ? console.log(
                  "props.item singleAnnotations",
                  props.item,
                  props.label
                )
              : null} */}
            {props.label === "MultiPointProbe" ||
            props.label === "MultiLine" ? null : (
              <ListItemIcon
                sx={{
                  color: `${
                    props.label === "MultiPointProbe" ||
                    props.label === "MultiLine"
                      ? props.item.data[0].color
                      : "inherit"
                  }`,
                }}
              >
                {iconsData[props.label]}
              </ListItemIcon>
            )}
          </>
        )}

        <ListItemText
          primary={get_coordinates(props.item, props.label)}
          primaryTypographyProps={{ fontSize: 12, fontWeight: "medium" }}
          secondary={getMiscData(props.item, props.label)}
        />

        <Tooltip title="Delete Annotation">
          <IconButton
            onClick={() => deleteMeasurement(props.index)}
            size="small"
            sx={{
              "&:after": {
                content: '""',
                position: "absolute",
                height: "80%",
                display: "block",
                left: 0,
                width: "1px",
                bgcolor: "divider",
              },
            }}
          >
            <DeleteIcon style={{ color: "brown" }} />
            <ArrowRight sx={{ position: "absolute", right: 4, opacity: 0 }} />
          </IconButton>
        </Tooltip>
      </ListItem>
    </>
  );
}
