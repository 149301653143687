import React from "react";

const NotFoundPage = () => {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#f4f4f4",
      textAlign: "center",
    },
    message: {
      fontSize: "24px",
      color: "red",
      padding: "20px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      backgroundColor: "#fff",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.message}>
        <h1>Oops! Something went wrong.</h1>
        <h5 style={{ color: "black", fontStyle: "italic" }}>
          please contact the dev team to report this issue{" "}
        </h5>
        <p style={{ fontWeight: "lighter", marginTop: "45px" }}>
          <i style={{ color: "black", fontSize: "medium" }}>
            your patience is highly appreciated
          </i>
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
